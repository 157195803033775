import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErroresService } from '../../shared/errores.service';
import { MdlRhEmpleadoConfigura } from './MdlRhEmpleadoConfigura.model';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }


  public setConfiguracion(modelo: MdlRhEmpleadoConfigura): Observable<any> {
    return this.http.post(`${environment.base_url}/usuario/configuraciones/guardar`, modelo)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

}
