import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth/auth.routing';
import { PagesRoutingModule } from './pages/pages.routing';
import { PagesComponent } from './pages/pages.component';

const routes: Routes = [
    {
        path: '',
        component: PagesComponent,
        children: [
            { path: '', redirectTo: '/tablero/tablero2', pathMatch: 'full' },
            { path: "tablero", loadChildren: () => import('./pages/SItablero/sitablero.module').then(m => m.SItableroModule) },
            { path: 'contabilidad', loadChildren: () => import('./pages/FIcontabilidad/contabilidad.module').then(m => m.ContabilidadModule) },
            { path: 'proyecto', loadChildren: () => import('./pages/PRproyectos/proyectos.module').then(m => m.ProyectosModule) },
            { path: 'rmgestion', loadChildren: () => import('./pages/RMgestion/rmgestion.module').then(m => m.RmgestionModule) },
            { path: 'rhgestion', loadChildren: () => import('./pages/RHgestion/rhgestion.module').then(m => m.RhgestionModule) },
            { path: 'rhreclutamiento', loadChildren: () => import('./pages/RHreclutamiento/rhreclutamiento.module').then(m => m.RHreclutamientoModule) },
            { path: 'rhnomina', loadChildren: () => import('./pages/RHnomina/rhnomina.module').then(m => m.RHnominaModule) },
            { path: 'calidad', loadChildren: () => import('./pages/GCcalidad/calidad.module').then(m => m.CalidadModule) },
            { path: 'compras', loadChildren: () => import('./pages/MMcompra/MMcompra.module').then(m => m.MMCompraModule) },
            { path: 'reporte', loadChildren: () => import('./pages/SIreporte/sireporte.module').then(m => m.SIreporteModule) },
            { path: 'sdventas', loadChildren: () => import('./pages/SDventa/venta.module').then(m => m.VentaModule) },
            { path: 'sdventapos', loadChildren: () => import('./pages/SDventapos/SDventapos.module').then(m => m.SDventaposModule) },
            { path: 'sdlogistica', loadChildren: () => import('./pages/SDlogistica/sdlogistica.module').then(m => m.SDlogisticaModule) },
            { path: 'evaluaciones', loadChildren: () => import('./pages/evaluaciones/evaluaciones.module').then(m => m.EvaluacionesModule) },
            { path: 'fiflujo', loadChildren: () => import('./pages/FIflujo/FIflujo.module').then(m => m.FIflujoModule) },
            { path: 'fiactivo', loadChildren: () => import('./pages/FIactivo/fiactivo.module').then(m => m.FIActivoModule) },
            { path: 'hthoteles', loadChildren: () => import('./pages/HThoteles/hthoteles.module').then(m => m.HthotelesModule) },
            { path: 'pmmantenimiento', loadChildren: () => import('./pages/PMmantenimiento/pmmantenimiento.module').then(m => m.PMmantenimientoModule) },
            { path: 'membresias', loadChildren: () => import('./pages/MSmembresia/msmembresia.module').then(m => m.MSmembresiaModule) },
            { path: 'fiimpuesto', loadChildren: () => import('./pages/FIimpuesto/impuesto.module').then(m => m.ImpuestoModule) },
            { path: 'FItesoreria', loadChildren: () => import('./pages/FItesoreria/tesoreria.module').then(m => m.TesoreriaModule) },
            { path: 'inventario', loadChildren: () => import('./pages/MMinventario/inventario.module').then(m => m.InventarioModule) },
            { path: 'productos', loadChildren: () => import('./pages/MMinventario/productos/producto.module').then(m => m.ProductoModule) },
            { path: 'sicontrol', loadChildren: () => import('./pages/SIcontrol/sicontrol.module').then(m => m.SicontrolModule) },
            { path: 'figasto', loadChildren: () => import('./pages/FIgasto/gasto.module').then(m => m.GastoModule) },
            { path: 'produccion', loadChildren: () => import('./pages/MMproduccion/mmproduccion.module').then(m => m.MMproduccionModule) },
            { path: 'taller', loadChildren: () => import('./pages/SDTaller/taller.module').then(m => m.TallerModule) }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        AuthRoutingModule,
        PagesRoutingModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
