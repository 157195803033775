import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ErroresService } from 'src/app/shared/errores.service';
import { environment } from 'src/environments/environment';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
	providedIn: 'root'
})
export class CalendarioService {

	constructor(
		private http: HttpClient,
		private errores: ErroresService,
		private datePipe: DatePipe,
		private modalService: NgbModal
	) { }

	/**
	 * Metodo para obtener los eventos del calendario
	 * @returns Observable con la respuesta de la Api
	 */
	public getEventos(fecha: Date, compartidos: string[]): Observable<any> {
		const parametros: HttpParams = new HttpParams()
			.append('fecha', this.datePipe.transform(fecha, 'yyyy-MM-dd'))
			.append('compartidos', compartidos.toString());
		return this.http.get<any>(`${environment.base_url}/usuario/calendario/listado`, { params: parametros });
	}

	/**
	 * Metodo para obtener listado de actividades por hacer del usuario autentificado
	 * @returns Observable con la respuesta de la Api
	 */
	public getPorHacer(): Observable<any> {
		return this.http.get<any>(`${environment.base_url}/usuario/calendario/porhacer`, {});
	}

	public setAvance(id: number, avance: number): Observable<any> {
		return this.http.post<any>(`${environment.base_url}/proyecto/setAvance/${id}/${avance}`, {});
	}

	/**
	 * Metodo para marcar la reunion como Terminada
	 * @param id Identificador de la reunion
	 * @returns
	 */
	public setTerminado(id: number): Observable<any> {
		return this.http.post<any>(`${environment.base_url}/usuario/calendario/setTerminado/${id}`, {});
	}

	/**
	 * Metodo para obtener a los empleados relacionados con el evento
	 * @param id
	 * @returns
	 */
	public getEmpleados(id_evento: number): Observable<any> {
		return this.http.get<any>(`${environment.base_url}/usuario/calendario/getEmpleados/${id_evento}`, {});
	}

	/**
   * Metodo agregar o compartir el evento con un empleado
   * @param id
   * @returns
   */
	public setEmpleado(id_evento: number, id_rh_empleado: number): Observable<any> {
		return this.http.post<any>(`${environment.base_url}/usuario/calendario/setEmpleado/${id_evento}/${id_rh_empleado}`, {});
	}

	/**
	 * Metodo eliminar del evento a un empleado
	 * @param id
	 * @returns
	 */
	public deleteEmpleado(id_rh_empleado: number): Observable<any> {
		return this.http.post<any>(`${environment.base_url}/usuario/calendario/deleteEmpleado/${id_rh_empleado}`, {});
	}

	/**
	 * Metodo para eliminar un evento
	 * @param id Numero o identificador del evento
	 * @returns
	 */
	public delCalendario(id: number): Observable<any> {
		return this.http.delete<any>(`${environment.base_url}/usuario/calendario/delCalendario/${id}`, {});
	}


}
