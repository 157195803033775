import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ButtonPropsModel } from '@syncfusion/ej2-popups';
import { MdlUsuario } from 'src/app/models/MdlUsuario';
import { environment } from 'src/environments/environment';
import { GccalidadService } from '../services/gccalidad.service';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { SelectEventArgs } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ListBox } from '@syncfusion/ej2-angular-dropdowns';
import { DlgEmpleadoComponent } from 'src/app/components/dlgEmpleado/dlgEmpleado.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { dlgMensajeConfirma } from 'src/app/components/dlgMensajeConfirma/dlgMensajeConfirma.component';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.scss'],
})
export class ConfiguracionComponent implements OnInit {
  public breadcrumbs1: string = '';
  public breadcrumbs2: string = '';
  @BlockUI() blockUI: NgBlockUI;

  @ViewChild('dlgAsignarPermiso') dlgAsignarPermiso: DialogComponent;
  @ViewChild('disponibles') disponibles: ListBox;
  @ViewChild('asignadas') asignadas: ListBox;
  @ViewChild('tfNombre') tfNombre: ElementRef;
  @ViewChild('id_rh_empleado') id_rh_empleado: ElementRef;

  public dropEle: HTMLElement;
  public formulario: any;
  public selection = { mode: "Single" };
  public dataDisponibles: any;
  public dataAsignadas: any;
  public fields: Object = { text: 'descripcion', value: 'id' };
  public data_proyecto: any;
  public data_tipo: any;
  public data: any;
  public usuario: MdlUsuario;
  private archivos: UploaderComponent;
  public archivoArea: HTMLElement;
  public valorDisponible: String = '';
  public valorAsignado: String = '';
  private id_selected_opc: number = -1;
  public datosDisponibles: any;
  public datosAsignados: any;
  public id_generalDoc: number = -1;

  public confirmDlgButtons: ButtonPropsModel[] = [
    { click: this.btnBorrar.bind(this), buttonModel: { content: 'Si', isPrimary: true } },
    { click: this.btnBorrar.bind(this), buttonModel: { content: 'No' } }];
  public id_proyecto_documento: number = -1;
  public id_tipodoc: number = -1;
  public id_proyecto_documento2: number = -1;
  public id_tipodoc2: number = -1;
  public buttons: Object = {
    browse: 'Elige archivo',
  };
  constructor(
    private route: ActivatedRoute,
    private gc_calidad_Service: GccalidadService,
    private http: HttpClient,
    private modalService: NgbModal
  ) {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.gc_calidad_Service.proyecto().subscribe((resp) => {
      this.data_proyecto = resp.respuesta;

    });
    this.gc_calidad_Service.tipodoc().subscribe((resp) => {
      this.data_tipo = resp.respuesta;
    });
  }


  ngOnInit(): void {
    this.route.data.subscribe(resp => {
      this.breadcrumbs1 = resp.rutas.respuesta.ruta;
      
    });
    this.dropEle = document.getElementById('droparea');
  }

  //METODO QUE OBTIENE LA INFORMACIÓN DE LA TABLA CON ID
  cargaDocumentos(id: number, id2: number, id3: number) {
    this.blockUI.start('Obteniendo informacion...');
    this.http.get<any>(`${environment.base_url}/gccalidad/listadodescarga/${id}/${id2}/${id3}`).subscribe((resp) => {
      this.data = resp.respuesta;
      this.blockUI.stop();
    });
  }
  onChangeProyecto(args): void {
    this.id_proyecto_documento = args.value;
    this.cargaDocumentos(-1, this.id_proyecto_documento, this.id_tipodoc);
  }
  onChangeTipo(args): void {
    this.id_tipodoc = args.value;
    this.cargaDocumentos(-1, this.id_proyecto_documento, this.id_tipodoc);
  }
  onChangeProyecto2(args): void {
    this.id_proyecto_documento2 = args.value;
    this.cargaAsignacionDoc();
  }
  onChangeTipo2(args): void {
    this.id_tipodoc2 = args.value;
    this.cargaAsignacionDoc();
  }

  cargaAsignacionDoc() {
    if (this.id_tipodoc2 <= 0 || this.id_proyecto_documento2 <= 0 || this.id_rh_empleado.nativeElement.value <= 0 || this.id_rh_empleado.nativeElement.value == '') {
      return;
    } else {
      this.gc_calidad_Service.lstDisponiblesDoc(this.id_rh_empleado.nativeElement.value, this.id_tipodoc2, this.id_proyecto_documento2).subscribe((resp) => {
        this.dataDisponibles = resp.respuesta;
      });
      this.gc_calidad_Service.lstAsignableDoc(this.id_rh_empleado.nativeElement.value, this.id_tipodoc2, this.id_proyecto_documento2).subscribe((resp) => {
        this.dataAsignadas = resp.respuesta;
      });
    }
  }

  public btnBorrar(id) {
    const modalRef = this.modalService.open(dlgMensajeConfirma);
    modalRef.componentInstance.encabezado = this.breadcrumbs2;
    modalRef.componentInstance.contenido = 'Deseas eliminar el registro ?';
    modalRef.result.then((resultado) => {
      if (resultado == 'Si') {
        this.http.post(`${environment.base_url}/gccalidad/configuracion/eliminar/${id}`, {}).subscribe((respuesta) => {
          if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
            this.cargaDocumentos(-1, this.id_proyecto_documento, this.id_tipodoc);
          } else {
            const modalRef = this.modalService.open(dlgMensajeError);
            modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
            modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
          }
        });
      }
    });
  }


  /**
   * Metodo que se ejecuta cada vez que seleccionamos un archivo, ya sea por arrastre o busqueda.
   * El archivo seleccionado será almacenado dentro de una variable global, la cual posteriormente
   * es utilizada para guardar el archivo con el metodo "guardarArchivo()"
   *
   * @param args Son las propiedades del componente uploader, para obtener el archivo seleccionado
   * que esta en la posicion 0. El uploader esta configurado para solo obtener 1 archivo a la vez
   */
  public selected(args: UploaderComponent) {
    this.archivos = args;
    this.guardarArchivo();
  }

  /**
   * Identificamos el componente de area de soltar, que será utilizada para arrastrar los archivos
   * y soltarlos.
   * Se tiene que haces este truco, ya que el componente de uploader se renderiza hasta que el dialog
   * se carga o visualiza la primera vez.
   * Asi mismo ocultamos el componente con el boton de "browser" y su área, de esta manera
   * dejamos solo el div personalizado donde se arrastra y el link paa abrir el buscador de archivos
   *
   * Esta se dispara cuando seleccionamos la pestña numero 1 (comenzamos a contar desde el Cero)
   *
   * @param e No utilizado por el momento
   */
  public handleSelectEvent(e: SelectEventArgs): void {
    if (e.selectedIndex === 1) {
      this.archivoArea = document.getElementById('droparea');
    }
  }

  /**
   * Metodo para lanzar el buscardor de archivos relacionado con el uploader.
   * @returns
   */
  public browseClick() {
    document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click(); return false;
  }

  public buscar() {
    const modalRef = this.modalService.open(DlgEmpleadoComponent);
    modalRef.componentInstance.empleado.subscribe(($event) => {
      this.tfNombre.nativeElement.value = $event.nombre + ' ' + $event.paterno + ' ' + $event.materno;
      this.id_rh_empleado.nativeElement.value = $event.id;
      this.cargaAsignacionDoc();
    });
  }

  /**
   * Metodo al momento de seleccionar una fila en la tabla toma los valores y los asigna
   * a variables.
   * @returns;
   **/
  public onRowSelected(args: any) {
    this.tfNombre.nativeElement.value = args.data.nombre;
    this.id_rh_empleado.nativeElement.value = args.data.id;
    this.cargaAsignacionDoc();
  }

  btnAsignar() {
    this.tfNombre.nativeElement.value = '';
    this.id_rh_empleado.nativeElement.value = '';
    this.id_generalDoc = -1;
    this.dataDisponibles = [];
    this.dataAsignadas = [];
    this.dlgAsignarPermiso.show();
  }

  public btnIdentificador(area: number) {
    this.id_selected_opc = area;
    this.datosDisponibles = Array(this.disponibles.value);
    this.datosAsignados = Array(this.asignadas.value);
    if (this.id_selected_opc == 1) {
      this.valorAsignado = '';
    } else if (this.id_selected_opc == 2) {
      this.valorDisponible = '';
    }
    this.valorDisponible = this.datosDisponibles.toString();
    this.valorAsignado = this.datosAsignados.toString();
  }

  public btnAgregarDocumentos(): void {
    if (this.valorDisponible == '' || this.valorDisponible == null || this.valorDisponible == undefined) {
      const modalRef = this.modalService.open(dlgMensajeError);
      modalRef.componentInstance.encabezado = this.breadcrumbs2;
      modalRef.componentInstance.contenido = 'No se ha seleccionado un documento';
      this.valorDisponible = '';
      this.disponibles.value = [];
      return;
    } else {
      this.blockUI.start('Procesando...');
      const body = {
        id: this.id_generalDoc,
        id_rh_empleado: this.id_rh_empleado.nativeElement.value,
        id_cal_proyecto_archivo: (this.valorDisponible)
      };
      this.http.post(`${environment.base_url}/gccalidad/configuracion/insertar_documento`, body).subscribe((respuesta) => {
        if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
          this.cargaAsignacionDoc();
          this.valorDisponible = '';
          this.disponibles.value = [];
          this.blockUI.stop();
        } else {
          const modalRef = this.modalService.open(dlgMensajeError);
          modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
          modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
          this.valorDisponible = '';
          this.disponibles.value = [];
          this.blockUI.stop();
        }
      });
    }
  }



  public btnQuitarDocumentos(): void {
    if (this.valorAsignado == '' || this.valorAsignado == null || this.valorAsignado == undefined) {
      const modalRef = this.modalService.open(dlgMensajeError);
      modalRef.componentInstance.encabezado = this.breadcrumbs2;
      modalRef.componentInstance.contenido = 'No se ha seleccionado un documento';
      this.valorAsignado = '';
      this.asignadas.value = [];
      return;
    } else {
      this.blockUI.start('Procesando...');
      const body = {
        id: (this.valorAsignado)
      };
      this.http.post(`${environment.base_url}/gccalidad/configuracion/borrar_documento`, body).subscribe((respuesta) => {
        if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
          this.cargaAsignacionDoc();
          this.valorAsignado = '';
          this.asignadas.value = [];
          this.blockUI.stop();
        } else {
          const modalRef = this.modalService.open(dlgMensajeError);
          modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
          modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
          this.valorAsignado = '';
          this.asignadas.value = [];
          this.blockUI.stop();
        }
      });
    }
  }

  public guardarArchivo() {
    if (this.archivos == undefined) {
      const modalRef = this.modalService.open(dlgMensajeError);
      modalRef.componentInstance.encabezado = this.breadcrumbs2;
      modalRef.componentInstance.contenido = 'No se ha seleccionado un archivo';
      return;
    }

    if (this.id_proyecto_documento == undefined || this.id_proyecto_documento <= 0 || this.id_proyecto_documento == null) {
      const modalRef = this.modalService.open(dlgMensajeError);
      modalRef.componentInstance.encabezado = this.breadcrumbs2;
      modalRef.componentInstance.contenido = 'No se ha seleccionado un proyecto';
      return;
    }
    if (this.id_tipodoc == undefined || this.id_tipodoc <= 0 || this.id_tipodoc == null) {
      const modalRef = this.modalService.open(dlgMensajeError);
      modalRef.componentInstance.encabezado = this.breadcrumbs2;
      modalRef.componentInstance.contenido = 'No se ha seleccionado un tipo de documento';
      return;
    }
    this.blockUI.start('Procesando...');
    this.formulario = new FormData();
    this.formulario.append('archivo', this.archivos.filesData[0].rawFile, this.archivos.filesData[0].name);
    this.formulario.append('Content-Type', 'application/json');
    this.formulario.append('Accept', `application/json`);
    this.http.post(`${environment.base_url}/gccalidad/configuracion/subirArchivo/${this.id_proyecto_documento}/${this.id_tipodoc}`, this.formulario).subscribe(resp => {
      let respuesta = JSON.parse(JSON.stringify(resp));
      if (respuesta.numero > 0) {
        //==============================================================================
        // Cargamos los nuevos valores de los archivos procesados
        //==============================================================================
        this.cargaDocumentos(-1, this.id_proyecto_documento, this.id_tipodoc);
        //==============================================================================
        // Utilizacion de "ej2_instances" para poder ejecutar un metodo dentro del componente
        // Este componente no se utilizo por ViewChild, ya que no se renderiza al inicio
        //==============================================================================
        (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
        //==============================================================================
        this.blockUI.stop();
        this.archivos = undefined;
      } else {
        const modalRef = this.modalService.open(dlgMensajeError);
        modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
        modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
        this.blockUI.stop();
      }
    });
  }


}
