import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'Arcarius ERP 10.0';

	constructor(translate: TranslateService) {
		// this language will be used as a fallback when a translation isn't found in the current language
		translate.addLangs(['en', 'es']);
		translate.setDefaultLang('es');

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		const browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|es/) ? browserLang : 'es');
	}

	ngOnInit() { }

}
