<div class="modal-header">
  <h4 class="modal-title">Calibracion de Patrones</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('-1')"></button>
</div>
<div class="modal-body">
  <form autocomplete="off" [formGroup]="formulario" (ngSubmit)="Guardar()">
    <div class="row">
      <div class='form-group col-md-4'>
        <label for="codigo">Codigo:</label>
        <input type='text' required class="form-control" id='codigo' formControlName="codigo" />
        <small *ngIf="codigoNoValido" class="text-danger">Se requiere un codigo</small>
      </div>
      <div class='form-group col-md-6'>
        <label for="alcance">Alcance:</label>
        <input type='text' required class="form-control" id='ancance' formControlName="alcance" />
        <small *ngIf="alcanceNoValido" class="text-danger">Se requiere un alcance</small>
      </div>
      <div class='form-group col-md-2'>
        <label for="clase">Clase:</label>
        <input type='text' required class="form-control" id='clase' formControlName="clase" />
        <small *ngIf="claseNoValida" class="text-danger">Se requiere una clase</small>
      </div>
    </div>
    <div class="row">
      <div class='form-group col-md-4'>
        <label for="fecha_calibracion">Fecha de clibracion:</label>
        <ejs-datepicker format='dd/MM/yyyy' formControlName="fecha_calibracion">
        </ejs-datepicker>
        <small *ngIf="fechaNoValida" class="text-danger">Se requiere una fecha de calibracion</small>
      </div>
      <div class='form-group col-md-8'>
        <label for="trazabilidad">Trazabilidad:</label>
        <input type='text' required class="form-control" id='trazabilidad' formControlName="trazabilidad" />
        <small *ngIf="trazabilidadNoValida" class="text-danger">Se requiere una trazabilidad</small>
      </div>
    </div>
    <div class="row">
      <div class='form-group col-md-3'>
        <label for="periodo_calibracion">Periodo:</label>
        <input type='text' required class="form-control" id='periodo_calibracion'
          formControlName="periodo_calibracion" />
        <small *ngIf="peridoNoValido" class="text-danger">Se requiere un periodo</small>
      </div>
      <div class='form-group col-md-3'>
        <label for="estatus_calibracion">{{"Documento.Estatus" | translate}}</label>
        <input type='text' required class="form-control" id='estatus_calibracion'
          formControlName="estatus_calibracion" />
        <small *ngIf="estatusNoValido" class="text-danger">Se requiere un Estatus</small>
      </div>
      <div class="col-md-3">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" formControlName="meteorometro">
          <label class="form-check-label">
            Meteorometro
          </label>
        </div>

        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" formControlName="valor_tabla">
          <label class="form-check-label">
            Valor
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class='form-group col-md-8'>
        <label>Texto certificado:</label>
        <input type='text' required class="form-control" id='texto_certificado' formControlName="texto_certificado" />

      </div>
      <div class='form-group col-md-4'>
        <label>Vigencia:</label>
        <ejs-datepicker format='dd/MM/yyyy' formControlName="vigencia">
        </ejs-datepicker>
      </div>
    </div>
    <div class="row">
      <div class='form-group col-md-4'>
        <label>Temperatura:</label>
        <input type="text" required class="form-control" id="temperatura" formControlName="temperatura">
      </div>
      <div class='form-group col-md-4'>
        <label>Humedad:</label>
        <input type='text' required class="form-control" id='humedad' formControlName="humedad" />
      </div>
      <div class='form-group col-md-4'>
        <label>Presion:</label>
        <input type='text' required class="form-control" id='presion' formControlName="presion" />
      </div>
    </div>
    <div class="row">
      <div class='form-group col-md-4'>
        <label>Incertidumbre_t:</label>
        <input type='text' required class="form-control" id='incertidumbre_t' formControlName="incertidumbre_t" />
      </div>
      <div class='form-group col-md-4'>
        <label>Incertidumbre_hr:</label>
        <input type='text' required class="form-control" id='incertidumbre_hr' formControlName="incertidumbre_hr" />
      </div>
      <div class='form-group col-md-4'>
        <label>Incertidumbre_p:</label>
        <input type='text' required class="form-control" id='incertidumbre_p' formControlName="incertidumbre_p" />
      </div>
    </div>
  </form>
</div>
<div class="footer">
  <button class="btn btn-guardar" (click)="Guardar()"> {{"Boton.Guardar" | translate}}</button>
</div>
