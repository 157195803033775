<div class="breadcrumb_navbar">
    <ul class="breadcrumb">
        <li><a>{{this.breadcrumbs1}}</a></li>
        
    </ul>
</div>
<block-ui>
</block-ui>
<section>
    <app-loader></app-loader>
    <div class="row">
        <div class="col-md-8">
            <span>
                <i style="float: left; font-size: 48px; color: #ff9800;" class="las la-user-tie"></i>
                <h3>Bienvenido</h3>
            </span> {{data.nombre}}
        </div>
        <div class="col-md-4">
            <img id="PerfilImagen" [src]="imagen" class="rounded float-right" width="150" height="150">
        </div>
    </div>

    <ngb-accordion [closeOthers]="true" (panelChange)="beforeChange($event)">
        <ngb-panel id="pnImagen" title="Imagen de Usuario">
            <ng-template ngbPanelContent>
                <img id="PerfilImagen" [src]="imagen" class="rounded float-right" width="150" height="150">
                <hr>
                <button class="btn btn-basico" (click)="btnImagen()">
                    <i class="far fa-file-image"></i> Subir imagen
                </button>
                <button class="btn btn-guardar" (click)="guardarImagen()">
                    Guardar Imagen
                </button>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnPassword" title="Contraseña">
            <ng-template ngbPanelContent>
                <form autocomplete="off" [formGroup]="formulario" (ngSubmit)="btnGuardar()">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="tfUsuario" class="form-label">Usuario</label>
                                <input type="text" id="tfUsuario" class="form-control" formControlName="usuario" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="tfPassword1" class="form-label">Contraseña</label>
                                <input type="text" id="tfPassword1" class="form-control" placeholder="contraseña" formControlName="password">
                            </div>
                            <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
                                <div *ngIf="password.errors.required">
                                    La contraseña es Obligatoria
                                </div>
                                <div *ngIf="password.errors.minlength">
                                    Al menos utilizar 4 caracteres
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="tfPassword2" class="form-label">Confirmar Contraseña</label>
                                <input type="text" id="tfPassword2" class="form-control" placeholder="la misma contraseña" formControlName="password2">
                                <div *ngIf="password2.invalid && (password2.dirty || password2.touched)" class="alert alert-danger">
                                    <div *ngIf="password2.errors.required">
                                        La contraseña es Obligatoria
                                    </div>
                                    <div *ngIf="password2.errors.minlength">
                                        Al menos utilizar 4 caracteres
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <hr>
                            <button type="submit" class="btn btn-guardar" [disabled]="formulario.invalid">
                                <i class="las la-save"></i> Guardar
                            </button>
                        </div>
                    </div>
                </form>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnCorreo" title="Correo Electronico">
            <ng-template ngbPanelContent>
                <form autocomplete="off" [formGroup]="frmCorreo" (ngSubmit)="btnGuardarCorreo()">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="tfNombre" class="form-label">Nombre del Correo</label>
                                <input type="text" id="tfNombre" class="form-control" formControlName="correo_nombre">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="tfUsuario" class="form-label">Usuario</label>
                                <input type="text" id="tfUsuario" class="form-control" formControlName="correo_usuario">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="tfServidorPOP" class="form-label">Servidor POP</label>
                                <input type="text" id="tfServidorPOP" class="form-control" formControlName="pop3">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="tfPuertoPOP" class="form-label">Puerto POP</label>
                                <input type="text" id="tfPuertoPOP" class="form-control" formControlName="correo_puerto_pop3">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="tfServidorSMTP" class="form-label">Servidor SMTP</label>
                                <input type="text" id="tfServidorSMTP" class="form-control" formControlName="smtp">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="tfPuertoSMTP" class="form-label">Puerto SMTP</label>
                                <input type="text" id="tfPuertoSMTP" class="form-control" formControlName="correo_puerto_smtp">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                          <div class="form-group">
                              <label for="tfCorreo" class="form-label">Correo Electronico</label>
                              <input type="text" id="tfCorreo" class="form-control" formControlName="correo_direccion">
                          </div>
                      </div>
                      <div class="col-md-2">
                          <div class="form-group">
                              <label for="tfPassword3" class="form-label">Contraseña</label>
                              <input type="text" id="tfPassword3" class="form-control" formControlName="correo_clave">
                          </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="tfAutenticacion" formControlName="autenticacion">
                          <label class="form-check-label" for="tfAutenticacion">
                            Autenticacion
                          </label>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="ssl_pop" formControlName="ssl_pop">
                          <label class="form-check-label" for="ssl_pop">
                            SSL POP
                          </label>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="ssl_smtp" formControlName="ssl_smtp">
                          <label class="form-check-label" for="ssl_smtp">
                            SSL SMTP
                          </label>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="tfTLS" formControlName="tls">
                          <label class="form-check-label" for="tfTLS">
                            TLS
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                          <button type="submit" class="btn btn-guardar" [disabled]="frmCorreo.invalid">
                              <i class="las la-save"></i> Guardar
                          </button>
                      </div>
                  </div>

                </form>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnFirmas" title="Firmas para Correos">
            <ng-template ngbPanelContent>
              <div class="row">
                <div class="col-md-3">
                  <div class='form-group'>
                    <label for='id_con_periodo'>Documento
                    </label>
                    <select #lstDocumentos *ngIf="dataDocumentos" class="form-control" id='id_doc_tipo' (change)="getDocumentoFirma($event.target.value)">
                      <option [ngValue]="null" >Selecciona Documento</option>
                      <option *ngFor="let item of dataDocumentos" value="{{item.id}}" [selected]="this._id_rh_empleado_firma === item.id">{{item.descripcion}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                  <div class="col-md-12">
                      <div class="form-group">
                          <label for="tfCuerpo" class="form-label">Cuerpo del correo</label>
                          <ejs-richtexteditor #tfFirmaTexto [toolbarSettings]='tools' [insertImageSettings]='imageSettings' height="150px">
                          </ejs-richtexteditor>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <button type="submit" class="btn btn-guardar" (click)="btnGuardarDocumento()">
                          <i class="las la-save"></i> Guardar Documento
                      </button>
                  </div>
              </div>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnFirmaImagen" title="Imagen Firma de Correo">
            <ng-template ngbPanelContent>
                <img id="PerfilImagenFirma" [src]="imagenFirma" class="rounded float-right" width="300" height="150">
                <hr>
                <button class="btn btn-basico" (click)="btnImagenFirma()">
                    <i class="far fa-file-image"></i> Subir imagen
                </button>

                <button class="btn btn-guardar" (click)="guardarImagenFirma()">
                    Guardar Firma
                </button>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</section>


<!-- Componente para obtener imagen del usuario -->
<ejs-dialog #dlgimagen width='400px' isModal='true' [visible]='false' showCloseIcon='true' (open)="openImagen()">
    <ng-template #header>
        <div id="dlg-template" class="e-icon-settings"><i class="fas fa-dice-d6"></i> Imagen</div>
    </ng-template>
    <ng-template #content>
        <div style="text-align: center;">
            <img class="preview-image" #imgRenderer [src]="imageFile ? imageFile.link : '/assets/img/favicon.png'" style="max-width: 350px;">
        </div>
        <div id='dropareaImg' style="height: 70px; background: #e9f5db; text-align: center; padding-top: 20px;">
            Soltar aqui tu archivo
            <a href="" id="browse" (click)='browseClickImg()'><u>Buscar Archivo</u></a>
            <ejs-uploader id="archivoCargaImg" [autoUpload]='false' [dropArea]='dropEleImg' multiple='false' (selected)="selectedImg($event)">
                <ng-template #template let-data>
                    <span class="fileListwrapper">
                        <span class="icon template-icons sf-icon-{{data.type}}"></span>
                    <span class="name file-name">{{data.name}} ({{data.size}}
                            bytes)</span>
                    <span class="upload-status">{{data.status}}</span>
                    </span>
                    <span class="e-icons e-file-remove-btn" title="Remove"></span>
                </ng-template>
            </ejs-uploader>
        </div>

    </ng-template>
    <ng-template #footerTemplate>
        <button class="btn btn-primary" (click)="btnPegarImagen()">
            <i class="far fa-file-image"></i> Pegar Imagen
        </button>
        <button class="btn btn-success" (click)="btnImportar()">
            <i class="far fa-file-image"></i> Importar
        </button>
    </ng-template>
</ejs-dialog>


<!-- Componente para obtener imagen para Firma del usuario -->
<ejs-dialog #dlgimagenFirma width='400px' isModal='true' [visible]='false' showCloseIcon='true' (open)="openImagenFirma()">
  <ng-template #header>
      <div id="dlg-template" class="e-icon-settings"><i class="fas fa-dice-d6"></i> Imagen</div>
  </ng-template>
  <ng-template #content>
      <div style="text-align: center;">
          <img class="preview-image" #imgFirmaRenderer [src]="imageFile ? imageFile.link : '/assets/img/favicon.png'" style="max-width: 350px;">
      </div>
      <div id='dropareaImgFirma' style="height: 70px; background: #e9f5db; text-align: center; padding-top: 20px;">
          Soltar aqui tu archivo
          <a href="" id="browse" (click)='browseClickImgFirma()'><u>Buscar Archivo</u></a>
          <ejs-uploader id="archivoCargaImg" [autoUpload]='false' [dropArea]='dropEleImgFirma' multiple='false' (selected)="selectedImgFirma($event)">
              <ng-template #template let-data>
                  <span class="fileListwrapper">
                      <span class="icon template-icons sf-icon-{{data.type}}"></span>
                  <span class="name file-name">{{data.name}} ({{data.size}}
                          bytes)</span>
                  <span class="upload-status">{{data.status}}</span>
                  </span>
                  <span class="e-icons e-file-remove-btn" title="Remove"></span>
              </ng-template>
          </ejs-uploader>
      </div>

  </ng-template>
  <ng-template #footerTemplate>
      <button class="btn btn-primary" (click)="btnPegarImagenFirma()">
          <i class="far fa-file-image"></i> Pegar Imagen
      </button>
      <button class="btn btn-success" (click)="btnImportarFirma()">
          <i class="far fa-file-image"></i> Importar
      </button>
  </ng-template>
</ejs-dialog>
