<block-ui>
</block-ui>
<section>
    <div class="card">
        <div class="card-title mb-1 p-2">
            <h5>{{titulo}}</h5>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <form [formGroup]="formulario">
                      <div class='form-group'>
                        <label>Descripción:</label>
                        <input type="text" class="form-control" placeholder='descripcion' formControlName="descripcion">
                        <small *ngIf="descripcionNoValida" class="text-danger">Se requiere una descripción</small>
                    </div>
                    </form>
                    <div class="callout callout-botones">
                        <button type="button" class="btn btn-guardar" (click)="btnGuardar()">Guardar</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>