import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { GccalidadService } from './gccalidad.service';

@Injectable({
  providedIn: 'root'
})
export class ParametrosResolverFrmEjecuta implements Resolve<boolean> {

  constructor(
    private servicio: GccalidadService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
    return this.servicio.lstFormulario(route.params['id']);
  }
}
