<ejs-dialog #dlgComentario isModal='true' [visible]='false' [animationSettings]='animationSettings'
    [showCloseIcon]='true' [width]='800' (open)="dialogOpen()" (close)="dialogClose()">
    <ng-template #header>
        <div class="e-icon-settings"><i class="fa-solid fa-comments"></i> Comentario</div>
    </ng-template>
    <ng-template #content>
        <ejs-richtexteditor #editor [toolbarSettings]='tools' [insertImageSettings]='imageSettings' height="150px">
        </ejs-richtexteditor>
    </ng-template>
    <ng-template #footerTemplate>
        <button class="btn btn-guardar" style="margin-left: 5px; float: right;" (click)="setDescripcion()">
            Guardar
        </button>
    </ng-template>
</ejs-dialog>