import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErroresService } from 'src/app/shared/errores.service';

@Injectable({
	providedIn: 'root',
})
export class EmpleadoService {
	constructor(
		private http: HttpClient, private errores: ErroresService
	) { }


	public buscar(referencia: string): Observable<any> {
		const parametros: HttpParams = new HttpParams().append('referencia', referencia);
		return this.http.get<any>(`${environment.base_url}/rrhh/empleado/buscar`, {
			params: parametros,
		}).pipe(
			catchError(error => {
				return throwError(this.errores.getErrores(error));
			})
		);
	}

	public cargaData(id: number): Observable<any> {
		return this.http.get<any>(`${environment.base_url}/rrhh/gestion/DataEmpleado/${id}`)
			.pipe(
				catchError(error => {
					return throwError(this.errores.getErrores(error));
				})
			);
	}

	public CargaHorarios(id: number): Observable<any> {
		return this.http.get<any>(`${environment.base_url}/rrhh/gestion/horario/lstHorario/${id}`)
			.pipe(
				catchError(error => {
					return throwError(this.errores.getErrores(error));
				})
			);
	}

	public DataEmpleado(id: number): Observable<any> {
		return this.http.get<any>(`${environment.base_url}/rrhh/gestion/DataEmpleado/${id}`)
			.pipe(
				catchError(error => {
					return throwError(this.errores.getErrores(error));
				})
			);
	}

}