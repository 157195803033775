import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
    selector: 'dlgComentario',
    templateUrl: './dlgComentario.component.html',
    styleUrls: ['./dlgComentario.component.scss']
})
export class DlgComentarioComponent implements OnInit {
    @ViewChild('dlgComentario') public dlgComentario: DialogComponent;
    @ViewChild('editor') public editor: RichTextEditorComponent;
    public tools: object = {
        items: [
            'Bold', 'Italic', 'Underline', '|', 'FontSize', 'FontColor', 'BackgroundColor'
        ]
    };
    public imageSettings: object = { saveFormat: "Base64" };
    public animationSettings: Object = { effect: 'Zoom' };
    @Output('descripcion') descripcion = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void {
    }

    @Input('Mostrar') Mostrar() {
        this.editor.value = '';
        this.dlgComentario.show();
    };

    @Input('Ocultar') Ocultar() {
        this.dlgComentario.hide();
    };

    public setDescripcion() {
        this.descripcion.emit(this.editor.value);
    }

    public dialogOpen() {
        this.editor.refreshUI();
    }

    public dialogClose() {

    }

}
