import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class RutasResolver implements Resolve<boolean> {

	constructor(
		private http: HttpClient,
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		let parametros: HttpParams = new HttpParams().append('ruta', state.url);
		return this.http.get<any>(`${environment.base_url}/auth/getRutas`, { params: parametros });
	}
}
