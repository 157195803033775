<div class="modal-header" style="background-color: #ff5722;">
    <h5 class="modal-title encabezado">{{encabezado}}</h5>
</div>
<div class="modal-body">
    <div class="contenido" [innerHtml]="contenido | safeHtml"></div>
</div>
<div class="modal-footer piepagina">
    <button class="btn boton" autofocus aria-label="Close" (click)="activeModal.close('Close click')">
        Entendido
    </button>
</div>
