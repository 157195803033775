import { Component, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from '../dlgMensajeError/dlgMensajeError.component';

@Component({
    selector: 'dlgEstatus',
    templateUrl: './dlgEstatus.component.html'
})

export class DlgEstatusComponent {

    @Input() tipo: number;
    @Input() id: number;
    @Input() formulario: number = -1;
    @Output('estatus') estatus = new EventEmitter<number>();
    public data: any[];

    @Input() carga() {
        this.getListado();
    };

    constructor(
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private http: HttpClient
    ) { }

    /**
     * Metodo para obtener listado de estatus disponibles para el usuario firmado
     * ! Los estatus dependerán de los permisos que tenga asignado dicho usuario a determinado
     * ! documento, aplicando las reglas de asignación de estatus a documento y posible
     * ! asignacion de manera repetida.
     */
    public getListado() {
        this.http.get<any>(`${environment.base_url}/sistema/lstEstatusAsigna/${this.id}/${this.tipo}/${this.formulario}`, {}).subscribe(
            resp => {
                if (resp.numero > 0) {
                    this.data = resp.respuesta;
                } else {
                    const modalRef = this.modalService.open(dlgMensajeError);
                    modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
                    modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
                }
            }
        );
    }

    /**
     * ! Metodo para asignar el ID del boton seleccionado y devolverlo por medio del
     * ! emit para ser valorado por algun metodo que lo llamo previamente
     * ? El metodo o proceso mas comun despues de devolver el ID es asignarlo a su tabla
     * ? de historial de cambios de estatus.
     * @param id
     */
    public setEstatus(id: number) {
        this.modalService.dismissAll();
        this.estatus.emit(id);
    }

    /**
     * Metodo para cerrar la ventana modal
     */
    public close() {
        this.modalService.dismissAll();
    }

}
