import { Component, HostListener, OnDestroy, TemplateRef } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { MdlUsuario } from '../../models/MdlUsuario';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public barraOculta: boolean = false;
  public usuario: MdlUsuario;
  public cargando = false;
  public data: any;
  public dataNotificacion: any;
  public mensajes = false; //Sirve para el ngIf si existen o no mensajes sin leer
  public myObservable: any;
  public numero_notificaciones = 0;
  public intervalo: any;
  public position = { X: 'right', Y: 'Bottom' }; //Posición del toast
  public maxCount: number = 1; //Variable para el limite de toast
  public animations = { show: { effect: 'SlideRightIn' }, hide: { effect: 'SlideLeftOut' } }; //Animación del toast

  constructor(
    private appService: AppService,
    private usuarioService: UsuarioService,
    private router: Router,
    private http: HttpClient,
    public domSanitizer: DomSanitizer,
    private modalService: NgbModal
  ) {
    // this.route.data.subscribe((resp: { atajos: any[] }) => {
    //   this.data = JSON.parse(JSON.stringify(resp.atajos)).respuesta;
    // });
    this.http.get<any>(`${environment.base_url}/auth/menuatajo`).subscribe(resp => {
      this.data = resp.respuesta;
    });

    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.cargando = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.cargando = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  /**
   * Metodo para calcular el ancho de la pantalla sobre PC y determinar el
   * ancho a utilizar considerando el sidebar abierto o cerrado
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 480) {
      if (this.barraOculta) {
        //document.body.style.width = window.innerWidth - 280 + 'px';
      } else {
        //document.body.style.width = window.innerWidth - 90 + 'px';
      }
    }
  }

  /**
   * Metodo para abrir y cerrar sidebar, asi como para calcular y determinar
   * el ancho a utilizar considerando el sidebar abierto o cerrado
   */
  toggleSidebarPin() {
    this.appService.toggleSidebarPin();
    this.barraOculta = !this.barraOculta;
    if (window.innerWidth > 480) {
      if (this.barraOculta) {
        //document.body.style.width = window.innerWidth - 280 + 'px';
        document.getElementById('btnBarraOcultar').style.inset = '10px 0px 0px 58px';
      } else {
        //document.body.style.width = window.innerWidth - 90 + 'px';
        document.getElementById('btnBarraOcultar').style.inset = '10px 0px 0px 250px';
      }
    }
  }

  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  logout(): void {
    this.usuarioService.logout();
  }

}
