import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { util } from 'src/app/shared/util';
import { CorreoService } from './services/correo.service';

interface iCorreo {
    emisor: string;
    fecha: Date;
    mensaje: string;
    subject: string;
}

@Component({
    selector: 'app-correo',
    templateUrl: './correo.component.html',
    styleUrls: ['./scss/styles-light.scss']
})
export class CorreoComponent implements OnInit {
    public breadcrumbs1: string = '';
    public breadcrumbs2: string = '';
    @BlockUI() blockUI: NgBlockUI;

    public usuario: string;
    public data: any;
    public itemCorreo: iCorreo;

    constructor(
        private route: ActivatedRoute,
        private servicio: CorreoService,
        private utilerias: util
    ) { }

    ngOnInit(): void {
        this.route.data.subscribe((resp) => {
            this.breadcrumbs1 = resp.rutas.respuesta.ruta;

        });
        this.usuario = JSON.parse(localStorage.getItem('usuario')).nombre;

        this.getCorreos();

    }

    public getCorreos() {
        this.blockUI.start('Obteniendo correos del buzon...');
        this.servicio.getCorreo().subscribe(resp => {
            this.data = JSON.parse(JSON.stringify(resp.respuesta), this.utilerias.parseFecha);
            console.log(this.data);
            this.blockUI.stop();
        });
    }

    public cargaCorreo(item: any) {
        this.itemCorreo = item;
    }

}
