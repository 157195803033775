import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { PatronesService } from '../../services/Patron.service';
import { MdlPatron } from '../models/MdlPatron';

@Component({
  selector: 'app-patrondialogo',
  templateUrl: './patrondialogo.component.html',
  styleUrls: ['./patrondialogo.component.scss']
})
export class PatrondialogoComponent {

  public formulario: UntypedFormGroup;
  @BlockUI() blockUI: NgBlockUI;
  public fields: Object = { text: 'descripcion', value: 'id' };
  @Input('datos') datos: any;
  @Output('cerrar') cerrar = new EventEmitter<number>();

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private service: PatronesService,
    private fb: UntypedFormBuilder
  ) {
    this.formulario = this.fb.group(new MdlPatron());

    setTimeout(() => {
      if (this.datos) {
        this.formulario = this.fb.group({
          id: this.datos.id,
          codigo: [this.datos.codigo, Validators.required],
          alcance: [this.datos.alcance, Validators.required],
          clase: [this.datos.clase, Validators.required],
          fecha_calibracion: [this.datos.fecha_calibracion, Validators.required],
          trazabilidad: [this.datos.trazabilidad, Validators.required],
          periodo_calibracion: [this.datos.periodo_calibracion, Validators.required],
          estatus_calibracion: [this.datos.estatus_calibracion, Validators.required],
          meteorometro: [this.datos.meteorometro, Validators.required],
          valor_tabla: [this.datos.valor_tabla, Validators.required],
          texto_certificado: [this.datos.texto_certificado, Validators.required],
          vigencia: [this.datos.vigencia, Validators.required],
          temperatura: [this.datos.temperatura, Validators.required],
          humedad: [this.datos.humedad, Validators.required],
          presion: [this.datos.presion, Validators.required],
          incertidumbre_t: [this.datos.incertidumbre_t, Validators.required],
          incertidumbre_hr: [this.datos.incertidumbre_hr, Validators.required],
          incertidumbre_p: [this.datos.incertidumbre_p, Validators.required],
        });
      }
    }, 1000);
  }

  get codigoNoValido() {
    return (
      this.formulario.get('codigo').invalid &&
      this.formulario.get('codigo').touched
    );
  }

  get alcanceNoValido() {
    return (
      this.formulario.get('alcance').invalid &&
      this.formulario.get('alcance').touched
    );
  }

  get claseNoValida() {
    return (
      this.formulario.get('clase').invalid &&
      this.formulario.get('clase').touched
    );
  }

  get fechaNoValida() {
    return (
      this.formulario.get('fecha_calibracion').invalid &&
      this.formulario.get('fecha_calibracion').touched
    );
  }

  get trazabilidadNoValida() {
    return (
      this.formulario.get('trazabilidad').invalid &&
      this.formulario.get('trazabilidad').touched
    );
  }

  get peridoNoValido() {
    return (
      this.formulario.get('periodo_calibracion').invalid &&
      this.formulario.get('periodo_calibracion').touched
    );
  }

  get estatusNoValido() {
    return (
      this.formulario.get('estatus_calibracion').invalid &&
      this.formulario.get('estatus_calibracion').touched
    );
  }
  public revisa() {
    if (this.formulario.invalid) {
      return Object.values(this.formulario.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }

  /*
  * Metodo utilizado para guardar patrones
  *
  */
  public Guardar() {
    this.revisa();
    if (!this.formulario.invalid) {
      this.blockUI.start('Procesando...');
      this.service.guardar(this.formulario.value).subscribe((resp) => {
        console.log(resp);
        let respuesta = JSON.parse(JSON.stringify(resp));
        if (respuesta.numero > 0) {
          this.cerrar.emit(1);
          this.modalService.dismissAll();
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          const modalRef = this.modalService.open(dlgMensajeError);
          modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
          modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
        }
      });
    }
  }

}
