import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListadoComponent } from './listado/listado.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { ControlcalidadComponent } from './controlcalidad/controlcalidad.component';
import { DescargaComponent } from './descarga/descarga.component';
import { EjecutaComponent } from './ejecuta/ejecuta.component';
import { FormulariosComponent } from './formularios/formularios.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { ListadoResolver } from 'src/app/services/GCcalidad/listado.resolver';
import { FormularioComponent } from './formulario/formulario.component';
import { ParametrosResolverFrm } from './services/parametrosfrm.resolver';
import { ParametrosResolverFrmEjecuta } from './services/parametrosfrmEjecuta.resolver';
import { RutasResolver } from 'src/app/services/rutas.resolver';
import { MatrizcomunicacionComponent } from './matrizcomunicacion/matrizcomunicacion.component';
import { PatronComponent } from './Patron/patron.component';


const routes: Routes = [
    { path: 'configuracion', component: ConfiguracionComponent, resolve: { rutas: RutasResolver } },
    { path: 'controlcalidad', component: ControlcalidadComponent, resolve: { rutas: RutasResolver } },
    { path: 'descarga', component: DescargaComponent, resolve: { rutas: RutasResolver } },
    { path: 'formularios', component: FormulariosComponent, resolve: { rutas: RutasResolver } },
    { path: 'formulario/:id/:id2', component: FormularioComponent, resolve: { rutas: RutasResolver, parametros: ParametrosResolverFrm } },
    { path: 'ejecuta/:id', component: EjecutaComponent, resolve: { rutas: RutasResolver, parametros: ParametrosResolverFrmEjecuta } },
    { path: 'listado', component: ListadoComponent, resolve: { rutas: RutasResolver, listado: ListadoResolver } },
    { path: 'proyectos', component: ProyectosComponent, resolve: { rutas: RutasResolver } },
    { path: 'patrones', component: PatronComponent, resolve: { rutas: RutasResolver } },
    { path: 'matrizcomunicacion', component: MatrizcomunicacionComponent, resolve: { rutas: RutasResolver } }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CalidadRoutingModule { }
