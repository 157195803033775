import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgModel, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownList, ListBox } from '@syncfusion/ej2-angular-dropdowns';
import { Grid } from '@syncfusion/ej2-angular-grids';
import { NumericTextBox } from '@syncfusion/ej2-angular-inputs';
import {
  ButtonPropsModel,
  DialogComponent,
  DialogUtility,
} from '@syncfusion/ej2-angular-popups';
import { environment } from 'src/environments/environment';
import { MdlComentarios } from '../models/MdlComentarios';
import { MdlFormulario } from '../models/MdlFormulario';
import { MdlOpcionesAgregar } from '../models/MdlOpcionesAgregar';
import { MdlParametros } from '../models/MdlParametros';
import { GccalidadService } from '../services/gccalidad.service';
import { EmitType } from '@syncfusion/ej2-base';
import { BlockUI, NgBlockUI } from 'ng-block-ui';


@Component({
  selector: 'app-formularios',
  templateUrl: './formularios.component.html',
  styleUrls: ['./formularios.component.scss'],
})
export class FormulariosComponent implements OnInit {
  public breadcrumbs1: string = '';
  public breadcrumbs2: string = '';

  @ViewChild('templateX') public tempX: NgModel;
  @ViewChild('grid') public grid: Grid;
  @ViewChild('tabla') tabla: Grid;
  @ViewChild('referencia') referencia: ElementRef;
  @ViewChild('id_frm_encabezado') id_frm_encabezado: ElementRef;
  @ViewChild('referenciaformulario') referenciaformulario: ElementRef;
  @ViewChild('tfNombre') tfNombre: ElementRef;
  @ViewChild('id_frm_campo_tipo') id_frm_campo_tipo: DropDownList;
  @ViewChild('id_rpt_encabezado') id_rpt_encabezado: DropDownList;
  @ViewChild('id_frm_encabezado_clasificacion') id_frm_encabezado_clasificacion: DropDownList;
  @ViewChild('folionumero') folionumero: NumericTextBox;
  @ViewChild('lstOpcionDisponibles') lstOpcionDisponibles: ListBox;
  @ViewChild('lstOpcionAsignadas') lstOpcionAsignadas: ListBox;
  @ViewChild('dlgComentario') dlgComentario: DialogComponent;
  @ViewChild('dlgNuevoFormulario') dlgNuevoFormulario: DialogComponent;
  @ViewChild('dlgBusquedaFormulario') dlgBusquedaFormulario: DialogComponent;
  @ViewChild('dlgParametrosOpciones') dlgParametrosOpciones: DialogComponent;
  @ViewChild('dlgOpcionesSeleccion') dlgOpcionesSeleccion: DialogComponent;
  @ViewChild('dlgOpcionesAgregar') dlgOpcionesAgregar: DialogComponent;
  @BlockUI() blockUI: NgBlockUI;
  /**
   * VARIABLES CON INFORMACIÓN DE CONSULTAS A LA BD
   **/
  public data: any;
  public columns: Object[];
  public dataformularios: any;
  public dataFrmListado: any;
  public columnsformulario: Object[];
  public dataopcionesformularios: any;
  public columnsopcionesformulario: Object[];
  public data_formulario: any;
  public data_clasifica_formulario: any;
  public data_reportes: any;
  public data_tipoParametro: any;
  public dataOpcionesDisponibles: any;
  public dataOpcionesAsignadas: any;
  public dataComentarios: any;
  public dataCondicionados: any;
  public inteval_Opciones: any;
  /**
   * VARIABLES LOCALES UTLIZADAS
   **/
  public id_frm_encabezado_DropList: number = 0;
  public UtilComponent: any;
  public id_frm_opciones: number = -1;
  public id_frm_detalles: number = -1;
  public id_frm: number = -1;
  public id_frm_deta_opc: number = -1;
  private id_selected_opc: number = -1;
  public id_parametro_select: number = -1;
  public valorDisponible: String = '';
  public valorAsignado: String = '';
  public datosDisponibles: any;
  public datosAsignados: any;
  public id_frm_encabezadoValida: boolean;
  /**
   * VARIABLES FORMULARIOS
   **/
  public frmComentario: UntypedFormGroup = new UntypedFormGroup({});
  public Listadoformulario: UntypedFormGroup;
  public Nuevoformulario: UntypedFormGroup;
  public AgregarOpciones: UntypedFormGroup;
  public NuevoParametro: UntypedFormGroup;
  /**
   * VARIABLES DE CONFIGURACIÓN EN COMPONENTES
   **/
  public fields: Object = { text: 'descripcion', value: 'id' };
  public tools: object = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      '|',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      '|',
      'Formats',
      'Alignments',
      '|',
      'OrderedList',
      'UnorderedList',
      '|',
      '|',
      'CreateLink',
      'Image',
    ],
  };
  public formatoFecha: string = 'dd/MM/yyyy';
  public imageSettings: object = { saveFormat: 'Base64' };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private gc_calidad_Service: GccalidadService,
    public datepipe: DatePipe,
    private fb: UntypedFormBuilder,
    private http: HttpClient
  ) {
    this.gc_calidad_Service.clasifica_formulario().subscribe((resp) => {
      this.data_clasifica_formulario = resp.respuesta;
    });
    this.gc_calidad_Service.lstReportes().subscribe((resp) => {
      this.data_reportes = resp.respuesta;
    });
    this.gc_calidad_Service.formulario().subscribe((resp) => {
      this.data_formulario = resp.respuesta;
    });
    this.gc_calidad_Service.lstTipoParam().subscribe((resp) => {
      this.data_tipoParametro = resp.respuesta;
    });
    this.AgregarOpciones = this.fb.group({
      id_opciones: [-1],
      descripcion: ['', Validators.required],
      valor: ['', Validators.required],
      orden: ['', Validators.required],
    });
    this.Nuevoformulario = this.fb.group({
      id: [-1],
      id_rpt_encabezado: [-1],
      id_frm_encabezado_clasificacion: [-1, Validators.required],
      descripcion: ['', Validators.required],
      folio: ['', Validators.required],
      estilo: [''],
      mensaje: [''],
      funcion: [''],
    });
    this.cargarFrm();

  }

  ngOnInit(): void {
    this.route.data.subscribe(resp => {
      this.breadcrumbs1 = resp.rutas.respuesta.ruta;
      
    });
    this.frmComentario = this.fb.group(new MdlComentarios());
    this.AgregarOpciones = this.fb.group(new MdlOpcionesAgregar());
    this.NuevoParametro = this.fb.group(new MdlParametros());
    this.Nuevoformulario = this.fb.group(new MdlFormulario());
  }

  /**
   * Metodo para abrir dialogo de busqueda de formularios
   * @returns;
   **/
  public btnAbrirDlgBusqueda() {
    this.dlgBusquedaFormulario.show();
  }

  /**
   * Metodo del botón en el cual crea el formulario inicial.
   * @returns;
   **/
  btnNuevoFormulario() {
    this.id_frm_encabezadoValida = true;
    this.Nuevoformulario = this.fb.group({
      id: [-1],
      id_rpt_encabezado: [-1],
      id_frm_encabezado_clasificacion: [-1, Validators.required],
      descripcion: ['', Validators.required],
      folio: ['', Validators.required],
      estilo: [''],
      mensaje: [''],
      funcion: [''],
    });

    this.dlgNuevoFormulario.show();
  }

  /**
   * Metodo al momento de seleccionar una fila en la tabla toma los valores y los asigna
   * a variables.
   * @returns;
   **/
  public onRowSelected(args: any) {
    this.tfNombre.nativeElement.value = args.data.descripcion;
    this.id_frm_encabezado.nativeElement.value = args.data.id;
    this.NuevoParametro = this.fb.group({ id_frm_encabezado: [this.id_frm_encabezado.nativeElement.value], });
    this.dlgBusquedaFormulario.hide();
    this.referenciaformulario.nativeElement.value = '';
    this.gc_calidad_Service
      .buscarFormulario(this.referenciaformulario.nativeElement.value)
      .subscribe((resp) => {
        this.columnsformulario = JSON.parse(
          JSON.stringify(resp)
        ).respuesta.columns;
        this.dataformularios = JSON.parse(
          JSON.stringify(resp)
        ).respuesta.dataSource;
      });
    this.gc_calidad_Service.getFomularios(args.data.id).subscribe((resp) => {
      let formulario: MdlFormulario = resp.respuesta;
      this.Nuevoformulario = this.fb.group(formulario);
    });
  }

  /**
   * Metodo para buscar los formularios con base en la referencia de "referenciaformulario".
   * @returns;
   *
   **/
  public buscar() {
    this.gc_calidad_Service
      .buscarFormulario(this.referenciaformulario.nativeElement.value)
      .subscribe((resp) => {
        this.columnsformulario = JSON.parse(
          JSON.stringify(resp)
        ).respuesta.columns;
        this.dataformularios = JSON.parse(
          JSON.stringify(resp)
        ).respuesta.dataSource;
      });
    this.dlgBusquedaFormulario.show();
  }

  /**
   * Metodo para cargar el listado de los formularios
   * @returns;
   *
   **/
  public cargarFrm() {
    this.gc_calidad_Service.listadoFrm().subscribe((resp) => {
      this.dataFrmListado = JSON.parse(JSON.stringify(resp)).respuesta;
    });
  }

  /**
   * Metodo para cargar el formulario a partir del Numero o identificador del
   * formulario selecionado
   */
  public btnEdiutarFrm(id: number): void {
    this.gc_calidad_Service.getFrm(id).subscribe((resp) => {
      let formulario: MdlFormulario = resp.respuesta;
      this.Nuevoformulario = this.fb.group(formulario);
      this.tfNombre.nativeElement.value = resp.respuesta.descripcion;
    });
    this.id_frm_encabezadoValida = false;
    this.dlgNuevoFormulario.show();
  }

  public btnEliminarFrm(id) {
    this.id_frm = id;
    this.UtilComponent = DialogUtility.confirm({
      title:
        'Información Arcarius' +
        '[Alerta]' +
        ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
      content: '<h6><b>¿Estas seguro de eliminar este registro?</b></h6>',
      okButton: { text: 'Si', click: this.btnEliminarFrm2.bind(this) },
      cancelButton: { text: 'No' },
      showCloseIcon: true,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
    });
  }

  public btnEliminarFrm2: EmitType<object> = () => {
    this.http
      .post(
        `${environment.base_url}/gccalidad/formulario/eliminarFormulario/${this.id_frm}`,
        {}
      )
      .subscribe((respuesta) => {
        if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
          DialogUtility.alert({
            title: 'Informacion Arcarius',
            content: JSON.parse(JSON.stringify(respuesta)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
          this.cargarFrm();
        } else {
          DialogUtility.alert({
            title: 'Informacion Arcarius [Problemas]',
            content: JSON.parse(JSON.stringify(respuesta)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
        }
        this.UtilComponent.hide();
        this.id_frm = -1;
      });
  };

  /**
   * Metodo para abrir dialogo de las opciones para parametros de los formularios
   * con cierta validación en que se debe seleccionar un formulario
   * para poder asignar los parametros
   * De igual forma se crea el formulario NuevoParametro
   *
   * @returns
   **/
  btnAbrirFrmOpciones() {
    if (this.id_frm_encabezado.nativeElement.value <= 0) {
      DialogUtility.alert({
        title: '<span class="dialogo-titulo-error">PROBLEMA DE SELECCIÓN <i style="color: #fff" class="fas fa-ban"></i></span>',
        content: 'Seleccione un formulario',
        showCloseIcon: true,
        closeOnEscape: true,
        animationSettings: { effect: 'Zoom' },
      });
    } else {
      this.gc_calidad_Service.lstOpcionesFormulario(this.id_frm_encabezado.nativeElement.value).subscribe((resp) => {
        this.dataopcionesformularios = JSON.parse(
          JSON.stringify(resp)
        ).respuesta;
      });
      this.NuevoParametro = this.fb.group({
        id_frm_encabezado: [Number(this.id_frm_encabezado.nativeElement.value)],
        id_frm_campo_tipo: [this.id_frm_campo_tipo.value],
        descripcion: [''],
        orden: ['', Validators.required],
        tabla: [''],
        columna: [''],
        condicion: [''],
        renglones: [1],
        observaciones: [''],
        pordefecto: [''],
        formula_js: [''],
        obligatorio: [false]

      });
      this.dlgParametrosOpciones.show();
    }
  }
  /**
   * Metodo para la seleccion de una fila y abra un nuevo dialogo donde se puede
   * se pueda asignar opciones con base a ese parametro que se selecciono.
   *
   * @returns
   **/
  public id_dataOpcionesAsignadas: number = -1;
  public btnAbrirOpc(id: any) {
    this.id_frm_detalles = id;
    this.dlgOpcionesSeleccion.show();
    this.gc_calidad_Service.lstOpcionesDisponibles(id).subscribe((resp) => {
      this.dataOpcionesDisponibles = resp.respuesta;
    });
    this.gc_calidad_Service.lstOpcionesAsignadas(id).subscribe((resp) => {
      this.dataOpcionesAsignadas = resp.respuesta;
    });
    this.id_parametro_select = id;
  }


  public btnIdentificador(area: number) {
    this.id_selected_opc = area;
    this.datosDisponibles = Array(this.lstOpcionDisponibles.value);
    this.datosAsignados = Array(this.lstOpcionAsignadas.value);
    if (this.id_selected_opc == 1) {
      this.valorAsignado = '';
    } else if (this.id_selected_opc == 2) {
      this.valorDisponible = '';
    }
    this.valorDisponible = this.datosDisponibles.toString();
    this.valorAsignado = this.datosAsignados.toString();
  }


  public btnAgregarOpciones(): void {
    if (this.valorDisponible == '' || this.valorDisponible == null || this.valorDisponible == undefined) {
      DialogUtility.alert({
        title: '<span class="dialogo-titulo-error">' + 'Documentos' + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
        content: 'No has seleccionado documento que agregar ',
        showCloseIcon: true,
        closeOnEscape: true,
        animationSettings: { effect: 'Zoom' }
      });
      this.valorDisponible = '';
      this.lstOpcionDisponibles.value = [];
      return;
    } else {
      this.blockUI.start('Procesando...');
      const body = {
        id: this.id_frm_deta_opc,
        id_frm_detalle: this.id_frm_detalles,
        id_frm_opciones: (this.valorDisponible)
      };
      this.http
        .post(
          `${environment.base_url}/gccalidad/formulario/insertar_opciones2`,
          body
        )
        .subscribe((respuesta) => {
          if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
            DialogUtility.alert({
              title: '<span class="dialogo-titulo-error">' + 'Documentos' + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
              content: JSON.parse(JSON.stringify(respuesta)).mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' }
            });
            this.blockUI.stop();
            this.valorDisponible = '';
            this.lstOpcionDisponibles.value = [];
          } else {
            this.gc_calidad_Service.lstOpcionesDisponibles(this.id_frm_detalles).subscribe((resp) => {
              this.dataOpcionesDisponibles = resp.respuesta;
            });
            this.gc_calidad_Service.lstOpcionesAsignadas(this.id_parametro_select).subscribe((resp) => {
              this.dataOpcionesAsignadas = resp.respuesta;
            });
            this.blockUI.stop();
            this.valorDisponible = '';
            this.lstOpcionDisponibles.value = [];
          }
        });
    }
  }

  public btnQuitarOpciones(): void {
    if (this.valorAsignado == '' || this.valorAsignado == null || this.valorAsignado == undefined) {
      DialogUtility.alert({
        title: '<span class="dialogo-titulo-error">' + 'Documentos' + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
        content: 'No has seleccionado un documento que quitar',
        showCloseIcon: true,
        closeOnEscape: true,
        animationSettings: { effect: 'Zoom' }
      });
      this.valorAsignado = '';
      this.lstOpcionAsignadas.value = [];
      return;
    } else {
      this.blockUI.start('Procesando...');
      const body = {
        id: (this.valorAsignado)
      };
      this.http
        .post(
          `${environment.base_url}/gccalidad/formulario/borrar_documento2`,
          body
        )
        .subscribe((respuesta) => {
          if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
            DialogUtility.alert({
              title: '<span class="dialogo-titulo-error">' + 'Documentos' + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
              content: JSON.parse(JSON.stringify(respuesta)).mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' }
            });
            this.blockUI.stop();
            this.valorAsignado = '';
            this.lstOpcionAsignadas.value = [];
          } else {
            this.blockUI.stop();
            this.gc_calidad_Service.lstOpcionesDisponibles(this.id_frm_detalles).subscribe((resp) => {
              this.dataOpcionesDisponibles = resp.respuesta;
            });
            this.gc_calidad_Service.lstOpcionesAsignadas(this.id_parametro_select).subscribe((resp) => {
              this.dataOpcionesAsignadas = resp.respuesta;
            });
            this.valorAsignado = '';
            this.lstOpcionAsignadas.value = [];
          }
        });
    }
  }


  btnOpcionesAgregar() {
    this.AgregarOpciones = this.fb.group({
      id_opciones: [-1],
      descripcion: ['', Validators.required],
      valor: ['', Validators.required],
      orden: ['', Validators.required],
    });
    this.dlgOpcionesAgregar.show();
  }
  /**
   * Metodo para cargar la opción a partir del Numero o identificador de la
   * opción selecionada
   */
  public btnCargaAgregarOpciones(id: number): void {
    this.gc_calidad_Service.getOpcionesAdd(id).subscribe((resp) => {
      let opciones: MdlOpcionesAgregar = resp.respuesta;
      this.AgregarOpciones = this.fb.group(opciones);
    });
  }

  /**
   * Metodo para cargar el parametro a partir del Numero o identificador de la
   * opción selecionada
   */
  public btnEditarParametro(id: number): void {
    this.blockUI.start('Cargando informacion...');
    this.gc_calidad_Service.getParametros(id).subscribe((resp) => {
      this.dataCondicionados = resp.respuesta.condicionados;
      setTimeout(() => {
        this.NuevoParametro = this.fb.group(resp.respuesta as MdlParametros);
        this.blockUI.stop();
      }, 1000);
    });
  }

  public btnBorrarOpciones(id) {
    this.id_frm_opciones = id;
    this.UtilComponent = DialogUtility.confirm({
      title: 'Información Arcarius[Alerta] <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
      content: '<h6><b>¿Estas seguro de eliminar este concepto?</b></h6>',
      okButton: { text: 'Si', click: this.btnBorrarOpciones2.bind(this) },
      cancelButton: { text: 'No' },
      showCloseIcon: true,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
    });
  }

  public btnBorrarOpciones2: EmitType<object> = () => {
    this.http
      .post(
        `${environment.base_url}/gccalidad/formulario/eliminarOpciones/${this.id_frm_opciones}`,
        {}
      )
      .subscribe((respuesta) => {
        this.gc_calidad_Service.lstOpcionesDisponibles(this.id_frm_detalles).subscribe((resp) => {
          this.dataOpcionesDisponibles = resp.respuesta;
        });
        if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
          DialogUtility.alert({
            title: 'Informacion Arcarius',
            content: JSON.parse(JSON.stringify(respuesta)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
        } else {
          DialogUtility.alert({
            title: 'Informacion Arcarius [Problemas]',
            content: JSON.parse(JSON.stringify(respuesta)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
        }
        this.UtilComponent.hide();
        this.id_frm_opciones = -1;
      });
  };

  public btnEliminarParametro(id) {
    this.id_frm_detalles = id;
    this.UtilComponent = DialogUtility.confirm({
      title:
        'Información Arcarius' +
        '[Alerta]' +
        ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
      content: '<h6><b>¿Estas seguro de eliminar este concepto?</b></h6>',
      okButton: { text: 'Si', click: this.btnBorrarParametros2.bind(this) },
      cancelButton: { text: 'No' },
      showCloseIcon: true,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
    });
  }

  public btnBorrarParametros2: EmitType<object> = () => {
    this.http.post(`${environment.base_url}/gccalidad/formulario/eliminarParametro/${this.id_frm_detalles}`, {})
      .subscribe((respuesta) => {
        if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
          DialogUtility.alert({
            title: 'Informacion Arcarius',
            content: JSON.parse(JSON.stringify(respuesta)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
          this.gc_calidad_Service
            .lstOpcionesFormulario(this.id_frm_encabezado.nativeElement.value)
            .subscribe((resp) => {
              this.dataopcionesformularios = JSON.parse(
                JSON.stringify(resp)
              ).respuesta;
            });
          this.NuevoParametro = this.fb.group({
            id_frm_encabezado: [
              Number(this.id_frm_encabezado.nativeElement.value),
            ],
            id_frm_campo_tipo: [this.id_frm_campo_tipo.value],
            descripcion: ['', Validators.required],
            orden: ['', Validators.required],
            tabla: [''],
            columna: [''],
            condicion: [''],
            renglones: [1],
            observaciones: [''],
            pordefecto: [''],
            formula_js: [''],
          });
        } else {
          DialogUtility.alert({
            title: 'Informacion Arcarius [Problemas]',
            content: JSON.parse(JSON.stringify(respuesta)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
        }
        this.UtilComponent.hide();
        this.id_frm_detalles = -1;
      });
  };



  /**
 * Metodos para las validaciones de Nuevoformulario
 */
  get descripcionParamNoValida() {
    return (
      this.NuevoParametro.get('descripcion').invalid &&
      this.NuevoParametro.get('descripcion').touched
    );
  }
  get ordenParamNoValida() {
    return (
      this.NuevoParametro.get('orden').invalid &&
      this.NuevoParametro.get('orden').touched
    );
  }

  public revisaAgregarParametros() {
    if (this.NuevoParametro.invalid) {
      return Object.values(this.NuevoParametro.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }

  /**
   * Metodo para limpiar el formulario y poder ingresar datos para ser insertados como
   * un nuevo registro
   */
  public btnParametrosNuevo() {
    this.NuevoParametro = this.fb.group({
      id_frm_encabezado: [Number(this.id_frm_encabezado.nativeElement.value),],
      id_frm_campo_tipo: [this.id_frm_campo_tipo.value],
      descripcion: ['', Validators.required],
      orden: ['1', Validators.required],
      tabla: [''],
      columna: [''],
      condicion: [''],
      renglones: [1],
      observaciones: [''],
      pordefecto: [''],
      formula_js: [''],
      obligatorio: [true],
      id_frm_opciones: [-1]
    });
  }

  /**
   * Metodo para guardar el parametro al cual se le va a asignar el formulario
   */
  public btnParametrosGuardar(): void {
    this.revisaAgregarParametros();
    if (this.NuevoParametro.get('id_frm_campo_tipo').value <= 0) {
      DialogUtility.alert({
        title: '<span class="dialogo-titulo-error">Formulario Parametro<i style="color: #fff" class="fas fa-ban"></i></span>',
        content: 'No has seleccionado un tipo',
        showCloseIcon: true,
        closeOnEscape: true,
        animationSettings: { effect: 'Zoom' },
      });
      return;
    }
    if (!this.NuevoParametro.invalid) {
      this.blockUI.start('Procesando...');
      this.NuevoParametro.controls['renglones'].setValue(Math.trunc(this.NuevoParametro.controls['renglones'].value));
      this.gc_calidad_Service.guardarParametros(this.NuevoParametro.value).subscribe((resp) => {
        let respuesta = JSON.parse(JSON.stringify(resp));
        if (respuesta.numero < 0) {
          DialogUtility.alert({
            title: '<span class="dialogo-titulo-error">' + respuesta.titulo + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
            content: JSON.parse(JSON.stringify(resp)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
          this.blockUI.stop();
        } else {
          DialogUtility.alert({
            title: '<span style="color: #28a745">' + respuesta.titulo + ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
            content: JSON.parse(JSON.stringify(resp)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
          this.gc_calidad_Service.lstOpcionesFormulario(this.id_frm_encabezado.nativeElement.value).subscribe((resp) => {
            this.dataopcionesformularios = JSON.parse(
              JSON.stringify(resp)
            ).respuesta;
          });
          this.NuevoParametro = this.fb.group({
            id_frm_encabezado: [Number(this.id_frm_encabezado.nativeElement.value),],
            id_frm_campo_tipo: [this.id_frm_campo_tipo.value],
            descripcion: ['', Validators.required],
            orden: ['', Validators.required],
            tabla: [''],
            columna: [''],
            condicion: [''],
            renglones: [1],
            observaciones: [''],
            pordefecto: [''],
            formula_js: [''],
            obligatorio: [false]
          });
          this.blockUI.stop();
        }
      });
    }
  }

  /**
   * Metodos para las validaciones de Nuevoformulario
   */
  get descripcionOpcNoValida() {
    return (
      this.AgregarOpciones.get('descripcion').invalid &&
      this.AgregarOpciones.get('descripcion').touched
    );
  }
  get valorOpcNoValida() {
    return (
      this.AgregarOpciones.get('valor').invalid &&
      this.AgregarOpciones.get('valor').touched
    );
  }
  get ordenOpcNoValida() {
    return (
      this.AgregarOpciones.get('orden').invalid &&
      this.AgregarOpciones.get('orden').touched
    );
  }

  public revisaAgregarOpciones() {
    if (this.AgregarOpciones.invalid) {
      return Object.values(this.AgregarOpciones.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }

  /**
   * Metodo para guardar la opción al que se le va a asignar al parametro
   */
  public btnGuardarOpciones(): void {
    this.revisaAgregarOpciones();
    if (!this.AgregarOpciones.invalid) {
      this.blockUI.start('Procesando...');
      this.gc_calidad_Service
        .guardarOpciones(this.AgregarOpciones.value)
        .subscribe((resp) => {
          let respuesta = JSON.parse(JSON.stringify(resp));
          if (respuesta.numero < 0) {
            DialogUtility.alert({
              title:
                '<span class="dialogo-titulo-error">' +
                respuesta.titulo +
                ' <i style="color: #fff" class="fas fa-ban"></i></span>',
              content: JSON.parse(JSON.stringify(resp)).mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' },
            });
            this.blockUI.stop();
          } else {
            DialogUtility.alert({
              title:
                '<span style="color: #28a745">' +
                respuesta.titulo +
                ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
              content: JSON.parse(JSON.stringify(resp)).mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' },
            });
            this.gc_calidad_Service.lstOpcionesDisponibles(this.id_frm_detalles).subscribe((resp) => {
              this.dataOpcionesDisponibles = resp.respuesta;
            });

          }
          this.AgregarOpciones = this.fb.group({
            id_opciones: [-1],
            descripcion: ['', Validators.required],
            valor: ['', Validators.required],
            orden: ['', Validators.required],
          });
        });
      this.blockUI.stop();
    }
    this.blockUI.stop();
  }

  /**
   * Metodos para las validaciones de Nuevoformulario
   */
  get descripcionNoValida() {
    return (
      this.Nuevoformulario.get('descripcion').invalid &&
      this.Nuevoformulario.get('descripcion').touched
    );
  }
  get folioNoValida() {
    return (
      this.Nuevoformulario.get('folio').invalid &&
      this.Nuevoformulario.get('folio').touched
    );
  }

  public revisa() {
    if (this.Nuevoformulario.invalid) {
      return Object.values(this.Nuevoformulario.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }

  /**
   * Metodo para guardar la información del formulario
   */
  public btnGuardarFormulario(): void {
    this.revisa();
    if (this.Nuevoformulario.get('id_frm_encabezado_clasificacion').value <= 0) {
      DialogUtility.alert({
        title:
          '<span class="dialogo-titulo-error">' +
          'Formulario' +
          ' <i style="color: #fff" class="fas fa-ban"></i></span>',
        content: 'No has seleccionado una clasificación',
        showCloseIcon: true,
        closeOnEscape: true,
        animationSettings: { effect: 'Zoom' },
      });
      return;
    }
    if (!this.Nuevoformulario.invalid) {
      this.blockUI.start('Procesando...');
      this.gc_calidad_Service
        .guardarFormulario(this.Nuevoformulario.value)
        .subscribe((resp) => {
          let respuesta = JSON.parse(JSON.stringify(resp));
          if (respuesta.numero < 0) {
            DialogUtility.alert({
              title:
                '<span class="dialogo-titulo-error">' +
                respuesta.titulo +
                ' <i style="color: #fff" class="fas fa-ban"></i></span>',
              content: JSON.parse(JSON.stringify(resp)).mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' },
            });
            this.blockUI.stop();
          } else {
            DialogUtility.alert({
              title:
                '<span style="color: #28a745">' +
                respuesta.titulo +
                ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
              content: JSON.parse(JSON.stringify(resp)).mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' },
            });
            this.dlgNuevoFormulario.hide();
            this.cargarFrm();
            this.tfNombre.nativeElement.value = '';
            this.Nuevoformulario = this.fb.group({
              id: [-1],
              id_rpt_encabezado: [-1],
              descripcion: ['', Validators.required],
              id_frm_encabezado_clasificacion: [-1, Validators.required],
              folio: ['', Validators.required],
              estilo: [''],
              mensaje: [''],
              funcion: [''],
            });
            this.blockUI.stop();
          }
        });
    }
  }
}
