import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MdlUsuario } from 'src/app/models/MdlUsuario';
import { environment } from 'src/environments/environment';
import { GccalidadService } from '../services/gccalidad.service';

@Component({
  selector: 'app-descarga',
  templateUrl: './descarga.component.html',
  styleUrls: ['./descarga.component.scss']
})
export class DescargaComponent implements OnInit {
  public breadcrumbs1: string = '';
  public breadcrumbs2: string = '';

  // variables
  public data: any;
  public data_proyecto: any;
  public data_tipo: any;
  public id_proyecto: number = 0;
  public id_proyecto_documento: number = 0;
  public id_tipodoc: number = 0;
  @BlockUI() blockUI: NgBlockUI;
  public fields: Object = { text: 'descripcion', value: 'id' };
  public usuario: MdlUsuario;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    public domSanitizer: DomSanitizer,
    private Gc_calidadService: GccalidadService
  ) {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    // CARGAMOS EL LISTADO DE LAS DESCARGAS
    this.CargaListadoDescarga();
    this.Gc_calidadService.proyecto().subscribe((resp) => {
      this.data_proyecto = resp.respuesta;
      if (this.data_proyecto[0] == null || this.data_proyecto[0] == 0) {
        this.id_proyecto_documento = 0;
      } else {
        this.id_proyecto_documento = this.data_proyecto[0];
      }
    });
    this.Gc_calidadService.tipodoc().subscribe((resp) => {
      this.data_tipo = resp.respuesta;
      if (this.data_tipo[0] == null || this.data_tipo[0] == 0) {
        this.id_tipodoc = 0;
      } else {
        this.id_tipodoc = this.data_tipo[0];
      }
    });
  }

  ngOnInit(): void {
    this.route.data.subscribe(resp => {
      this.breadcrumbs1 = resp.rutas.respuesta.ruta;
      
    });
  }

  // carga de los listados de descargas del usuario
  public CargaListadoDescarga(): void {
    this.Gc_calidadService.lstDescarga(this.usuario.id, 0, 0)
      .subscribe((resp) => {
        this.data = JSON.parse(JSON.stringify(resp)).respuesta;
      });
  }

  /**
 * Metodo para la descarga del archivo desde la API
 * 
 * Se utiliza un elemento <a> para gestionar la url de descarga, así como el nombre del archivo
 * 
 * @id Numero o identificador del archivo
 * @nombre Nombre del archivo con el cual se descargara dicho archivo
 * @returns
 */
  public descarga(id: number, nombre: string) {
    this.Gc_calidadService.getDescarga(id).subscribe(resp => {
      const element = document.createElement('a');
      const blob = new Blob([resp], { type: 'application/octet-stream' });
      element.href = window.URL.createObjectURL(blob);
      element.download = nombre;
      element.click();
    });
  }


  cargaDocumentos(id: number, id2: number, id3: number) {
    this.http
      .get<any>(`${environment.base_url}/gccalidad/listadodescarga/${id}/${id2}/${id3}`)
      .subscribe((resp) => {
        this.data = resp.respuesta;
      });
  }

  onChangeProyecto(args): void {
    console.log(this.id_tipodoc);
    this.id_proyecto_documento = args.value;
    this.cargaDocumentos(this.usuario.id, this.id_proyecto_documento, this.id_tipodoc);
  }

  onChangeTipo(args): void {
    this.id_tipodoc = args.value;
    this.cargaDocumentos(this.usuario.id, this.id_proyecto_documento, this.id_tipodoc);
  }
}
