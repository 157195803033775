export class MdlParametros {
	public id: number = -1;
	public id_frm_encabezado: number = -1;
	public id_frm_campo_tipo: number = -1;
  public id_frm_detalle_opciones: number = -1;
	public descripcion: string = "";
	public orden: number = 0;
	public tabla: string = "";
	public columna: string = "";
	public renglones: number = 1;
	public condicion: string = "";
	public observaciones: string = "";
  public pordefecto: string = "";
  public formula_js: string = "";
  public obligatorio: boolean = false;
}
