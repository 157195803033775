import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoginForm } from '../interfaces/login-form.interface';
import { MdlUsuario } from '../models/MdlUsuario';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from '../components/dlgMensajeError/dlgMensajeError.component';
import { ErroresService } from '../shared/errores.service';

@Injectable({
    providedIn: 'root',
})
export class UsuarioService {
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    /**
     * Metodo para confirmar que el usuario este autentificado o activo
     */
    get isLoggedIn() {
        if (localStorage.getItem('usuario')) {
            this.loggedIn.next(true);
        }
        return this.loggedIn.asObservable();
    }

    constructor(
        private http: HttpClient,
        private errores: ErroresService,
        private router: Router,
        private modalService: NgbModal
    ) { }

    login(formData: LoginForm) {
        this.http.post(`${environment.base_url}/auth/acceso`, formData).pipe(
            catchError((error) => {
                return throwError(this.errores.getErrores(error));
            })
        ).subscribe((resp) => {
            let respuesta = JSON.parse(JSON.stringify(resp));
            if (respuesta.numero < 0) {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = respuesta.titulo;
                modalRef.componentInstance.contenido = respuesta.mensaje;
            } else {
                localStorage.setItem('token', respuesta.Token);
                const usuario: MdlUsuario = new MdlUsuario(
                    respuesta.User,
                    respuesta.Nombre,
                    respuesta.Paterno,
                    respuesta.Materno,
                    respuesta.Puesto,
                    respuesta.Departamento,
                    respuesta.Empresa,
                    respuesta.Perfil,
                    respuesta.Id,
                    respuesta.Id_rpt_tablero_detalle
                );
                //=======================================================================================
                // Almacenamos la información del usuario
                //=======================================================================================
                localStorage.setItem('usuario', JSON.stringify(usuario));
                //=======================================================================================
                // Navegar al Dashboard
                this.loggedIn.next(true);
                this.router.navigateByUrl(`/tablero/tablero2`);
            }
        },
            (err) => {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = 'Arcarius ERP';
                modalRef.componentInstance.contenido = err;
            }
        );
    }

    /**
     * Metodo para cerrar sesion del usuario y limpiar sus datos del navegador
     */
    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('usuario');
        localStorage.removeItem('menu');
        localStorage.removeItem('id_menu');
        localStorage.removeItem('id_menu_sub');
        this.loggedIn.next(false);
        this.router.navigateByUrl('/login');
    }

    validarToken(): Observable<boolean> {
        const token = localStorage.getItem('token') || '';
        return this.http.get(`${environment.base_url}/auth/renovar`, {
            headers: {
                token: token,
            },
        }).pipe(
            tap((resp: any) => {
                localStorage.setItem('token', resp.token);
            }),
            map((resp) => true),
            catchError((error) => of(false))
        );
    }

    public getListado(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/usuario/configuraciones/listado`, {});
    }

    public getConfiguracion(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/usuario/configuraciones/configuracion/${id}`, {});
    }
}
