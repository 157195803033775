<div class="breadcrumb_navbar">
    <ul class="breadcrumb">
        <li><a>{{this.breadcrumbs1}}</a></li>
        
    </ul>
</div>
<block-ui></block-ui>

<section>
    <app-loader></app-loader>
    <div class="card">
        <div class="card-body">
            <div class="footer">
                <button class="btn btn-nuevo" (click)="btnNuevoFormulario()"> {{"Boton.Nuevo" | translate}}</button>
            </div>
        </div>
        <div class="card-detail">


            <div class="table-responsive">
                <table *ngIf="this.dataFrmListado" class="table table-hover table-bordered">
                    <thead>
                        <th style="width: 80px;"></th>
                        <th>Descripcion</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of this.dataFrmListado">
                            <td>
                                <button class="btn btn-editar-sm" title="Modificar Formulario"
                                    (click)="btnEdiutarFrm(item.id)"></button>
                                <button class="btn btn-borrar-sm" title="Eliminar"
                                    (click)="btnEliminarFrm(item.id)"></button>
                            </td>
                            <td>
                                {{item.descripcion}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>


<!-- INICIO DIALOGO DEL NUEVO FORMULARIO-->
<ejs-dialog #dlgNuevoFormulario [isModal]='true' [visible]='false' showCloseIcon='true' width='400px'>
    <ng-template #header>
        Formularios
    </ng-template>
    <ng-template #content>
        <div class="dialogContent">
            <div class="container">
                <form [formGroup]="Nuevoformulario">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Descripción</label>
                                <input type="text" required #tfNombre class="form-control" aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm" formControlName="descripcion">
                                <small *ngIf="descripcionNoValida" class="text-danger">Se requiere una
                                    descripción</small>
                                <input type="text" #id_frm_encabezado class="form-control" aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm" formControlName="id" hidden="true">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Reporte</label>
                                <ejs-dropdownlist #id_rpt_encabezado [dataSource]='data_reportes' [fields]='fields'
                                    placeholder='Seleccionar reporte' formControlName="id_rpt_encabezado">
                                </ejs-dropdownlist>
                            </div>
                            <div class="form-group">
                                <label>Clasficación</label>
                                <ejs-dropdownlist #id_frm_encabezado_clasificacion
                                    [dataSource]='data_clasifica_formulario' [fields]='fields'
                                    placeholder='Seleccionar clasificación'
                                    formControlName="id_frm_encabezado_clasificacion">
                                </ejs-dropdownlist>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Folio</label>
                                        <ejs-numerictextbox required format="n0" decimals="0"
                                            validateDecimalOnType="true" min="0" [showSpinButton]='false'
                                            formControlName="folio"></ejs-numerictextbox>
                                        <small *ngIf="folioNoValida" class="text-danger">Se requiere un folio</small>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Estilo</label>
                                <div class="multiline-row">
                                    <ejs-textbox #default rows="2" floatLabelType="Auto" [multiline]="true"
                                        formControlName="estilo"></ejs-textbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Mensaje</label>
                                <div class="multiline-row">
                                    <ejs-textbox #default rows="2" floatLabelType="Auto" [multiline]="true"
                                        formControlName="mensaje"></ejs-textbox>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Función</label>
                                <div class="multiline-row">
                                    <ejs-textbox #default rows="2" floatLabelType="Auto" [multiline]="true"
                                        formControlName="funcion"></ejs-textbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <button class="btn btn-guardar" (click)="btnGuardarFormulario()"> {{"Boton.Guardar" | translate}}</button>
        <button *ngIf="!id_frm_encabezadoValida" class="btn btn-outline-secondary" (click)="btnAbrirFrmOpciones()">
            <i class="fas fa-clipboard-list"></i>
            Opciones
        </button>
    </ng-template>
</ejs-dialog>
<!-- FINAL DIALOGO DEL NUEVO FORMULARIO-->

<!-- INICIO DIALOGO DE BUSQUEDA DE FORMULARIOS -->
<ejs-dialog id="dlgBusquedaFormulario" #dlgBusquedaFormulario header='Busca Formularios' height='50%' width='30%'
    isModal='true' [visible]='false' showCloseIcon='true'>
    <ng-template #content>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <div class="input-group input-group-sm mb-4">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-sizing-sm">Descripción</span>
                        </div>
                        <input type="text" #referenciaformulario class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                        <div class="input-group-append">
                            <button class="btn btn-outline-success" type="button" (click)="buscar()">
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <ejs-grid #tabla [dataSource]='dataformularios' (rowSelected)="onRowSelected($event)">
                        <e-columns>
                            <e-column field='id' headerText='ID' width='0'>
                            </e-column>
                            <e-column field='descripcion' headerText='Descripcion' width='50px'>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>
    </ng-template>
</ejs-dialog>
<!-- INICIO DIALOGO DE BUSQUEDA DE FORMULARIOS -->

<!-- INICIO DIALOGO DE OPCIONES PARAMETROS -->
<ejs-dialog id="dlgParametrosOpciones" #dlgParametrosOpciones header='Definicion del Formulario' width='500'
    isModal='false' [visible]='false' showCloseIcon='true'>
    <ng-template #content>
        <form autocomplete="off" [formGroup]="NuevoParametro">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <input type="text" class="form-control " id="exampleFormControlInput1"
                            formControlName="id_frm_encabezado" hidden="true">
                        <div class="col-md-8">
                            <div class="form-group">
                                <label for="exampleFormControlInput1">Descripción</label>
                                <input type="text" required class="form-control " id="exampleFormControlInput1"
                                    formControlName="descripcion">
                                <small *ngIf="descripcionParamNoValida" class="text-danger">Se requiere una
                                    descripción</small>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Tipo</label>
                                <ejs-dropdownlist #id_frm_campo_tipo [dataSource]='data_tipoParametro' [fields]='fields'
                                    formControlName="id_frm_campo_tipo">
                                </ejs-dropdownlist>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="exampleFormControlInput1">Columna</label>
                                <input type="text" class="form-control" id="exampleFormControlInput1"
                                    formControlName="columna">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="exampleFormControlInput1">Tabla</label>
                                <input type="text" class="form-control" id="exampleFormControlInput1"
                                    formControlName="tabla">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="exampleFormControlInput1">Condiciones</label>
                                <input type="text" class="form-control" id="exampleFormControlInput1"
                                    formControlName="condicion">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Orden</label>
                                <input type="number" class="form-control" formControlName="orden">
                                <small *ngIf="ordenParamNoValida" class="text-danger">Se requiere un orden</small>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="exampleFormControlInput1">Renglones</label>
                                <input type="text" class="form-control" id="exampleFormControlInput1"
                                    formControlName="renglones">
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="form-group">
                                <label for="frmdefault">Valor por defecto</label>
                                <input type="text" class="form-control" id="frmdefault" formControlName="pordefecto">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-check form-switch">
                                <input type="checkbox" id="tfObligatorio" class='form-check-input' formControlName="obligatorio" />
                                <label class="form-check-label" for="tfObligatorio">Obligatorio</label>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <label>Componente Condicionado:</label>
                            <select class="form-select" formControlName="id_frm_detalle_opciones">
                                <option *ngFor="let item of dataCondicionados" value="{{item.id}}">{{item.descripcion}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Formula Java Script</label>
                        <div class="multiline-row">
                            <ejs-textbox rows="4" floatLabelType="Auto" [multiline]="true"
                                formControlName="formula_js"></ejs-textbox>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Observaciones</label>
                        <div class="multiline-row">
                            <ejs-textbox rows="2" floatLabelType="Auto" [multiline]="true"
                                formControlName="observaciones"></ejs-textbox>
                        </div>
                    </div>
                    <div class="botonera">
                        <button class="btn btn-nuevo" (click)="btnParametrosNuevo()"> {{"Boton.Nuevo" | translate}}</button>
                        <button class="btn btn-guardar" (click)="btnParametrosGuardar()"> {{"Boton.Guardar" | translate}}</button>
                    </div>
                    <div class="form-group">
                        <ejs-grid #tabla [dataSource]='dataopcionesformularios' width='100%' height="200">
                            <e-columns>
                                <e-column field='id' headerText='ID' width='0'>
                                </e-column>
                                <e-column headerText='' width='120'>
                                    <ng-template #template let-item>
                                        <div style="display: inline-flex;">
                                            <button class="btn btn-outline-primary" title="Abrir Opciones"
                                                (click)="btnAbrirOpc(item.id)">
                                                <i class="fas fa-external-link-alt"></i>
                                            </button>
                                            <button class="btn btn-editar-sm" title="Modificar"
                                                (click)="btnEditarParametro(item.id)"></button>
                                            <button class="btn btn-borrar-sm" title="Eliminar"
                                                (click)="btnEliminarParametro(item.id)"></button>
                                        </div>
                                    </ng-template>
                                </e-column>
                                <e-column field='descripcion' headerText='Descripcion' width='150'>
                                </e-column>
                                <e-column field='orden' headerText='Orden' width='60'>
                                </e-column>
                                <e-column field='renglones' headerText='Renglones' width='60'>
                                </e-column>
                                <e-column field='tabla' headerText='Tabla' width='150'>
                                </e-column>
                                <e-column field='columna' headerText='Columna' width='150'>
                                </e-column>
                                <e-column field='condicion' headerText='Condición' width='150'>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
</ejs-dialog>
<!-- INICIO DIALOGO DE OPCIONES PARAMETROS -->

<!-- INICIO DIALOGO DETALLES DE OPCIONES -->
<ejs-dialog id="dlgOpcionesSeleccion" #dlgOpcionesSeleccion header='Detalles Opciones' height='50%' width='50%'
    isModal='true' [visible]='false' showCloseIcon='true'>
    <block-ui>
        <ng-template #content>
            <form autocomplete="off" [formGroup]="">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <div class="dual-list-groupa">
                                        <h5>Opciones Disponibles</h5>
                                        <ejs-listbox #lstOpcionDisponibles [dataSource]='dataOpcionesDisponibles'
                                            height='150px' [fields]='fields' (click)="btnIdentificador(1)">
                                        </ejs-listbox>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <div class="dual-list-groupb">
                                        <h5>Opciones Asignadas</h5>
                                        <ejs-listbox #lstOpcionAsignadas [dataSource]='dataOpcionesAsignadas'
                                            height='150px' [fields]='fields' (click)="btnIdentificador(2)">
                                        </ejs-listbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn btn-outline-secondary mb-2 mr-2" (click)="btnAgregarOpciones()"><i
                        class="fas fa-chevron-right"></i> {{"Boton.Agregar" | translate}}</button>
                <button class="btn btn-outline-secondary mb-2 mr-2" (click)="btnQuitarOpciones()"><i
                        class="fas fa-chevron-left"></i>Quitar</button>
                <button class="btn btn-nuevo" (click)="btnOpcionesAgregar()">Agregar
                    Opción</button>
            </form>
        </ng-template>
    </block-ui>
</ejs-dialog>
<!-- FINAL DIALOGO DETALLES DE OPCIONES -->

<!-- INICIO DIALOGO AGREGAR OPCIONES -->
<ejs-dialog id="dlgOpcionesAgregar" #dlgOpcionesAgregar header='Agregar Opciones para Formularios' height='50%'
    width='50%' isModal='true' [visible]='false' showCloseIcon='true'>
    <ng-template #content>
        <form autocomplete="off" [formGroup]="AgregarOpciones">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="exampleFormControlInput1">Descripción</label>
                        <input type="text" required class="form-control " id="exampleFormControlInput1"
                            formControlName="descripcion">
                        <small *ngIf="descripcionOpcNoValida" class="text-danger">Se requiere una
                            descripción</small>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Valor</label>
                                <input type="number" class="form-control" formControlName="valor">
                                <small *ngIf="valorOpcNoValida" class="text-danger">Se requiere un valor</small>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Orden</label>
                                <input type="number" class="form-control" formControlName="orden">
                                <small *ngIf="ordenOpcNoValida" class="text-danger">Se requiere un orden</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-guardar" (click)="btnGuardarOpciones()"> {{"Boton.Guardar" | translate}}</button>
                    </div>
                    <ejs-grid [dataSource]='dataOpcionesDisponibles' width='auto'>
                        <e-columns>
                            <e-column field='id' headerText='Id' width='0'>
                            </e-column>
                            <e-column headerText='' width='100'>
                                <ng-template #template let-item>
                                    <div style="display: inline-flex;">
                                        <button class="btn btn-editar-sm" title="Modificar Opción"
                                            (click)="btnCargaAgregarOpciones(item.id)"></button>
                                        <button class="btn btn-borrar-sm" title="Eliminar Opción"
                                            (click)="btnBorrarOpciones(item.id)"></button>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='descripcion' headerText='Descripcion' width='120'>
                            </e-column>
                            <e-column field='valor' headerText='Valor' width='100'>
                            </e-column>
                            <e-column field='orden' headerText='Orden' width='100'>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </form>
    </ng-template>
</ejs-dialog>
<!-- FINAL DIALOGO AGREGAR OPCIONES -->
