<!-- Listado de Mensajes de Organizacion-->
<button ejs-button (click)="carga()">Button</button>
<ejs-listview id='List' [dataSource]='data' cssClass='e-list-template'>
    <ng-template #template let-data="">
        <div class="e-list-wrapper e-list-multi-line e-list-avatar">
            <span class="e-avatar e-avatar-circle" *ngIf="data.avatar !== ''">{{data.avatar}}</span>
            <span class="e-list-item-header">{{data.descripcion}}</span>
            <span class="e-list-content">{{data.origen}}</span>
        </div>
    </ng-template>
</ejs-listview>