<!--Dialogo par busqueda de empleados
    Esta ventana puede ser utilizada por el resto de los modulos para buscar y seleccionar
    a los empleados registrados
-->
<div class="modal-header">
	<h5 class="modal-title"> Buscar Empleado</h5>
	<button class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
	<div class="col-md-12">
		<div class='form-group'>
			<label>{{"Documento.Referencia" | translate}}</label>
			<div class="input-group">
				<input type="text" #referencia class="form-control" (keydown.enter)="buscar()">
				<div class="input-group-append">
					<button class="btn btn-buscar-cuadrado" (click)="buscar()"></button>
				</div>
			</div>
		</div>
	</div>

	<div class="table-responsive">
		<table *ngIf="this.data" class="table table-hover table-bordered">
			<thead>
				<th></th>
				<th *ngFor="let col of columns" width='{{col.width}}'>
					{{col.headerText}}
				</th>
			</thead>
			<tbody>
				<tr *ngFor="let item of this.data" (click)="getEmpleado(item)">
					<td>
						<button class="btn btn-aceptar" (click)="getEmpleado(item)"></button>
					</td>
					<td *ngFor="let col of columns" width='{{col.width}}'>
						{{item[col.field]}}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<div class="modal-footer">
	<button class="btn btn-cerrar" aria-label="Close" (click)="close()">
		Cerrar
	</button>
</div>