import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { Grid } from '@syncfusion/ej2-grids';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { BusquedagenericoService } from 'src/app/services/SIreporte/busquedagenerico.service';

@Component({
  selector: 'dlgBusqueda',
  templateUrl: './dlgBusqueda.component.html',
  styleUrls: ['./dlgBusqueda.component.scss']
})
export class DlgBusquedaComponent implements OnInit {
  @ViewChild('tabla') tabla: Grid;
  @ViewChild('dlgBusqueda') public dlgBusqueda: DialogComponent;
  @ViewChild('referencia') referencia: ElementRef;
  @Input('nombre') nombre: string;
  @Input('tablas') tablas: string;
  @Input('columnas') columnas: string;
  @Input('condicion') condicion: string;
  @Output('numero') respuesta = new EventEmitter<Respuesta>();
  //private respuesta: Respuesta;
  public _tabla: string = '';
  public _columna: string = '';
  public _condicion: string = '';
  public data: any;

  ClickedRow: any;
  // ===============================================================================================
  // Configuracion de botones para le ventana emergente
  // isPrimary: false | true = Configuramos el boton por default. Si damos Enter ejecuta este boton
  // ===============================================================================================
  public buttons: Object = [
    {
      click: this.cerrar.bind(this),
      buttonModel: {
        content: 'Cerrar',
        isPrimary: false
      },
    },
  ];

  @Input('show') show() {
    this.referencia.nativeElement.value = '';
    this._tabla = String(this.tablas).trim() == "null" ? '' : String(this.tablas).trim();
    this._columna = String(this.columnas).trim() == "null" ? '' : String(this.columnas).trim();
    this._condicion = String(this.condicion).trim() == "null" ? '' : String(this.condicion).trim();
    this.data = undefined;
    this.dlgBusqueda.show();
  };

  @Input('hide') hide() {
    this.dlgBusqueda.hide();
  };

  public dialogOpen = (): void => { }

  constructor(
    private BusquedagenericoService: BusquedagenericoService
  ) {
    this.ClickedRow = function (index) {
      this.HighlightRow = index;
      this.dlgBusqueda.hide();
      let resp: Respuesta;
      resp = {
        id: index,
        descripcion: this.descripcion
      };
      this.respuesta.emit(resp);
    }
  }

  ngOnInit(): void { }

  public cerrar(): void {
    this.dlgBusqueda.hide();
  }

  /**
   * Metodo para buscar los resultados con base en la referencia
   */
  public buscar() {
    this._tabla = String(this.tablas).trim() == "null" ? '' : String(this.tablas).trim();
    this._columna = String(this.columnas).trim() == "null" ? '' : String(this.columnas).trim();
    this._condicion = String(this.condicion).trim() == "null" ? '' : String(this.condicion).trim();
    this.BusquedagenericoService.listado(
      this._tabla,
      this._columna,
      this.referencia.nativeElement.value,
      this._condicion
    ).subscribe(resp => {
      this.data = JSON.parse(JSON.stringify(resp)).respuesta;
    });
  }

  /**
   * Se encarga de obtener el dato del componente y enviarlo al EventEmitter
   */
  public onRowSelected(args: any) {
    this.dlgBusqueda.hide();
    let resp: Respuesta;
    resp = {
      id: args.data.id,
      descripcion: args.data.descripcion
    };
    this.respuesta.emit(resp);
    this._tabla = '';
    this._columna = '';
  }
}

interface Respuesta {
  id: Number;
  descripcion: String;
}