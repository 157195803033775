<div class="breadcrumb_navbar">
  <ul class="breadcrumb">
    <li><a>{{this.breadcrumbs1}}</a></li>
    
  </ul>
</div>

<block-ui>
</block-ui>
<section>
    <app-loader></app-loader>
  <div class="card">
    <div class="card-body">
      <div class="footer">
        <button type="button" class="btn btn-nuevo" (click)="nuevo()"> {{"Boton.Nuevo" | translate}}</button>
      </div>
    </div>
  </div>


  <div class="card">
    <div class="card-detail">

      <div class="table-responsive">
        <table *ngIf="this.data" class="table table-hover table-bordered">
          <thead>
            <th style="min-width: 70px;"></th>
            <th style="min-width: 100px;">codigo</th>
            <th style="min-width: 150px;">alcance</th>
            <th style="min-width: 100px;">clase</th>
            <th style="min-width: 100px;">fecha_calibracion</th>
            <th style="min-width: 200px;">trazabilidad</th>
            <th style="min-width: 100px;">periodo_calibracion</th>
            <th style="min-width: 100px;">estatus_calibracion</th>
            <th style="min-width: 100px;">meteorometro</th>
            <th style="min-width: 100px;">valor_tabla</th>
            <th style="min-width: 200px;">texto_certificado</th>
            <th style="min-width: 100px;">vigencia</th>
            <th style="min-width: 100px;">temperatura</th>
            <th style="min-width: 100px;">humedad</th>
            <th style="min-width: 100px;">presion</th>
            <th style="min-width: 100px;">incertidumbre_t</th>
            <th style="min-width: 100px;">incertidumbre_hr</th>
            <th style="min-width: 100px;">incertidumbre_p</th>
          </thead>
          <tbody>
            <tr *ngFor="let item of this.data">
              <td>
                <button class="btn btn-editar-sm" title="Modificar" (click)="Editar(item)"></button>
                <button class="btn btn-borrar-sm" title="Eliminar" (click)="Eliminar(item.id)"></button>
              </td>
              <td>{{item.codigo}}</td>
              <td>{{item.alcance}}</td>
              <td>{{item.clase}}</td>
              <td>{{item.fecha_calibracion | date: 'dd/MM/yyyy'}}</td>
              <td>{{item.trazabilidad}}</td>
              <td>{{item.periodo_calibracion}}</td>
              <td>{{item.estatus_calibracion}}</td>
              <td>{{item.meteorometro}}</td>
              <td>{{item.valor_tabla}}</td>
              <td>{{item.texto_certificado}}</td>
              <td>{{item.vigencia | date: 'dd/MM/yyyy'}}</td>
              <td>{{item.temperatura}}</td>
              <td>{{item.humedad}}</td>
              <td>{{item.presion}}</td>
              <td>{{item.incertidumbre_t}}</td>
              <td>{{item.incertidumbre_hr}}</td>
              <td>{{item.incertidumbre_p}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
