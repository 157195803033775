<div class="limiter">
    <div class="container-login">
        <div class="wrap-login">
            <button class="button" data-text="Awesome" style="width: 100%;">
                <span class="actual-text">&nbsp;Arcarius ERP&nbsp;</span>
                <span aria-hidden="true" class="hover-text">&nbsp;Arcarius ERP&nbsp;</span>
            </button>

            <div class="login-form-title" style="background-image: url(assets/images/entrada.png);">
            </div>
            <form class="login-form" autocomplete="off" [formGroup]="loginForm" (ngSubmit)="login()">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <i class="fa-solid fa-landmark-flag login-icono"></i>
                            <label for="tfEmpresa">{{"Empresa" | translate}}</label>
                            <input type="text" class="form-control" id="tfEmpresa" aria-describedby="emailHelp"
                                formControlName="database">
                            <small id="emailHelp" class="form-text text-muted">Capture your company name</small>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <i class="fas fa-user login-icono"></i>
                            <label for="tfUsuario">{{"Login.usuario" | translate}}</label>
                            <input type="text" class="form-control" id="tfUsuario" aria-describedby="emailHelp"
                                formControlName="user">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <i class="fas fa-key login-icono"></i>
                            <label for="tfPassword">{{"Login.password" | translate}}</label>
                            <input type="password" class="form-control" id="tfPassword" formControlName="password">
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button type="submit" class="btn btn-primary">
                            <i class="fa-solid fa-user-check"></i>
                            {{"Boton.Comenzar" | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>