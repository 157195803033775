import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-controlcalidad',
  templateUrl: './controlcalidad.component.html',
  styleUrls: ['./controlcalidad.component.scss']
})
export class ControlcalidadComponent implements OnInit {
  public breadcrumbs1: string = '';
  public breadcrumbs2: string = '';

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(resp => {
      this.breadcrumbs1 = resp.rutas.respuesta.ruta;
      
    });
  }

}
