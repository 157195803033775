import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ListadoResolver implements Resolve<boolean> {

  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
    const parametro: HttpParams = new HttpParams()
      .append('numero', '-1')
      .append('fecha_inicial', this.datepipe.transform(new Date(), 'yyyy-MM-dd'))
      .append('fecha_final', this.datepipe.transform(new Date(), 'yyyy-MM-dd'))
      .append('referencia', '');
    return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/listado`, { params: parametro });
  }
}

