<!-- <div class="wrapper default-theme" [ngClass]="getClasses()">
    <main>
        <app-navbar class="navBarTop" *ngIf="isLoggedIn$ | async as isLoggedIn"></app-navbar>
        <app-sidebar *ngIf="isLoggedIn$ | async as isLoggedIn"></app-sidebar>
        <section>
    <app-loader></app-loader>
            <router-outlet></router-outlet>
        </section>
        <div class="overlay" *ngIf="isLoggedIn$ | async as isLoggedIn" (click)="toggleSidebar()"></div>
    </main>
</div> -->

<div class="wrapper default-theme" [ngClass]="getClasses()">
    <header>
        <app-navbar class="navBarTop" *ngIf="isLoggedIn$ | async as isLoggedIn"></app-navbar>
        <app-sidebar *ngIf="isLoggedIn$ | async as isLoggedIn"></app-sidebar>
    </header>
    <main>
        <router-outlet></router-outlet>
    </main>
</div>

<!-- <div class="container-fluid"></div> -->
<!-- <app-footer *ngIf="isLoggedIn$ | async as isLoggedIn"></app-footer> -->