<!--Dialogo para los correos.
    Esta ventana puede ser utilizada para diferentes modulos con la finalidad de mandar correos
    mediante un modelo..
-->
<div class="modal-header">
    <h5 class="modal-title">{{"General.Correo" | translate}}</h5>
    <button class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <form autocomplete="off" [formGroup]="formulario">
        <div class='form-group'>
            <label>{{"General.Para" | translate}}</label>
            <input type='text' class="form-control" required formControlName="para">
            <small *ngIf="paraNoValida" class="text-danger">{{"Aviso.InformacionRequerida" | translate}}</small>
        </div>
        <div class='form-group'>
            <label>{{"General.Copia" | translate}}</label>
            <input type='text' class="form-control" formControlName="copia">
        </div>
        <div class='form-group'>
            <label>{{"General.CopiaOculta" | translate}}</label>
            <input type='text' class="form-control" formControlName="copiaoculta">
        </div>
        <div class='form-group'>
            <label>{{"General.Asunto" | translate}}</label>
            <input type='text' class="form-control" required formControlName="asunto">
            <small *ngIf="asuntoNoValida" class="text-danger">{{"Aviso.InformacionRequerida" | translate}}</small>
        </div>
        <div class='form-group'>
            <label>{{"General.Mensaje" | translate}}</label>
            <quill-editor required style="width: 100%; height: 30vh " [modules]="modules"
                formControlName="mensaje"></quill-editor>
            <small *ngIf="mensajeNoValida" class="text-danger">{{"Aviso.InformacionRequerida" | translate}}</small>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-correo" (click)="enviarCorreo()" [disabled]="this.trabajando">
        <span *ngIf="this.trabajando" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{"Boton.Correo" | translate}}
    </button>
</div>