<div *ngIf="(loader.isLoading$ | async)" class="loader-container" id="loader">
    <div class="holder">
        <div class="la-line-scale la-2x">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>