import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Metodo para obtener las notificaciones del usuario activo
   * @param id
   * @returns
   */
  public getDataNotificaciones(id: number): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/mensajes/carganotificacion/${id}`);
  }


  public getDataNotificacionSelect(id: number): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/mensajes/carganotificacionseleccionada/${id}`);
  }


  /**
   * Metodo para obtener las notificaciones
   * @param id
   * @param referencia
   * @returns
   */
  public getListado(referencia: string): Observable<any> | any {
    const parametros: HttpParams = new HttpParams().append('referencia', referencia);
    return this.http.get(`${environment.base_url}/mensajes/getListado`, { params: parametros });
  }
}
