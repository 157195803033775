import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrganizacionRoutingModule } from './organizacion-routing.module';
import { NotificacionComponent } from './notificacion/notificacion.component';
import { OrganizacionComponent } from './organizacion.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [NotificacionComponent, OrganizacionComponent],
  imports: [
    CommonModule,
    SharedModule,
    OrganizacionRoutingModule
  ]
})
export class OrganizacionModule { }
