import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgModel, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownList, ListBox } from '@syncfusion/ej2-angular-dropdowns';
import { NumericTextBox } from '@syncfusion/ej2-angular-inputs';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { Grid } from '@syncfusion/ej2-grids';
import { GccalidadService } from '../services/gccalidad.service';
import { MdlComentarios } from '../models/MdlComentarios';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from '../../../components/dlgMensajeError/dlgMensajeError.component';
import { dlgImportar } from '../archivos/archivos.component';
import { DocumentoService } from '../services/documento.service';
import { dlgMensaje } from '../../../components/dlgMensaje/dlgMensaje.component';
import { DlgcorreoComponent } from '../../../components/dlgcorreo/dlgcorreo.component';
import { ItemSeleccionado } from './ItemSeleccionado.modelo';
import { dlgMensajeConfirma } from '../../../components/dlgMensajeConfirma/dlgMensajeConfirma.component';
import { dlgFormulario } from '../dlgFormulario/dlgFormulario.component';

@Component({
    selector: 'app-listado',
    templateUrl: './listado.component.html',
    styleUrls: ['./listado.component.scss'],
})
export class ListadoComponent implements OnInit {
    public breadcrumbs1: string = '';
    public breadcrumbs2: string = '';

    @BlockUI() blockUI: NgBlockUI;
    @ViewChild('dglCorreo') public dglCorreo: DlgcorreoComponent;
    @ViewChild('templateX') public tempX: NgModel;
    @ViewChild('grid') public grid: Grid;
    @ViewChild('referencia') referencia: ElementRef;
    @ViewChild('id_frm_encabezado') id_frm_encabezado: ElementRef;
    @ViewChild('referenciaformulario') referenciaformulario: ElementRef;
    @ViewChild('tfNombre') tfNombre: ElementRef;
    @ViewChild('id_frm_campo_tipo') id_frm_campo_tipo: DropDownList;
    @ViewChild('id_rpt_encabezado') id_rpt_encabezado: DropDownList;
    @ViewChild('folionumero') folionumero: NumericTextBox;
    @ViewChild('lstOpcionDisponibles') lstOpcionDisponibles: ListBox;
    @ViewChild('lstOpcionAsignadas') lstOpcionAsignadas: ListBox;
    @ViewChild('dlgComentario') dlgComentario: DialogComponent;
    @ViewChild('dlgEjecutaFormulario') dlgEjecutaFormulario: DialogComponent;
    /**
     * VARIABLES CON INFORMACIÓN DE CONSULTAS A LA BD
     **/
    public data: any;
    public dataEjecuta: any;
    public columns: Object[];
    public columnsActivadas: boolean = false;
    public data_formulario: any;
    public data_reportes: any;
    public data_tipoParametro: any;
    public dataComentarios: any;
    public encabezado: any;
    public extras: any;
    public ids: any;
    public items: ItemSeleccionado[] = [];

    /**
     * VARIABLES LOCALES UTLIZADAS
     **/
    public id_frm_encabezado_DropList: number = 0;
    public UtilComponent: any;
    public id_frm_opciones: number = -1;
    public id_frm_detalles: number = -1;
    private id_selected_opc: number = -1;
    public id_parametro_select: number = -1;
    public valorDisponible: number = 0;
    public valorAsignado: number = 0;
    /**
     * VARIABLES FORMULARIOS
     **/
    public frmComentario: UntypedFormGroup = new UntypedFormGroup({});
    public Listadoformulario: UntypedFormGroup;
    public Nuevoformulario: UntypedFormGroup;
    public AgregarOpciones: UntypedFormGroup;
    public NuevoParametro: UntypedFormGroup;
    public formulario: UntypedFormGroup;
    /**
     * VARIABLES DE CONFIGURACIÓN EN COMPONENTES
     **/
    public fields: Object = { text: 'descripcion', value: 'id' };
    public formatoFecha: string = 'dd/MM/yyyy';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private gc_calidad_Service: GccalidadService,
        public datepipe: DatePipe,
        private fb: UntypedFormBuilder,
        private modalService: NgbModal,
        private servicio: DocumentoService,
        private offcanvasService: NgbOffcanvas
    ) {
        this.Listadoformulario = this.fb.group({
            fechainicial: [new Date()],
            fechafinal: [new Date()],
            id_frm_encabezado_DropList: [-1],
        });
        this.frmComentario = this.fb.group({
            comentario: ["", Validators.required]
        });

        this.gc_calidad_Service.formulario().subscribe((resp) => {
            this.data_formulario = resp.respuesta;
        });
        this.gc_calidad_Service.lstReportes().subscribe((resp) => {
            this.data_reportes = resp.respuesta;
        });
        this.gc_calidad_Service.lstTipoParam().subscribe((resp) => {
            this.data_tipoParametro = resp.respuesta;
        });

    }

    ngOnInit(): void {
        this.route.data.subscribe(resp => {
            this.breadcrumbs1 = resp.rutas.respuesta.ruta;
            
        });
    }

    /**
     * Metodo para la busqueda de formularios
     * @returns;
     **/
    public btnBuscar() {
        this.blockUI.start('trabajando...');
        this.gc_calidad_Service.getData(
            this.Listadoformulario.get('id_frm_encabezado_DropList').value,
            this.datepipe.transform(this.Listadoformulario.get('fechainicial').value, 'yyyy-MM-dd'),
            this.datepipe.transform(this.Listadoformulario.get('fechafinal').value, 'yyyy-MM-dd'),
            this.referencia.nativeElement.value
        ).subscribe((resp) => {
            if (!this.columnsActivadas) {
                this.columns = JSON.parse(JSON.stringify(resp)).respuesta.columns;
                this.columnsActivadas = !this.columnsActivadas;
            }

            this.data = JSON.parse(JSON.stringify(resp)).respuesta.dataSource;
            if (this.data) {
                this.items = this.data.map(h => new ItemSeleccionado(h.id, false));
            }
            this.blockUI.stop();
        });
    }

    /**
     * Metodo para detarminar que todos los registros esten seleccionados,
     * de ser así el checkbox del encabezado se pone seleccionado
     * Esto sucede cuando seleccionamos 1 x 1
     * @returns
     */
    public isAllCheckBoxChecked() {
        return this.items.every(p => p.seleccionado);
    }

    /**
     * Metodo para poner o retirar la seleccion de todos los registros
     * @param ev valor $evento del checkbox del encabezado
     */
    public checkAllCheckBox(ev: any) {
        this.items.forEach(x => x.seleccionado = ev.target.checked);
    }

    /**
     * Listado de formularios ejecuta
     * @returns;
     **/
    public listadoFrmEjecuta() {
        this.gc_calidad_Service.listadoFrmEjecuta().subscribe((resp) => {
            this.dataEjecuta = JSON.parse(JSON.stringify(resp)).respuesta;
        });
    }

    /**
     * Metodo para el doble click para la ejecución de un nuevo formulario
     * @returns;
     **/
    public btnAbrir(id: number) {
        //this.router.navigate(['/calidad/ejecuta', id]);
        this.router.navigate(['/calidad/formulario', -1, id]);
    }

    /**
     * Metodo para abrir un componente y mandar 2 ids
     * @params id ID de la información detalles
     * @params ii_formulario ID del frm_encabezado(id del formulario seleccionado)
     * @returns;
     **/
    public btnAbrirFrm(args, content) {
        const modalRef = this.offcanvasService.open(dlgFormulario, { position: 'end' });
        modalRef.componentInstance.id_frm_informacion = args.id;
        modalRef.componentInstance.id_frm_encabezado = args.id_formulario;
    }

    /**
    * PROCESO PARA GENERAR ARRAY DE ID
    */
    public onRowSelected(args: any) {
        // Se asigna a una variable temporal los datos seleccionados(Pueden ser multiples registros)
        let registros: any = this.grid.getSelectedRecords();
        // Se asigna a una variable temporal vacia que sirve para guardar las ids.
        let id_clb_membresia: string = '';
        // Se genera un forEach para recorrer los valores del los registros que se seleccionaron.
        registros.forEach((registro) => {
            // Se le asigna a la variable la misma variable más una coma y el valor.
            id_clb_membresia += ',' + registro.id;
        });
        // Se genera variable temporal.
        let cadena_id_membresia;
        // Se genera variable temporal en la que se va a almacenar las ids, el slice permite
        // eliminar la primera coma que se genera al seleccionar un valor.
        cadena_id_membresia = id_clb_membresia.slice(1);
        // Se asigna a un array la cadena convertida en array.
        this.ids = String(cadena_id_membresia);
    }

    /**
     * Metodo para revisar los registro seleccionados y determinar los IDS que seran utilizados
     */
    public btnCorreo() {
        let registros: any = this.items;
        let id_clb_membresia: string = '';
        registros.forEach((registro) => {
            if (registro.seleccionado) {
                id_clb_membresia += ',' + registro.id;
            }
        });
        let cadena_id_membresia;
        cadena_id_membresia = id_clb_membresia.slice(1);
        this.ids = String(cadena_id_membresia);
        console.log(this.ids);
        const modalRef = this.modalService.open(DlgcorreoComponent);
        modalRef.componentInstance.ids = this.ids;
        modalRef.componentInstance.estatus.subscribe(resp => { });
    }

    /**
     * Metodo abrir dialogo para ejecutar un nuevo formulario.
     */
    public btnEjecutaFormulario(): void {
        this.listadoFrmEjecuta();
        this.dlgEjecutaFormulario.show();
    }

    /**
     * Metodo para abrir la ventana modal donde se podra buscar o arrastrar
     * el archivo de excel para su procesamiento.
     */
    public btnArchivos() {
        const modalRef = this.modalService.open(dlgImportar);
        modalRef.componentInstance.encabezado = "Archivos";
        modalRef.componentInstance.contenido = "Importar archivo XLS";
        modalRef.result.then((resultado) => {
            if (resultado > 0) {
                this.btnBuscar();
                const modalRef = this.modalService.open(dlgMensaje);
                modalRef.componentInstance.encabezado = 'Formularios';
                modalRef.componentInstance.contenido = 'Importacion Procesada.';
            }
        }, (reason) => {
            const modalRef = this.modalService.open(dlgMensajeError);
            modalRef.componentInstance.encabezado = 'Formularios';
            modalRef.componentInstance.contenido = reason;
        });
    }

    /**
     * Metodo para enviar el estatus para asignarlo al documento
     * @param args
     */
    public onEstatus(args: any) {
        this.blockUI.start('Procesando...');
        this.servicio.setEstatus(this.formulario.controls['id'].value, args).subscribe(
            respuesta => {
                if (respuesta.numero > 0) {
                    this.servicio.getEncabezado(this.formulario.controls['id'].value).subscribe(
                        resp => {
                            let datosResolver = JSON.parse(JSON.stringify(resp));
                            this.encabezado = datosResolver.respuesta.encabezado;
                            this.extras = datosResolver.respuesta.extras;
                            this.blockUI.stop();
                        }
                    );
                } else {
                    this.blockUI.stop();
                    const modalRef = this.modalService.open(dlgMensajeError);
                    modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
                    modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
                }

            }
        );
    }

    /**
     * Metodo para mostar la ventana de comentarios con respecto a la
     * incidencia seleccionada, para poder ver o agregar nuevos comentarios
     */
    public btnComentario(id: number): void {
        this.frmComentario = this.fb.group({
            comentario: ["", Validators.required]
        });
        let comentario = new MdlComentarios();
        comentario.id_frm_informacion = id;
        this.frmComentario = this.fb.group(comentario);
        this.gc_calidad_Service.getListadoComentarios(id).subscribe((resp) => {
            this.dataComentarios = resp.respuesta;
        });
        this.dlgComentario.show();
    }

    /**
     * Metodo para exportar formulario en formato TXT
     * @param id
     */
    public btnExportar(id: number, nombre: string): void {
        this.blockUI.start('Obteniendo informacion...');
        this.gc_calidad_Service.getTXT(id).subscribe(resp => {
            if (resp.numero > 0) {
                let byteArray = decodeURIComponent(escape(window.atob(resp.archivo)));
                let blob: any = new Blob([byteArray], { type: 'text' });
                const element = document.createElement('a');
                element.href = window.URL.createObjectURL(blob);
                element.download = nombre;
                element.click();
                this.blockUI.stop();
            } else {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
                modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
                this.blockUI.stop();
            }
        });
    }

    /**
     * Metodo para guardar el comentario de la incidencia
     *
     */
    public btnGuardarComentario(): void {
        this.gc_calidad_Service
            .guardarComentario(this.frmComentario.value)
            .subscribe((resp) => {
                let respuesta = JSON.parse(JSON.stringify(resp));
                if (respuesta.numero < 0) {
                    DialogUtility.alert({
                        title:
                            '<span class="dialogo-titulo-error">' +
                            respuesta.titulo +
                            ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                        content: JSON.parse(JSON.stringify(resp)).mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                } else {
                    DialogUtility.alert({
                        title:
                            '<span style="color: #28a745">' +
                            respuesta.titulo +
                            ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
                        content: JSON.parse(JSON.stringify(resp)).mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                    this.dlgComentario.hide();
                }
            });
    }

    public btnBorrar(id: number) {
        const modalRef = this.modalService.open(dlgMensajeConfirma);
        modalRef.componentInstance.encabezado = 'Formulario';
        modalRef.componentInstance.contenido = 'Deseas eliminar el registro ?';
        modalRef.result.then((resultado) => {
            if (resultado == 'Si') {
                this.gc_calidad_Service.borrar(id).subscribe(resp => {
                    let respuesta = JSON.parse(JSON.stringify(resp));
                    if (respuesta.numero < 0) {
                        const modalRef = this.modalService.open(dlgMensajeError);
                        modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
                        modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
                    } else {
                        this.btnBuscar();
                    }
                });
            }
        });
    }
}
