import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dlgMensajeConfirma',
  templateUrl: './dlgMensajeConfirma.component.html',
  styleUrls: ['./dlgMensajeConfirma.component.scss']
})
export class dlgMensajeConfirma {

  @Input() encabezado;
  @Input() contenido;

  constructor(public activeModal: NgbActiveModal) { }

}
