import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { TableroComponent } from './tablero/tablero.component';
import { ListadoResolver } from 'src/app/services/SItablero/listado.resolver';
import { Tablero2Component } from './tablero2/tablero2.component';
import { Tablero2Resolver } from '../../services/SItablero/tablero2.resolver';

const routes: Routes = [
    { path: 'tablero1', component: TableroComponent, resolve: { listado: ListadoResolver } },
    { path: 'tablero2', component: Tablero2Component, resolve: { tablero2: Tablero2Resolver } }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule],
    providers: [

    ]
})
export class SitableroRoutingModule { }
