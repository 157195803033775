import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ErroresService } from 'src/app/shared/errores.service';
import { environment } from 'src/environments/environment';
import { MdlPerfilCorreo } from '../perfil.component';


@Injectable({
  providedIn: 'root',
})
export class PerfilService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) {}

  /**
   * Metodo para guardar configuracion del correo del usuario
   *
   * @param id_rh_empelado
   * @param
   * @returns
   */
  public setCorreo(item: MdlPerfilCorreo): Observable<any> {
    return this.http.post<any>(`${environment.base_url}/usuario/setCorreo`,item)
    .pipe(
      catchError(error => {
        return throwError(this.errores.getErrores(error));
      })
    );;
  }

  /**
   * Metodo para guardar firma individual por documento seleccionado
   *
   * @param id_rh_empelado
   * @param
   * @returns
   */
  public setDocumentoFirma(id: number, firma: string): Observable<any> {
    let parametros: HttpParams = new HttpParams().append('firma', firma);
    return this.http.post<any>(`${environment.base_url}/usuario/setDocumentoFirma/${id}`, parametros)
    .pipe(
      catchError(error => {
        return throwError(this.errores.getErrores(error));
      })
    );;
  }

  /**
   * Metodo para obtener la configuracion del correo del usuario
   * @returns
   */
  public getCorreo(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/usuario/getCorreo`)
    .pipe(
      catchError(error => {
        return throwError(this.errores.getErrores(error));
      })
    );;
  }

  /**
   * Metodo para obtener la imagen de firma del correo del usuario
   * @returns
   */
  public getImagenFirma(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/usuario/getImagenFirma`)
    .pipe(
      catchError(error => {
        return throwError(this.errores.getErrores(error));
      })
    );;
  }

  /**
   * Metodo para obtener listado de documentos a que tiene acceso el usuario
   * @returns
   */
  public getDocumentos(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/usuario/getDocumentos`)
    .pipe(
      catchError(error => {
        return throwError(this.errores.getErrores(error));
      })
    );;
  }

  /**
   * Metodo para obtener la firma del documento seleccionado
   * @returns
   */
  public getDocumentoFirma(id: number): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/usuario/getDocumentoFirma/${id}`)
    .pipe(
      catchError(error => {
        return throwError(this.errores.getErrores(error));
      })
    );;
  }

}
