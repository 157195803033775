import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { UsuarioService } from '../../services/usuario.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LoginComponent {
    @ViewChild('defaultDialog')
    public defaultDialog!: DialogComponent;
    public dialogHeader: string = 'Animation Dialog';
    public dialogCloseIcon: Boolean = true;
    public dialogWidth: string = '285px';
    public animationSettings: Object = { effect: 'Zoom' };
    public isModal: Boolean = true;
    public target: string = '.control-section';
    public showCloseIcon: Boolean = false;
    public visible: Boolean = true;
    public formSubmitted = false;
    public auth2: any;

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private modalService: NgbModal
    ) { }

    public loginForm = this.fb.group({
        database: [''],
        user: [''],
        password: ['']
    });

    /**
     * Metodo para validar usuario y dar acceso a la base de datos correspondiente
     */
    login() {
        if (this.loginForm.controls['database'].value == '') {
            const modalRef = this.modalService.open(dlgMensajeError);
            modalRef.componentInstance.encabezado = 'Arcarius ERP';
            modalRef.componentInstance.contenido = 'Se debe proporcionar una empresa';
            return;
        }
        if (this.loginForm.controls['user'].value == '') {
            const modalRef = this.modalService.open(dlgMensajeError);
            modalRef.componentInstance.encabezado = 'Arcarius ERP';
            modalRef.componentInstance.contenido = 'Se debe proporcionar un usuario';
            return;
        }
        if (this.loginForm.controls['password'].value == '') {
            const modalRef = this.modalService.open(dlgMensajeError);
            modalRef.componentInstance.encabezado = 'Arcarius ERP';
            modalRef.componentInstance.contenido = 'Se debe proporcionar una contraseña';
            return;
        }
        this.usuarioService.login(this.loginForm.value);
    }
}
