<div class="modal-header">
    <h5 class="modal-title encabezado">{{encabezado}}</h5>
</div>
<div class="modal-body">
    <div class="contenido" [innerHtml]="contenido | safeHtml"></div>
</div>
<div class="modal-footer piepagina">
    <button class="btn boton" (click)="activeModal.close('Si')">
        Si
    </button>
    <button class="btn boton btn-color-rojo" autofocus aria-label="Close" (click)="activeModal.close('No')">
        No
    </button>
</div>
