import { Component, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmpleadoService } from '../../services/RHgestion/Empleados/empleado.service';

@Component({
	selector: 'dlgEmpleado',
	templateUrl: './dlgEmpleado.component.html',
	styleUrls: ['./dlgEmpleado.component.scss']
})
export class DlgEmpleadoComponent {
	@Output('empleado') empleado = new EventEmitter<any>();
	@ViewChild('referencia') referencia: ElementRef;
	public data: any;
	public columns: Object[];

	constructor(
		private modalService: NgbModal,
		private empleadoService: EmpleadoService,
		public activeModal: NgbActiveModal
	) { }

	/**
	 * Metodo a ejecutar despues de la carga de componentes web
	 * Para este caso poner el foco dentro del componente input de referencia
	 * listo para recibir captura de datos y busca al empleado
	 */
	ngAfterViewInit(): void {
		this.referencia.nativeElement.focus();
	}

	/**
	 * Metodo para buscar los empleados con base en la referencia
	 */
	public buscar() {
		this.empleadoService.buscar(this.referencia.nativeElement.value).subscribe(resp => {
			this.columns = JSON.parse(JSON.stringify(resp)).respuesta.columns;
			this.data = JSON.parse(JSON.stringify(resp)).respuesta.dataSource;
		});
	}

	/**
	 * Se encarga de obtener el dato del componente y enviarlo al EventEmitter
	 */
	public getEmpleado(args: any) {
		this.activeModal.close('Close click');
		this.empleado.emit(
			{
				id: args.id,
				nombre: args.nombre,
				paterno: args.paterno,
				materno: args.materno
			}
		);
	}

	/**
   * Metodo para cerrar la ventana modal
   */
	public close() {
		this.activeModal.close('Close click');
	}

}
