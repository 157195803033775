import { Component, Input, NgModule } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { dlgMensajeError } from '../../../components/dlgMensajeError/dlgMensajeError.component';
import { NgxFileDropEntry } from 'ngx-file-drop/ngx-file-drop/ngx-file-drop-entry';

@Component({
  selector: 'ImportarArchivos',
  templateUrl: './importar-archivos.html',
  styleUrls: ['./importar-archivos.scss']
})
export class ImportarArchivos {
  @BlockUI() blockUI: NgBlockUI;
  @Input() encabezado;
  @Input() contenido;
  @Input() id: number;
  public data: any[];

  private archivos: UploaderComponent;
  public archivoArea: HTMLElement;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private http: HttpClient
  ) { }

  public fileOver(event) {
    //console.log(event);
  }

  public fileLeave(event) {
    //console.log(event);
  }

  public files: NgxFileDropEntry[] = [];
  public etiquetas: Array<{nombre: string, estado: string}> = [];

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        if (droppedFile.relativePath.endsWith('.xls') || droppedFile.relativePath.endsWith('.xlsx')) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            this.blockUI.start('Procesando...');
            let formulario = new FormData();
            formulario.append('archivo', file, droppedFile.relativePath);
            formulario.append('Content-Type', 'application/json');
            formulario.append('Accept', `application/json`);
            this.http.post(`${environment.base_url}/gccalidad/importar/excel/${this.id}`, formulario).subscribe(resp => {
              let respuesta = JSON.parse(JSON.stringify(resp));
              if (respuesta.numero > 0) {
                //==============================================================================
                // Utilizacion de "ej2_instances" para poder ejecutar un metodo dentro del componente
                // Este componente no se utilizo por ViewChild, ya que no se renderiza al inicio
                //==============================================================================
                (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                //==============================================================================
                this.activeModal.close(respuesta.numero);
                this.blockUI.stop();
              } else {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = respuesta.titulo;
                modalRef.componentInstance.contenido = respuesta.mensaje;
                this.blockUI.stop();
              }
            });
          });
          this.etiquetas.push({'nombre': droppedFile.relativePath, 'estado': 'correcto'});
        }else{
          this.etiquetas.push({'nombre': droppedFile.relativePath, 'estado': 'incorrecto'});
        }
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }
}