import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class util {
    /**
    * Funcion para obtener data del JSON y validar posible conversión a un objeto de tipo fecha
    * en javacript.
    * Se valida por medio de Expersión Regular qu cumpla con las posiciones de una estructura de fecha
    * 4 digitos = año
    * 2 digitos = mes
    * 2 digitos = dia
    * 2 digitos = horas
    * 2 digitos = minutos
    * 2 digitos = segundos
    *
    * Problema con UTC format, Cuando tenemos una fecha sin horario al convertir a Date utiliza
    * el standart UTC con lo cual provoca un conversión con diferencia de horario y en determinadados
    * casos provoca una fecha menor por 1 dia, para solicionar esta situacion concatenamos T00:00:00
    * a la fecha antes de hacer la conversión.
    *
    * @param key Nombre de la estructura del dato
    * @param value Valor de la estructura del dato
    * @returns Si cumple con la condición se regresa el Valor como objeto Date de lo contrario el Valor original
    */

    parseFecha = function (key, value) {
        if (typeof value === "string" && new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/).test(value)) {
            return new Date(value);
        }
        if (typeof value === "string" && new RegExp(/^\d{4}-\d{2}-\d{2}$/).test(value)) {
            return new Date(Date.parse(value + 'T00:00:00'));
        }
        if (typeof value === "string" && new RegExp(/^\d{2}:\d{2}:\d{2}$/).test(value)) {
            return new Date('1900-01-01T' + value);
        } if (typeof value === "string" && new RegExp(/^\d{2}:\d{2}$/).test(value)) {
            return new Date('1900-01-01T' + value);
        }
        return value;
    }

    /**
     * Funcion para identificar fecha en formato dd-MM-yyyy y devolverlo en formato yyyy-MM-dd
     * Esto es utilizado en componentes de fecha que requieren un formato de visualizacion
     * pero al momento de enviarlo a la DB se requiere otro formato, entonces la informacion
     * se intercambua de posision para poder ser aceptada por la API y la DB.
     * 
     * @param value 
     * @returns 
     */
    parseFechaFormato = function (value: any) {
        if (typeof value === "string" && new RegExp(/^\d{1,2}-\d{1,2}-\d{4}$/).test(value)) {
            const _fecha = value;
            return _fecha.split('-')[2] + '-' + _fecha.split('-')[1] + '-' + _fecha.split('-')[0];
        }
        return value;
    }

    /**
     * Funcion para identificar fecha dentro de un objeto JSon y convertir la estructura para ser 
     * aceptada por el componente de fecha de NG Bootstrap el cual debe ser de la siguiente manera:
     * {year: 2024, month: 01, day: 01}
     * @param key 
     * @param value 
     * @returns 
     */
    parseFechaNG = function (key: string, value: any) {
        if (typeof value === "string" && new RegExp(/^\d{4}-\d{2}-\d{2}$/).test(value)) {
            return {
                year: Number(value.split('-')[0]),
                month: Number(value.split('-')[1]),
                day: Number(value.split('-')[2])
            };
        }
        return value;
    }

    public base64ToByte(cadena: string) {
        var binary_string = window.atob(cadena);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    /**
     * Codifica la cadena a Base64
     * @param cadena 
     * @returns 
     */
    public stringToBase64(cadena: string) {
        return window.btoa(cadena);
    }

    /**
     * Funcion para ejecutar una serie de operaciones matematicas
     * @param str 
     * @returns 
     */
    public executar(str) {
        return Function(`'use strict'; return (${str})`)()
    }

    /**
     * Funcion para evaluar un serie de operaciones matematicas, las cuales son utilizadas en formularios
     * para obtener datos 1 o mas componentes, para posteriormente evaluar algunas operaciones matematicas
     * basicas y obtener un resultado que puede asignarse a otro componente.
     * @param str 
     * @returns 
     */
    public evaluar(str) {
        return eval(str);
    }
}
