<div class="breadcrumb_navbar">
  <ul class="breadcrumb">
    <li><a>{{this.breadcrumbs1}}</a></li>
    
  </ul>
</div>
<block-ui>
</block-ui>

<section>
    <app-loader></app-loader>
  <div class="card">
    <div class="card-detail">
      <div class="form-group col-md-4">
        <label for="exampleFormControlSelect1">Referencia</label>
        <div class="input-group">
          <input type="text" #referencia autofocus class="form-control" (keydown.enter)="btnBuscar()">
          <div class="input-group-append">
            <button class="btn btn-buscar-cuadrado" (click)="btnBuscar()"></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <ul class="list-group">
      <!-- INICIO NOTIFICACIÓN -->
      <div class="row" *ngFor="let notas of data">
        <li class="list-group-item">
          <div class="row" (click)="cargaMensaje(notas.id)">
            <div class="col-md-2">
              <div class="thumb hidden-sm-down m-r-20">
                <img *ngIf="notas.imagen" class="img-avatar"
                  [src]="domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + notas.imagen)">
                <img *ngIf="!notas.imagen" class="img-avatar" src="/assets/img/avatar.png">
              </div>
            </div>
            <div class="col-md-10">
              <a class="m-r-10"><b>{{notas.origen}}</b></a>
              <span class="badge" [ngClass]="notas.estatus == 'Leido' ? 'bg-green' : 'bg-red'">{{notas.estatus}}</span>
              <small class="float-right text-muted">{{notas.fecha | date: 'medium'}}</small>
              <div [innerHTML]="notas.descripcion | safeHtml"></div>
            </div>
          </div>
        </li>
      </div>
      <!-- FIN NOTIFICACIÓN -->
    </ul>
  </div>
</section>

<ejs-dialog id="dlgnotificacion" #dlgnotificacion header='' height='60%' width='500px' isModal='true' [visible]='false'
  showCloseIcon='true' [buttons]='buttons'>
  <ng-template #header>
    <div id="dlg-template" class="e-icon-settings"><i class="fas fa-dice-d6"></i> Notificaciones
    </div>
  </ng-template>
  <ng-template #content>
    <table class="body-wrap">
      <tbody>
        <tr
          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
          <td
            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;"
            valign="top"></td>
          <td class="container" width="600"
            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; display: block !important; max-width: 600px !important; clear: both !important; margin: 0 auto;"
            valign="top">
            <div class="content"
              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; max-width: 600px; display: block; margin: 0 auto; padding: 20px;">
              <table class="main" width="100%" cellpadding="0" cellspacing="0">
                <tbody>
                  <tr
                    style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                    <td>
                      <a style="font-size:32px;color:#fff;"> Notificación</a> <br>
                    </td>
                  </tr>
                  <tr
                    style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                    <td class="content-wrap"
                      style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 20px;"
                      valign="top">
                      <table width="100%" cellpadding="0" cellspacing="0"
                        style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                        <tbody>
                          <tr
                            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                            <td class="content-block"
                              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                              valign="top">
                              {{descripcion}}
                            </td>
                          </tr>
                          <tr
                            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                            <td class="content-block"
                              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                              valign="top">
                              <b>{{origen}}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="footer"
                style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; clear: both; color: #999; margin: 0; padding: 20px;">
                <table width="100%"
                  style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                  <tbody>
                    <tr
                      style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
                      <td class="aligncenter content-block">
                        {{fecha | date: 'medium'}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
          <td
            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;"
            valign="top"></td>
        </tr>
      </tbody>
    </table>

  </ng-template>
</ejs-dialog>
