import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DocumentoService } from 'src/app/pages/SDlogistica/documento/Services/Documento.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from '../dlgMensajeError/dlgMensajeError.component';
import { dlgMensaje } from '../dlgMensaje/dlgMensaje.component';


@Component({
  selector: 'dlgCorreoFormulario',
  templateUrl: './dlgCorreoFormulario.component.html',
  styleUrls: ['./dlgCorreoFormulario.component.scss']
})
export class dlgCorreoFormulario implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  @Input('ids') ids: string = '';
  @ViewChild('dglCorreo') public dglCorreo: DialogComponent;
  @ViewChild('para') para: ElementRef;
  @ViewChild('copia') copia: ElementRef;
  @ViewChild('copiaoculta') copiaoculta: ElementRef;
  @ViewChild('encabezado') encabezado: ElementRef;
  @ViewChild('mensaje') mensaje: RichTextEditorComponent;
  public formulario: UntypedFormGroup;
  public toolbarSettings: Object = { items: ['Bold', 'Italic'] };


  constructor(
    private modalService: NgbModal,
    private correo: DocumentoService,
    private fb: UntypedFormBuilder,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.formulario = this.fb.group({
      para: ['', Validators.required],
      asunto: ['', Validators.required],
      mensaje: ['', Validators.required]
    });
  }

  /**
   * Metodos para las validaciones de Nuevoformulario
   */
  get paraNoValida() {
    return (
      this.formulario.get('para').invalid &&
      this.formulario.get('para').touched
    );
  }
  get asuntoNoValida() {
    return (
      this.formulario.get('asunto').invalid &&
      this.formulario.get('asunto').touched
    );
  }
  get mensajeNoValida() {
    return (
      this.formulario.get('mensaje').invalid &&
      this.formulario.get('mensaje').touched
    );
  }

  @Input('show') show() {
    this.formulario = this.fb.group({
      para: ['', Validators.required],
      asunto: ['', Validators.required],
      mensaje: ['', Validators.required]
    });
    this.dglCorreo.show();
  }

  @Input('hide') hide() {
    this.dglCorreo.hide();
  }

  public revisa() {
    if (this.formulario.invalid) {
      return Object.values(this.formulario.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }

  /**
   * Metodo para el envio de correos
   * @returns
   */
  public enviarCorreo() {
    this.revisa();
    if (!this.formulario.invalid) {
      let pass = prompt("Contraseña para Archivo PDF", "");
      if (pass == null) {
        pass = '';
      }

      let agrupado = confirm("Los reportes deseas agruparlos?");
      // OK = true, Cancel = false


      this.blockUI.start('Procesando...');
      const parametros: HttpParams = new HttpParams()
        .append('mensaje', this.mensaje.value)
        .append('para', this.para.nativeElement.value.trim())
        .append('copia', this.copia.nativeElement.value.trim())
        .append('copiaoculta', this.copiaoculta.nativeElement.value.trim())
        .append('encabezado', this.encabezado.nativeElement.value.trim())
        .append('ids', this.ids)
        .append('pass', pass)
        .append('agrupado', agrupado);
      this.http.get<any>(`${environment.base_url}/reporte/enviarFormulario`, { params: parametros, }).subscribe((resp) => {
        if (resp.numero < 0) {
          this.blockUI.stop();
          const modalRef = this.modalService.open(dlgMensajeError);
          modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
          modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
          modalRef.componentInstance.error = JSON.parse(JSON.stringify(resp)).error;
        } else {
          this.dglCorreo.hide();
          this.mensaje.value = "";
          this.para.nativeElement.value = "";
          this.copia.nativeElement.value = "";
          this.copiaoculta.nativeElement.value = "";
          this.encabezado.nativeElement.value = "";
          const modalRef = this.modalService.open(dlgMensaje);
          modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
          modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(resp)).mensaje;
          this.blockUI.stop();
        }
      });
    } else {
      this.blockUI.stop();
    }
  }

}
