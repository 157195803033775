<div class="breadcrumb_navbar">
  <ul class="breadcrumb">
    <li><a>{{this.breadcrumbs1}}</a></li>
    
  </ul>
</div>

<block-ui>
</block-ui>
<section>
    <app-loader></app-loader>
  <div class="card">
    <div class="card-body">
      <button class="btn btn-nuevo" (click)="btnNuevo()"> {{"Boton.Nuevo" | translate}}</button>
    </div>
    <div class="card-detail">
      <div class="table-responsive">
        <table *ngIf="this.dataLstProyectos" class="table table-hover table-bordered">
          <thead>
            <th style="width: 80px;"></th>
            <th>Descripcion</th>
          </thead>
          <tbody>
            <tr *ngFor="let item of this.dataLstProyectos">
              <td>
                <button class="btn btn-editar-sm" title="Modificar" (click)="btnEditar(item.id)"></button>
                <button class="btn btn-borrar-sm" title="Eliminar" (click)="btnBorrar(item.id)"></button>
              </td>
              <td>
                {{item.descripcion}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<ejs-dialog id="dlgFormulario" #dlgFormulario header='Registro de Proyectos' height='40%' width='40%' isModal='false'
  [visible]='false' showCloseIcon='true'>
  <ng-template #content>
    <form autocomplete="off" [formGroup]="formulario">
      <div class="form-group">
        <label for="exampleFormControlInput1">Descripción</label>
        <input type="text" required class="form-control " id="exampleFormControlInput1" placeholder=""
          formControlName="descripcion">
        <small *ngIf="descripcionNoValida" class="text-danger">Se requiere una
          descripción</small>
      </div>
    </form>
  </ng-template>
  <ng-template #footerTemplate>
    <div class="form-group">
      <button class="btn btn-guardar" (click)="btnGuardar()"> {{"Boton.Guardar" | translate}}</button>
    </div>
  </ng-template>
</ejs-dialog>
