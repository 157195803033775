import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { ErroresService } from '../../../shared/errores.service';



@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }

  public getEncabezado(id: number) {
    return this.http.get<any>(`${environment.base_url}/gccalidad/documento/encabezado/${id}`)
  }


  /**
   * Metodo para obtener el detalle del documento de compra
   */
  public getDetalle(id_encabezado: number) {
    return this.http
      .get(`${environment.base_url}/gccalidad/documento/detalle/${id_encabezado}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
   * Metodo para asignar el estatus al documento de compra
   */
  public setEstatus(id: number, id_estatus: number) {
    return this.http.post<any>(`${environment.base_url}/gccalidad/documento/estatus/${id}/${id_estatus}`, {})
  }

  /**
   * Metodo para eliminar partidas del documento de compra
   */
  public setElimina(id: number) {
    return this.http.post<any>(`${environment.base_url}/gccalidad/documento/elimina/${id}`, {})
  }

  /**
   * Metodo para cambiar o complementar la descripcion de la partida
   */
  public setProducto(args: any) {
    return this.http.post<any>(`${environment.base_url}/gccalidad/documento/producto`, args)
  }


  /**
  * Obtenemos el listado de Comentarios de la incidencia proporcionada 
  * 
  * @returns Observable con listado de Comentarios
  */
  public getComentarios(id: number): Observable<any> {
    const parametros: HttpParams = new HttpParams().append('id', String(id));
    return this.http.get(`${environment.base_url}/gccalidad/documento/comentarios`, { params: parametros })
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

}
