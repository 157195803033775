<block-ui>
</block-ui>
<div class="modal-header">
    <h4 class="modal-title encabezado">{{encabezado}}</h4>
</div>
<div class="modal-body">
    <div id='droparea' class="area">
        Arrastrar y Soltar aqui tu archivo o dar click
        <a href="" id="browse" (click)='browseClick()'><u>Aquí</u></a> para buscar el archivo.
        <ejs-uploader id="archivoCarga" [autoUpload]='false' [dropArea]='archivoArea' multiple='false' (selected)="selected($event)">
            <ng-template #template let-data>
                <span class="fileListwrapper">
                    <span class="icon template-icons sf-icon-{{data.type}}"></span>
                <span class="name file-name">{{data.name}}
                        ({{data.size}}bytes)</span>
                <span class="upload-status">{{data.status}}</span>
                </span>
                <span class="e-icons e-file-remove-btn" title="Remove"></span>
            </ng-template>
        </ejs-uploader>
    </div>

</div>
<div class="modal-footer piepagina">
    <button class="btn boton" aria-label="Close" (click)="activeModal.close('-1')">
        Cerrar
    </button>
</div>
