import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MdlUsuario } from '../models/MdlUsuario';
import { Observable } from 'rxjs';
import { UsuarioService } from '../services/usuario.service';
import { AppService } from '../services/app.service';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss']
})
export class PagesComponent {
    public notificacion: any = [];
    public NotificacionPosicion: number = 1;
    public usuario: MdlUsuario;

    isLoggedIn$: Observable<boolean>;
    public activo: boolean = false;
    public marginTop: string = '50px';

    constructor(
        private http: HttpClient,
        private appService: AppService,
        private usuarioService: UsuarioService
    ) {
        this.usuario = JSON.parse(localStorage.getItem('usuario'));

        this.usuarioService.getConfiguracion(2).subscribe(resp => {
            if (JSON.parse(resp.respuesta).activado) {
                this.marginTop = '110px';
            }
        });
    }

    ngOnInit(): void {
        this.isLoggedIn$ = this.usuarioService.isLoggedIn;
        //=============================================================================================
        // Obtenemos la informacion de los mensajes del usuario
        //=============================================================================================
        const base_url = environment.base_url;
        this.http.get<any>(`${base_url}/organizacion/notificacion/listado`).subscribe(
            respuesta => {
                this.notificacion = respuesta;
            }
        );
        //=============================================================================================
    }

    cargaNotificacion() {
        const base_url = environment.base_url;
        this.http.get<any>(`${base_url}/organizacion/notificacion/listado`).subscribe(
            respuesta => {
                this.notificacion = respuesta;
            }
        );
    }

    onNotificacionChange(posicion: number): void {
        this.NotificacionPosicion = posicion;
        if (this.NotificacionPosicion == 1) {
            this.cargaNotificacion();
        }
    }

    getClasses() {
        const classes = {
            'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
            'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled,
            'toggeled-menubar': this.appService.getSidebarStat().isMenubarToggeled
        }
        return classes;
    }

    toggleSidebar() {
        this.appService.toggleSidebar();
    }

}
