import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CorreoService {

    /**
     * Ruta que corresponde a la URL del servicio de la API con respecto a su controlador
     * Siempre debe comenzar con diagonal, por ejemplo, '/modulos/listado'
     */
    private ruta: string = `${environment.base_url}/correo`;

    constructor(
        private http: HttpClient
    ) { }



    /**
     * Metodo para mandar data a la API para almacenarla en la DB
     * @param modelo Objeto el cual contiene estructura e informacion para ser almacenada en la DB
     * es importante que la estructura del objeto (modelo) sea identica dentro de Angular y de la API
     * @returns
     */
    public setCorreo(modulo: string, modelo: any): Observable<any> {
        if (modulo == 'ventas') {
            return this.http.post(`${this.ruta}/enviarCorreoVenta`, modelo);
        } else if (modulo == 'crm') {
            return this.http.post(`${this.ruta}/enviarCorreoCRM`, modelo);
        } else if (modulo == 'logistica') {
            return this.http.post(`${this.ruta}/enviarLogistica`, modelo);
        } else if (modulo == 'compras') {
            return this.http.post(`${this.ruta}/enviarCorreoCompra`, modelo);
        }
    }

}
