<div class="breadcrumb_navbar">
  <ul class="breadcrumb">
    <li><a>{{this.breadcrumbs1}}</a></li>
    
  </ul>
</div>

<block-ui>
</block-ui>
<section>
    <app-loader></app-loader>
  <div class="card">
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Proyecto</label>
              <ejs-dropdownlist [dataSource]='data_proyecto' [fields]='fields' placeholder='Seleccionar Proyecto'
                (change)='onChangeProyecto($event)'>
              </ejs-dropdownlist>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Tipo</label>
              <ejs-dropdownlist [dataSource]='data_tipo' [fields]='fields' placeholder='Seleccionar Tipo de Documento'
                (change)='onChangeTipo($event)'>
              </ejs-dropdownlist>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <div class="callout callout-primary">
                <div id='droparea' style="height: 70px; background: #e9f5db; text-align: center; padding-top: 20px;">
                  Soltar aqui tu archivo
                  <a href="" id="browse" (click)='browseClick()'><u>Buscar Archivo</u></a>
                  <ejs-uploader id="archivoCarga" [autoUpload]='false' [dropArea]='dropEle' multiple='false'
                    (selected)="selected($event)">
                    <ng-template #template let-data>
                      <span class="fileListwrapper">
                        <span class="icon template-icons sf-icon-{{data.type}}"></span>
                        <span class="name file-name">{{data.name}} ({{data.size}}
                          bytes)</span>
                        <span class="upload-status">{{data.status}}</span>
                      </span>
                      <span class="e-icons e-file-remove-btn" title="Remove"></span>
                    </ng-template>
                  </ejs-uploader>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button class="btn btn-convertir" (click)="btnAsignar()">Asignar a Usuario</button>
        </div>
      </form>
    </div>
  </div>


  <div class="card">
    <div class="card-detail">
      <div class="table-responsive">
        <table *ngIf="this.data" class="table table-hover table-bordered">
          <thead>
            <th style="width: 40px;"></th>
            <th>Nombre</th>
            <th>Tipo</th>
          </thead>
          <tbody>
            <tr *ngFor="let item of this.data">
              <td><button class="btn btn-borrar" title="Eliminar Documento" (click)="btnBorrar(item.id)"></button></td>
              <td>{{item.nombre}}</td>
              <td>{{item.tipo}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>

<ejs-dialog #dlgAsignarPermiso header='Asignar Documentos' [isModal]='true' [visible]='false' [showCloseIcon]='true'
  width='700px'>
  <div class="dialogContent">
    <div class="container">
      <div class="row" style="display: flex;">
        <div class="col-md-12">
          <div class="row" style="display: flex;">
            <div class="col-md-6">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Empleado</label>
                <div class="input-group">
                  <input type="text" required #tfNombre class="form-control" readonly>
                  <input type="hidden" #id_rh_empleado class="form-control">
                  <div class="input-group-append">
                    <button class="btn btn-buscar" (click)="buscar()"></button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="row" style="display: flex;">
            <div class="col-md-6">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Proyecto</label>
                <ejs-dropdownlist [dataSource]='data_proyecto' [fields]='fields' placeholder='Seleccionar Proyecto'
                  (change)='onChangeProyecto2($event)'>
                </ejs-dropdownlist>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Tipo</label>
                <ejs-dropdownlist [dataSource]='data_tipo' [fields]='fields' placeholder='Seleccionar Tipo de Documento'
                  (change)='onChangeTipo2($event)'>
                </ejs-dropdownlist>
              </div>
            </div>
          </div>
          <div class="row" style="display: flex;">
            <div class="col-md-6">
              <div class="form-group">
                <div class="dual-list-groupa">
                  <h5>Disponibles</h5>
                  <ejs-listbox #disponibles [dataSource]='dataDisponibles' height='150px' [fields]='fields'
                    (click)="btnIdentificador(1)"></ejs-listbox>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="dual-list-groupb">
                  <h5>Asignadas</h5>
                  <ejs-listbox #asignadas [dataSource]='dataAsignadas' height='150px' [fields]='fields'
                    (click)="btnIdentificador(2)">
                  </ejs-listbox>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <button class="btn btn-outline-secondary mb-2 mr-2" (click)="btnAgregarDocumentos()"><i
          class="fas fa-chevron-right"></i> {{"Boton.Agregar" | translate}}</button>
      <button class="btn btn-outline-secondary mb-2 mr-2" (click)="btnQuitarDocumentos()"><i
          class="fas fa-chevron-left"></i>Quitar</button>
    </div>
  </div>
</ejs-dialog>
