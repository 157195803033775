import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dlgMensaje',
  templateUrl: './dlgMensaje.component.html',
  styleUrls: ['./dlgMensaje.component.scss']
})
export class dlgMensaje {

  @Input() encabezado;
  @Input() contenido;

  constructor(public activeModal: NgbActiveModal) { }

}
