import { Component, OnInit, Renderer2, Inject, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { util } from 'src/app/shared/util';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { dlgMensaje } from 'src/app/components/dlgMensaje/dlgMensaje.component';
import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { PerfilService } from './services/perfil.service';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';

export class MdlPerfilCorreo {
  correo_puerto_pop3: number = 110;
  correo_puerto_smtp: number = 25;
  correo_tipo: number = 1;
  smtp: string = "";
  pop3: string = "";
  correo_nombre: string = "";
  correo_usuario: string = "";
  correo_direccion: string = "";
  correo_clave: string = "";
  autenticacion: boolean = false;
  ssl_pop: boolean = false;
  ssl_smtp: boolean = false;
  tls: boolean = false;
}

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  public breadcrumbs1: string = '';
  public breadcrumbs2: string = '';

  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('archivo') archivo: ElementRef;
  @ViewChild('tfFirmaTexto') tfFirmaTexto: RichTextEditorComponent;
  @ViewChild('lstDocumentos') lstDocumentos: ElementRef;
  @ViewChild('imgRenderer') imgRenderer: ElementRef;
  @ViewChild('imgFirmaRenderer') imgFirmaRenderer: ElementRef;
  @ViewChild('dlgimagen') dlgimagen: DialogComponent;
  @ViewChild('dlgimagenFirma') dlgimagenFirma: DialogComponent;

  private _id_rh_empleado_firma: number;
  private archivos: UploaderComponent;
  public imageFile: { link: string, file: any, name: string };
  public data: any;
  public dataDocumentos: any;
  public imagen;
  public imagenFirma;
  public htmlAttributes = { name: "username", type: "password", maxlength: "20" };
  public formulario: UntypedFormGroup;
  public frmCorreo: UntypedFormGroup;
  public frmFirmaDocumento: UntypedFormGroup;

  public targetElement: HTMLElement;
  public dropEleImg: HTMLElement;
  public dropEleImgFirma: HTMLElement;

  public imageSettings: object = { saveFormat: "Base64" };
  public tools: object = {
    items: [
      'Bold', 'Italic', 'Underline', '|', 'FontSize', 'FontColor', 'BackgroundColor'
    ]
  };

  constructor(
    private http: HttpClient,
    private servicio: PerfilService,
    private modalService: NgbModal,
    private utilerias: util,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private documento
  ) {
    this.route.data.subscribe((resp: { datos: any[] }) => {
      this.data = JSON.parse(JSON.stringify(resp.datos)).respuesta;
      this.imagen = this.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${this.data.imagen}`);
    });
    this.crearFormulario();
    this.crearFrmCorreo();
    this.crearfrmFirmaDocumento();
  }

  crearFormulario() {
    this.formulario = this.fb.group({
      usuario: this.data.usuario,
      password: ['', [Validators.required, Validators.minLength(4)]],
      password2: ['', [Validators.required, Validators.minLength(4)]]
    });
  }

  crearFrmCorreo() {
    this.frmCorreo = this.fb.group(new MdlPerfilCorreo());
  }

  crearfrmFirmaDocumento() {
    this.frmFirmaDocumento = this.fb.group({
      usuario: this.data.usuario
    });
  }

  get password() {
    return this.formulario.get('password');
  }

  get password2() {
    return this.formulario.get('password2');
  }

  ngOnInit(): void {
    this.route.data.subscribe((resp) => {
      this.breadcrumbs1 = resp.rutas.respuesta.ruta;
      
    });
  }

  /**
   * Metodo para ejecutare al seleccionar un apartado de acordeon
   *
   * Cuando args.nextState = true, quiere decir que el acordeon se abre, entonces ejecutamos
   * nuestro proceso para obtener la informacion de la API
   *
   * Cuando args.panelId = 'pnTableros' es verdadero, lo cual nos nidica que el acordeon
   * de Tableros fue seleccionado.
   *
   */
  public beforeChange(args: NgbPanelChangeEvent) {
    if (args.nextState){
      if (args.panelId == 'pnCorreo') {
        this.blockUI.start('Obteniendo Informacion');
        this.servicio.getCorreo().subscribe((resp) => {
          this.frmCorreo = this.fb.group(resp.respuesta as MdlPerfilCorreo);
          this.blockUI.stop();
        });
      } else if(args.panelId == 'pnFirmaImagen') {
        this.blockUI.start('Obteniendo Imagen de Firma');
        this.servicio.getImagenFirma().subscribe(resp => {
          this.imagenFirma = this.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${JSON.parse(JSON.stringify(resp)).respuesta.imagen}`);
          this.blockUI.stop();
        });
      } else if(args.panelId == 'pnFirmas') {
        this.blockUI.start('Obteniendo documentos');
        this._id_rh_empleado_firma = null;
        this.servicio.getDocumentos().subscribe(resp => {
          this.dataDocumentos = JSON.parse(JSON.stringify(resp)).respuesta;
          this.blockUI.stop();
        });
      }
    }
  }

  public getDocumentoFirma(id: number){
    this.blockUI.start('Obteniendo Firma de documento');
    this.servicio.getDocumentoFirma(id).subscribe(resp =>{
      this.tfFirmaTexto.value = resp.respuesta.descripcion;
      this.blockUI.stop();
    });
  }

  public btnGuardarDocumento(){
    this.blockUI.start('Obteniendo Informacion');
    if(this.lstDocumentos.nativeElement.value as number < 0){
      this._id_rh_empleado_firma = this.lstDocumentos.nativeElement.value;
    }

    this.servicio.setDocumentoFirma(this.lstDocumentos.nativeElement.value, this.tfFirmaTexto.value).subscribe(resp =>{
      let respuesta = JSON.parse(JSON.stringify(resp));
      if(respuesta.numero > 0){
        if(this._id_rh_empleado_firma < 0){
          this._id_rh_empleado_firma = respuesta.numero;
          this.servicio.getDocumentos().subscribe(resp => {
            this.dataDocumentos = JSON.parse(JSON.stringify(resp)).respuesta;
          });
        }
        console.log('_id_rh_empleado_firma: ', this._id_rh_empleado_firma);

        const modalRef = this.modalService.open(dlgMensaje);
        modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
        modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
      }else{
        const modalRef = this.modalService.open(dlgMensajeError);
        modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
        modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
      }
      this.blockUI.stop();
    });
  }

  public openImagen() {
    this.dropEleImg = document.getElementById('dropareaImg');
  }

  public openImagenFirma() {
    this.dropEleImgFirma = document.getElementById('dropareaImgFirma');
  }

  public browseClickImg() {
    document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();
    return false;
  }

  creamosScript() {
    const script = this.renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = '/assets/scripts/perfil.script.js';
    script.text = ``;
    this.renderer2.appendChild(this.documento.body, script);
  }


  /**
   * Metodo para guardar cambio de password
   *
   * @returns
   */
  btnGuardar() {
    if (this.formulario.get('password').value !== this.formulario.get('password2').value) {
      DialogUtility.alert('Las contraseñas no son iguales');
      return;
    } else {
      this.http.post(`${environment.base_url}/auth/guardar`, this.formulario.value).subscribe(
        resp => {
          DialogUtility.alert({
            title: 'Informacion Arcarius',
            content: JSON.parse(JSON.stringify(resp)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' }
          });
        },
        error => {
          DialogUtility.alert(`Problemas al Guardar, ${error}`);
        }
      );
    }
  }

  /**
   * Metodo para almacenar la imagen del usuario
   *
   */
  guardarImagen() {
    this.blockUI.start('Procesando...');
    let formulario = new FormData();
    let byteArray = this.utilerias.base64ToByte(this.imagen.replace(/^data:image\/(png|jpg);base64,/, ""));
    let blob: any = new Blob([byteArray], { type: 'image/png' });

    formulario.append('imagen', blob);
    formulario.append('Content-Type', 'application/json');
    formulario.append('Accept', `application/json`);

    this.http.post(`${environment.base_url}/usuario/subirImagen`, formulario)
      .subscribe(resp => {
        let respuesta = JSON.parse(JSON.stringify(resp));
        const modalRef = this.modalService.open(dlgMensaje);
        modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
        modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
        this.blockUI.stop();
      });
  }

  /**
   * Metodo para almacenar la imagen de Firma del usuario
   * Esta imagen va al final de los correos que enevia el usuario
   *
   */
  guardarImagenFirma() {
    this.blockUI.start('Procesando...');
    let formulario = new FormData();
    let byteArray = this.utilerias.base64ToByte(this.imagenFirma.replace(/^data:image\/(png|jpg);base64,/, ""));
    let blob: any = new Blob([byteArray], { type: 'image/png' });

    formulario.append('imagen', blob);
    formulario.append('Content-Type', 'application/json');
    formulario.append('Accept', `application/json`);

    this.http.post(`${environment.base_url}/usuario/subirImagenFirma`, formulario).subscribe(resp => {
        let respuesta = JSON.parse(JSON.stringify(resp));
        if(respuesta.numero > 0){
          const modalRef = this.modalService.open(dlgMensaje);
          modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
          modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
        }else{
          const modalRef = this.modalService.open(dlgMensajeError);
          modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
          modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
        }
        this.blockUI.stop();
      });
  }

  public btnImagen() {
    this.dlgimagen.show();
  }

  public btnImagenFirma() {
    this.dlgimagenFirma.show();
  }

  public btnImportar() {
    this.dlgimagen.hide();
  }

  public btnImportarFirma() {
    this.dlgimagenFirma.hide();
  }

  /**
   * Metodo para almacenar la configuracion del correo
   */
  public btnGuardarCorreo() {
    this.blockUI.start('Configurando');
    this.servicio.setCorreo(this.frmCorreo.value).subscribe(respuesta =>{
      if (respuesta.numero > 0) {
        const modalRef = this.modalService.open(dlgMensaje);
        modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
        modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
      }else{
        const modalRef = this.modalService.open(dlgMensajeError);
        modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
        modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
      }
      this.blockUI.stop();
    });
  }

  public selectedImg(args: UploaderComponent) {
    this.archivos = args;
    const reader = new FileReader();
    reader.readAsDataURL(args.filesData[0].rawFile as any);
    reader.onload = (_event: any) => {
      this.imageFile = {
        link: _event.target.result,
        file: args.filesData[0].rawFile,
        name: args.filesData[0].name
      };
      const img = new Image();
      img.src = String(reader.result);
      img.onload = () => {
        /**
         * Procedimiento para gestionar el tamaño de la imagen y no superar el maximo
         */
        let width = img.width;
        let height = img.height;
        let MAX_WIDTH: any = 400;
        let MAX_HEIGHT: any = 240;
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        const elem = document.createElement('canvas');
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        const data = ctx.canvas.toDataURL();
        this.imagen = data;
      }
    };
  }

  public selectedImgFirma(args: UploaderComponent) {
    this.archivos = args;
    const reader = new FileReader();
    reader.readAsDataURL(args.filesData[0].rawFile as any);
    reader.onload = (_event: any) => {
      this.imageFile = {
        link: _event.target.result,
        file: args.filesData[0].rawFile,
        name: args.filesData[0].name
      };
      const img = new Image();
      img.src = String(reader.result);
      img.onload = () => {
        /**
         * Procedimiento para gestionar el tamaño de la imagen y no superar el maximo
         */
        let width = img.width;
        let height = img.height;
        let MAX_WIDTH: any = 400;
        let MAX_HEIGHT: any = 240;
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        const elem = document.createElement('canvas');
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        const data = ctx.canvas.toDataURL();
        this.imagenFirma = data;
      }
    };
  }

  public btnPegarImagen() {
    navigator.clipboard.read().then((items) => {
      for (const item of items) {
        for (let type of item.types) {
          if (type.indexOf('image') === 0) {
            item.getType('image/png').then((imageBlob) => {
              if (imageBlob !== null) {
                const fileFromBlob: File = new File([imageBlob], 'imagen.jpg');
                const reader = new FileReader();
                reader.onload = (evt: any) => {
                  const img = new Image();
                  img.src = String(reader.result);
                  img.onload = () => {
                    /**
                     * Procedimiento para gestionar el tamaño de la imagen y no superar el maximo
                     */
                    let width = img.width;
                    let height = img.height;
                    let MAX_WIDTH: any = 400;
                    let MAX_HEIGHT: any = 240;
                    if (width > height) {
                      if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                      }
                    } else {
                      if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                      }
                    }
                    const elem = document.createElement('canvas');
                    elem.width = width;
                    elem.height = height;
                    const ctx = elem.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    const data = ctx.canvas.toDataURL();
                    this.imagen = data;
                  }

                  this.imagen = String(evt.target.result);
                  this.imgRenderer.nativeElement.src = this.imagen;
                };
                reader.readAsDataURL(imageBlob);
              }
            });
          }
        }
      }
    })
      .catch(err => {
        // maybe user didn't grant access to read from clipboard
        console.log('Something went wrong', err);
      });
  }

  public btnPegarImagenFirma() {
    navigator.clipboard.read().then((items) => {
      for (const item of items) {
        for (let type of item.types) {
          if (type.indexOf('image') === 0) {
            item.getType('image/png').then((imageBlob) => {
              if (imageBlob !== null) {
                const fileFromBlob: File = new File([imageBlob], 'imagen.jpg');
                const reader = new FileReader();
                reader.onload = (evt: any) => {
                  const img = new Image();
                  img.src = String(reader.result);
                  img.onload = () => {
                    /**
                     * Procedimiento para gestionar el tamaño de la imagen y no superar el maximo
                     */
                    let width = img.width;
                    let height = img.height;
                    let MAX_WIDTH: any = 400;
                    let MAX_HEIGHT: any = 240;
                    if (width > height) {
                      if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                      }
                    } else {
                      if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                      }
                    }
                    const elem = document.createElement('canvas');
                    elem.width = width;
                    elem.height = height;
                    const ctx = elem.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    const data = ctx.canvas.toDataURL();
                    this.imagenFirma = data;
                  }

                  this.imagenFirma = String(evt.target.result);
                  this.imgFirmaRenderer.nativeElement.src = this.imagenFirma;
                };
                reader.readAsDataURL(imageBlob);
              }
            });
          }
        }
      }
    })
      .catch(err => {
        // maybe user didn't grant access to read from clipboard
        console.log('Something went wrong', err);
      });
  }

}
