<div class="breadcrumb_navbar">
    <ul class="breadcrumb">
        <li><a>{{this.breadcrumbs1}}</a></li>
        
    </ul>
</div>

<block-ui>
</block-ui>
<section>
    <app-loader></app-loader>
    <div class="card">
        <div class="card-title mb-1 p-2">
            <h5>{{descripcion}}</h5>
        </div>
        <div class="card-body">
            <form #frmParametros id="frmParametros" [formGroup]="formulario">
                <div class="grid">
                    <div *ngFor="let item of data" [ngStyle]="{'grid-column': item.tipo == 7 ? '1/4':''}">
                        <div *ngIf="item.tipo == 7">
                            <!--7 Titulo-->
                            <div style="border-bottom: 1px solid #006a97; color: #006a97;">
                                <h6>{{item.descripcion}}</h6>
                            </div>
                        </div>
                        <div *ngIf="item.tipo != 7" style="margin-top: 5px;">
                            <div class="row">
                                <div class="col-md-6 etiqueta">
                                    {{item.descripcion}}
                                </div>
                                <div class="col-md-6">
                                    <!--1 NUMERICO-->
                                    <input type="number" class="form-control" *ngIf="item.tipo == 1" required formControlName="{{item.id_frm_detalle}}" />
                                    <!--2 TEXTO-->
                                    <input class="form-control" *ngIf="item.tipo == 2 && item.renglones == 1" type="text" required formControlName="{{item.id_frm_detalle}}">
                                    <textarea *ngIf="item.tipo == 2 && item.renglones > 1" required formControlName="{{item.id_frm_detalle}}" cols="30" [rows]="item.renglones"></textarea>
                                    <!--3 FECHA-->
                                    <ejs-datepicker *ngIf="item.tipo == 3" [format]="format" formControlName="{{item.id_frm_detalle}}" required value="item.fecha">
                                    </ejs-datepicker>
                                    <!--4 CONSULTA-->
                                    <ejs-dropdownlist *ngIf="item.tipo == 4" required [dataSource]='item.data' [fields]='fields' formControlName="{{item.id_frm_detalle}}">
                                    </ejs-dropdownlist>
                                    <!--5 BUSQUEDA-->
                                    <div *ngIf="item.tipo == 5" class="input-group mb-6">
                                        <dlgBusqueda #dlgBusqueda [tablas]='item.tabla' [columnas]='item.columna' [condicion]='item.condicion' (numero)="onBusquedaSelect($event, item.id_frm_detalle)">
                                        </dlgBusqueda>
                                        <div class="input-group input-group-sm">
                                            <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" required id="id_{{item.id_frm_detalle}}" readonly>
                                            <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" required formControlName="{{item.id_frm_detalle}}" hidden="true">
                                            <div class="input-group-append">
                                                <button class="btn btn-buscar-cuadrado" (click)="dlgBusqueda.show()"></button>
                                            </div>
                                        </div>
                                    </div>
                                    <!--6 MULTICONSULTA-->
                                    <ejs-combobox *ngIf="item.tipo == 6" [dataSource]='item.data' [showCheckBox]='true' [fields]="fields" required formControlName="{{item.id_frm_detalle}}">
                                    </ejs-combobox>
                                    <!--8 BUSQUEDA DE IDs-->
                                    <div *ngIf="item.tipo == 8" name="{{item.variable}}" class="input-group mb-6">
                                        <div class="input-group-prepend">
                                            <button class="btn btn-outline-success">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                        <input type="text" #tfIds readonly required formControlName="{{item.id_frm_detalle}}">
                                    </div>
                                    <!--9 Imagen-->
                                    <div *ngIf="item.tipo == 9">
                                        <div style="display: grid;">
                                            <button class="btn btn-buscar-cuadrado" (click)="btnImagen(item.id_frm_detalle)">
                                                <i class="far fa-file-image"></i> Subir imagen
                                            </button>
                                            <input type="hidden" required formControlName="{{item.id_frm_detalle}}">
                                            <img [src]="domSanitizer.bypassSecurityTrustUrl(this.formulario.controls[item.id_frm_detalle].value)" style="max-width: 300px;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="footer">
                <button class="btn btn-guardar" (click)="btnGuardar()"> {{"Boton.Guardar" | translate}}</button>
            </div>
        </div>
    </div>
</section>

<ejs-dialog #dlgimagenPregunta header='Imagen Pregunta' width='400px' isModal='true' [visible]='false' showCloseIcon='true'>
    <ng-template #header>
        <div id="dlg-template" class="e-icon-settings"><i class="fas fa-dice-d6"></i> Imagen</div>
    </ng-template>
    <ng-template #content>
        <div class="custom-file">
            <input #archivo type="file" class="custom-file-input" id="imagen" (change)="imagesPreview($event)">
            <label class="custom-file-label" for="imagen">Seleccionar Archivo</label>
        </div>
        <div style="text-align: center;">
            <img class="preview-image" [src]="imageFile ? imageFile.link : '/assets/img/favicon.png'" style="max-width: 350px;">
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <button class="btn btn-success" (click)="btnImportar()">
            <i class="far fa-file-image"></i> Importar
        </button>
    </ng-template>
</ejs-dialog>
