<nav class="navbar navbar-expand-lg">
	<div class="container-fluid">
		<div style="display: flex;">
			<a class="navbar-brand m-0 " href="#" routerLink="/tablero/tablero2">
				<img src="./assets/img/logo-icon.png" width="35px">
				<span class="ml-1">Arcarius ERP</span>
			</a>
			<a class="circulo buger" (click)="openTop(contentMenu)" title="Menu de Sistema">
				<i class="fa-solid fa-bars"></i>
			</a>
		</div>
		<!-- <div style="display: flex;">
            <a class="circulo" (click)="logout()" title="Salir de App">
                <i class="fa fa-sign-out" aria-hidden="true"></i>
            </a>
        </div> -->
	</div>
</nav>


<!-- Menu Principal o de Primer Nivel-->
<nav id="menu-container" class="arrow" *ngIf="this.menuDeslizante">
	<div id="btn-nav-previous">
		<i class="fa-solid fa-circle-arrow-left"></i>
	</div>
	<div id="btn-nav-next">
		<i class="fa-solid fa-circle-arrow-right"></i>
	</div>
	<div class="menu-inner-box">
		<div class="menu">
			<a [ngClass]="item.id == this.id_menu ? 'menu-item-activo' : 'menu-item'" *ngFor="let item of this.dataMenu"
				(click)="this.getMenuSub(item.id)">
				<img src="./assets/iconos/{{item.imagen}}.png" /> {{item.descripcion}}
			</a>
			<a class="menu-item"></a>
			<a class="menu-item"></a>
			<a class="menu-item"></a>
		</div>
	</div>
</nav>


<!-- Sub Menu o de Segundo Nivel-->
<nav id="menu2-container" class="arrow" style="background-color: #9e9e9e;" *ngIf="this.menuDeslizante">
	<div id="btn-nav2-previous" style="background-color: #9e9e9e;">
		<i class="fa-solid fa-circle-arrow-left"></i>
	</div>
	<div id="btn-nav2-next" style="background-color: #9e9e9e;">
		<i class="fa-solid fa-circle-arrow-right"></i>
	</div>
	<div class="menu2-inner-box">
		<div class="menu2">
			<a [ngClass]="itemSub.id == this.id_menu_sub ? 'menu2-item-activo' : 'menu2-item'"
				*ngFor="let itemSub of this.dataMenuSub" [routerLink]="[itemSub.url]"
				(click)="this.setMenuSub(itemSub.id)">
				<img src="./assets/iconos/{{itemSub.imagen}}.png" />{{itemSub.descripcion}}
			</a>
			<a class="menu2-item"></a>
			<a class="menu2-item"></a>
			<a class="menu2-item"></a>
		</div>
	</div>
</nav>

<!-- Panel izquierdo con comentarios del usuario -->
<ng-template #contentComentario let-offcanvas>
	<div class="offcanvas-header">
		<button class="btn-close" aria-label="Close" (click)="offcanvas.dismiss()"></button>
	</div>
	<div class="offcanvas-body">
		<div style="overflow-y: scroll; height: calc(100vh - 150px);">
			<div class="list-group">
				<div class="list-group-item list-group-item-action" aria-current="true"
					*ngFor="let item of this.dataPorHacer"
					[ngStyle]="{'border-left': '5px', 'border-left-color': item.color, 'border-left-style': 'solid'}">
					<div class="d-flex w-100 justify-content-between">
						<div>
							<p style="font-weight: bold;">{{item.descripcion}}</p>
						</div>
						<div style="min-width: 100px; text-align: center;">
							<small>{{item.fecha}}</small>
							<div class="sidebar-etiqueta"
								[ngStyle]="{'background-color': item.color, 'color': item.texto_color}">
								{{item.estatus}}
							</div>
						</div>
					</div>
					<div class="mb-1" [innerHtml]="item.texto"></div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!-- Panel izquierdo configuracion del usuario -->
<ng-template #contentConfigura let-offcanvas>
	<div class="offcanvas-header">
		Configuraciones individuales por usuario
		<button class="btn-close" aria-label="Close" (click)="offcanvas.dismiss()"></button>
	</div>
	<div class="offcanvas-body">
		<div>
			<ul class="list-group">
				<li class="list-group-item" *ngFor="let configuracion of dataConfiguracion">
					<div class="form-check form-switch">
						<input class="form-check-input" type="checkbox" role="switch"
							[id]="'configuracion' + configuracion.id_rh_configura" [checked]="configuracion.activado"
							(change)="chkConfiguracion($event, configuracion)">
						<label class="form-check-label" [for]="'configuracion' + configuracion.id_rh_configura">
							{{configuracion.descripcion}}
						</label>
					</div>
				</li>
			</ul>
		</div>
	</div>
</ng-template>

<!-- Panel superior para mostrar el menu -->
<ng-template #contentMenu let-offcanvas>
	<div class="offcanvas-header">
		<div class="row" style="width: -webkit-fill-available;">
			<div class="col-md-4 justify-content-center" style="border-right: 1px solid #a1a1a1;">
				<div class="profile-details text-center">
					<p class="mb-0 text-uppercase" style="font-weight: bold;">{{usuario.empresa}}</p>
				</div>
				<div class="profile-details text-center">
					{{usuario.nombre}} {{usuario.paterno}} {{usuario.materno}}
					<br />
					<small class="text-uppercase role">{{usuario.departamento}}</small>
				</div>
			</div>
			<div class="col-md-8">
				<!--
                    Menu de inicial donde se carga tablero inicial con base en el siguiente registro
                    Tabla: rh_empleado
                    Columna : id_rpt_tablero_detalle
                -->
				<a class="circulo" routerLink="/tablero/tablero2" title="Inicio" (click)="offcanvas.dismiss()">
					<i class="fa-solid fa-house-user"></i>
				</a>

				<!--
                    Boton para carga de tablero asignados al usuario
                    Tabla: rpt_tablero_empleado
                -->
				<a class="circulo" routerLink="/tablero/tablero1" title="Tableros" (click)="offcanvas.dismiss()">
					<i class="fa-solid fa-chart-pie"></i>
				</a>
				<a class="circulo" [routerLink]="['/escritorio', 'perfil']" (click)="offcanvas.dismiss()">
					<i class="fa-solid fa-user"></i>
				</a>
				<a class="circulo" (click)="openEnd(contentConfigura)">
					<i class="fa-solid fa-gear"></i>
				</a>
				<a class="circulo" (click)="openEnd(contentComentario)">
					<i class="fa-regular fa-comment"></i>
				</a>

				<a class="circulo" [routerLink]="['/rmgestion', 'calendario']" title="Calendario"
					(click)="offcanvas.dismiss()">
					<i class="far fa-calendar-alt"></i>
				</a>

				<a class="circulo" [routerLink]="['/escritorio', 'correo']" title="Correo">
					<i class="fa-regular fa-envelope"></i>
				</a>

				<a class="circulo" [routerLink]="['/escritorio', 'notificaciones']" title="Avisos"
					(click)="offcanvas.dismiss()">
					<i class="far fa-bell"></i>
					<div *ngIf="!mensajes" class="position-relative">
						<span
							class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{numero_notificaciones}}</span>
					</div>
				</a>

				<a class="circulo logout" title="Salir" (click)="logout()">
					<i class="fa fa-sign-out"></i>
				</a>
			</div>
		</div>

		<button class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>

	</div>
	<div class="offcanvas-body">
		<ul style="list-style-type: none;">
			<li *ngFor="let menu1 of this.dataMenuTradicional">
				<div class="row" style="font-size: 1.5rem;">
					<img *ngIf="menu1.image" style="width: 16px;" src="./assets/iconos/{{menu1.image}}.png" />&nbsp;
					{{menu1.text}}
				</div>
				<div class="row">
					<div class="col-12 col-lg-2 col-md-3 col-sm-4 grupo-subpanel" *ngFor="let menu2 of menu1.items">
						<a class="grupo-item" [routerLink]="[menu2.url]" *ngIf="menu2.url != ''"
							(click)="offcanvas.dismiss()">
							<img *ngIf="menu2.image" src="./assets/iconos/{{menu2.image}}.png" /> {{menu2.text}}
						</a>
						<div *ngIf="menu2.url == ''">
							<img *ngIf="menu2.image" src="./assets/iconos/{{menu2.image}}.png" />
							<span class="subTitulo">{{menu2.text}}</span>
						</div>
						<div class="list-group" *ngIf="menu2.url == ''">
							<a class="grupo-item" [routerLink]="[menu3.url]" *ngFor="let menu3 of menu2.items"
								(click)="offcanvas.dismiss()">
								<img *ngIf="menu3.image" src="./assets/iconos/{{menu3.image}}.png" /> {{menu3.text}}
							</a>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
	<div
		style="font-family: sans-serif;background-color: #212020; color: #fff; border-top: 1px solid #a1a1a1; height: 45px !important; text-align: right; padding-right: 10px; font-style: oblique;">
		<h4>Arcarius ERP</h4>
	</div>
</ng-template>

<!-- Bloque para mostrar las notificaciones -->
<div style="position: fixed; right: 0px;">
	<ngb-toast *ngFor="let toast of alertaService.toasts" [autohide]="true" [delay]="toast.delay || 60000"
		(hidden)="alertaService.remove(toast)">
		<ng-template ngbToastHeader>
			<div class="me-auto">
				<i class="fa-regular fa-message"></i>
				<strong class="mx-1">Informacion</strong> de Notificaciones
			</div>

		</ng-template>
		<ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
			<ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
		</ng-template>
		<ng-template #text>
			{{toast.modulo}}
			<div [innerHTML]="toast.textOrTpl | safeHtml"></div>
			<button *ngIf="toast.modulo == 'Mensaje'" class="btn btn-aceptar" data-bs-dismiss="toast"
				(click)="btnMensajeMarcado(toast.id)">Leido</button>
			<button *ngIf="toast.modulo == 'Calendario'" class="btn btn-aceptar" data-bs-dismiss="toast"
				(click)="setTerminado(toast.id)">Terminado</button>
		</ng-template>
	</ngb-toast>
</div>
<!-- Termina Bloque para mostrar las notificaciones -->