import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { UsuarioService } from './usuario.service';
import { LoaderService } from '../components/loader/services/loader.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    private usuarioService: UsuarioService

    constructor(
        usuarioService: UsuarioService,
        private loadingService: LoaderService
    ) {
        this.usuarioService = usuarioService;
    }

    /**
     * Interceptor de comunicacion con solicitudes http, donde podemos identificar cuando se hacen
     * peticiones a la plataforma API.
     * 
     * Se agrega funcionalidad de un cargador (loader), para generar una animacion dentro de la app
     * y comunicar al usuario que se esta trabajando en su solicitud.
     * 
     * @param req 
     * @param next 
     * @returns 
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request.url.includes('carganotificacion')) {
            this.loadingService.showLoader();
        }
        const token: string = localStorage.getItem('token');
        if (token) {
            if (request.url.includes('https://graph.facebook.com')) {
                request = request.clone({
                    setHeaders: {
                        authorization: 'Bearer EAAFADaKmZAxEBO82SXvbZAr8NJGbQSRZC92cbFFFlLkJsMmz7nycZAzPsNIoeH1fImLNW5iirUxh7jJa3TspMVSDmfWeICMcWraaGQ0uSQbyUGO8iBDZAwNFZBrjWaYsuHrS6mdU7I2QLg1b6sFXhzRQITpwjXZCaZBDXJXN6nFR0YGf6IkdKwS9jGUW1ZAesAWwGVlLxyPTTroqzVlmE'
                    }
                });
            } else if (request.url.includes('https://lookaside.fbsbx.com/whatsapp_business/attachments')) {
                // Bloque para autorizacion de descarga de archivos de whatsapp
                request = request.clone({
                    setHeaders: {
                        authorization: 'Bearer EAAFADaKmZAxEBO82SXvbZAr8NJGbQSRZC92cbFFFlLkJsMmz7nycZAzPsNIoeH1fImLNW5iirUxh7jJa3TspMVSDmfWeICMcWraaGQ0uSQbyUGO8iBDZAwNFZBrjWaYsuHrS6mdU7I2QLg1b6sFXhzRQITpwjXZCaZBDXJXN6nFR0YGf6IkdKwS9jGUW1ZAesAWwGVlLxyPTTroqzVlmE',
                        //'Access-Control-Allow-Origin': '*',
                        //'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                        //'Access-Control-Allow-Methods': 'GET'
                    }
                });
            } else {
                request = request.clone({
                    setHeaders: {
                        authorization: `${token}`
                    }
                });
            }
        } else {
            this.usuarioService.logout();
        }

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401 || err.status === 403) {
                    this.usuarioService.logout();
                }
                return throwError(err);
            }),
            finalize(() => {
                if (!request.url.includes('carganotificacion')) {
                    this.loadingService.hideLoader();
                }
            })
        );
    }
}
