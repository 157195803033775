<div class="breadcrumb_navbar">
    <ul class="breadcrumb">
        <li><a>{{this.breadcrumbs1}}</a></li>
        
    </ul>
</div>

<block-ui>
</block-ui>
<section>
    <app-loader></app-loader>
    <div class="card">
        <div class="card-body">
            <form [formGroup]="Listadoformulario">
                <div class="row" style="display: flex;">
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">Fecha Inicial</label>
                            <ejs-datepicker placeholder='' formControlName="fechainicial" [format]='formatoFecha'>
                            </ejs-datepicker>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">Fecha Final</label>
                            <ejs-datepicker placeholder='' formControlName="fechafinal" [format]='formatoFecha'>
                            </ejs-datepicker>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">Tipo</label>
                            <ejs-dropdownlist [dataSource]='data_formulario' [fields]='fields'
                                placeholder='Seleccionar formulario' formControlName="id_frm_encabezado_DropList">
                            </ejs-dropdownlist>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">Referencia</label>
                            <div class="input-group">
                                <input type="text" #referencia autofocus class="form-control"
                                    (keydown.enter)="btnBuscar()">
                                <div class="input-group-append">
                                    <button class="btn btn-buscar-cuadrado" (click)="btnBuscar()"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <button class="btn btn-buscar" (click)="btnBuscar()"> {{"Boton.Buscar" | translate}}</button>
                    <button class="btn btn-nuevo btn-derecha" (click)="btnEjecutaFormulario()"> {{"Boton.Agregar" | translate}}</button>
                    <button class="btn btn-derecha btn-basico" (click)="btnArchivos()"><i
                            class="fa-solid fa-file-arrow-down"></i> Importar </button>
                    <button class="btn btn-derecha btn-basico" (click)="btnCorreo()"><i
                            class="fa-solid fa-envelopes-bulk"></i> {{"Boton.Correo" | translate}}</button>
                </div>
            </form>
        </div>
        <div class="card-detail">
            <div class="table-responsive" style="height: calc(100vh - 245px);">
                <table *ngIf="data" class="table table-hover table-bordered">
                    <thead>
                        <th><input type="checkbox" class="seleccion" [checked]="isAllCheckBoxChecked()"
                                (change)="checkAllCheckBox($event)">
                        </th>
                        <th></th>
                        <ng-template #template ngFor let-column [ngForOf]="columns">
                            <div
                                *ngIf="column.nombre.startsWith('id') || column.nombre.startsWith('estatus_color'); then thenBlock else elseBlock">
                            </div>
                            <ng-template #thenBlock>
                                <!--No se imprime-->
                            </ng-template>
                            <ng-template #elseBlock>
                                <th style="text-transform: capitalize;" (sort)="onSort($event)"
                                    [ngStyle]="column.nombre.startsWith('fecha') && { 'min-width': '90px' }">
                                    {{column.nombre}}
                                </th>
                            </ng-template>
                        </ng-template>
                    </thead>
                    <tbody>
                        <tr *ngFor='let item of data; let i=index'>
                            <td><input type="checkbox" class="seleccion" value="{{this.items.id}}"
                                    [(ngModel)]="this.items[i].seleccionado">
                            </td>
                            <td style="width: 100px;">
                                <div style="display: inline-flex;">
                                    <button class="btn btn-editar-sm" title="Modificar Formulario"
                                        (click)="btnAbrirFrm(item, content)"></button>
                                    <button class="btn btn-borrar-sm" title="Eliminar"
                                        (click)="btnBorrar(item.id)"></button>
                                    <button class="btn btn-mensaje-sm" title="Comentarios"
                                        (click)="btnComentario(item.id)"></button>
                                    <button class="btn btn-txt" title="Exportar"
                                        (click)="btnExportar(item.id, item.folio)"></button>
                                </div>
                            </td>
                            <ng-template #template ngFor let-column [ngForOf]="columns">
                                <div
                                    *ngIf="column.nombre.startsWith('id') || column.nombre.startsWith('estatus_color'); then thenBlock else elseBlock">
                                </div>
                                <ng-template #thenBlock>
                                    <!--Sin imprimir-->
                                </ng-template>
                                <ng-template #elseBlock>
                                    <td>
                                        <div [ngSwitch]="column.nombre">
                                            <div *ngSwitchCase="'descripcion'">
                                                <div [innerHTML]="item[column.nombre] | safeHtml"></div>
                                            </div>
                                            <div *ngSwitchCase="'estatus'">
                                                <div class="estatus" [innerHTML]="item[column.nombre] | safeHtml"
                                                    [ngStyle]="{'background-color': item.estatus_color, 'color': item.texto_color}">
                                                </div>
                                            </div>
                                            <div *ngSwitchDefault>
                                                <div *ngIf="column.tipo == 'numeric'"
                                                    [innerHTML]="item[column.nombre] | number: '.2-2'"
                                                    [ngStyle]="{'text-align': 'right'}"></div>
                                                <div *ngIf="column.tipo !== 'numeric'"
                                                    [innerHTML]="item[column.nombre] | safeHtml"
                                                    [ngStyle]="{'text-align': 'left'}">
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </ng-template>
                            </ng-template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>

<!-- INICIO DIALOGO DE COMENTARIOS-->
<ejs-dialog #dlgComentario header='Detalles del comentario' [isModal]='true' height='75%' [visible]='false'
    showCloseIcon='true' width='800px'>
    <div class="dialogContent">
        <div class="container">
            <ul class="timeline">
                <li *ngFor="let item of dataComentarios">
                    <div class="timeline-time">
                        <span class="time">{{item.fecha}}</span>
                    </div>
                    <div class="timeline-icon">
                        <div></div>
                    </div>
                    <div class="timeline-body">
                        <div class="timeline-header">
                            <span class="userimage"><img src="assets\iconos\persona32.png" alt=""></span>
                            <span class="username"><a href="javascript:;">{{item.nombre}}</a>
                                <small></small></span>
                        </div>
                        <div class="timeline-content" [innerHtml]="item.comentario | safeHtml">
                        </div>
                    </div>
                </li>

            </ul>
        </div>
    </div>
    <form [formGroup]="frmComentario" autocomplete="off">
        <div class="row">
            <label for="tfComentario">Agregar un Comentario</label>
            <ejs-richtexteditor id='tfComentario' formControlName="comentario" height="150px">
            </ejs-richtexteditor>
        </div>
    </form>
    <div class="footer">
        <button class="btn btn-success" (click)="btnGuardarComentario()">
            <i class="far fa-save"></i> Guardar
        </button>
    </div>
</ejs-dialog>
<!-- FINAL DIALOGO DE COMENTARIOS-->

<!-- INICIO DIALOGO DEL NUEVO FORMULARIO-->
<ejs-dialog #dlgEjecutaFormulario header='Formularios' [isModal]='true' [visible]='false' showCloseIcon='true'
    width='500px'>
    <div class="dialogContent">
        <ejs-grid [dataSource]='dataEjecuta' width='auto'>
            <e-columns>
                <e-column width="60">
                    <ng-template #template let-item>
                        <div style="display: inline-flex;">
                            <button class="btn btn-editar-sm" title="Ejecutar Formulario"
                                (click)="btnAbrir(item.id)"></button>
                        </div>
                    </ng-template>
                </e-column>
                <e-column field='descripcion'>
                </e-column>
            </e-columns>
        </ejs-grid>
    </div>
</ejs-dialog>
<!-- FINAL DIALOGO DEL NUEVO FORMULARIO-->

<dlgCorreoFormulario #dglCorreo [ids]="this.ids"></dlgCorreoFormulario>
