import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Tablero2Resolver implements Resolve<boolean> {
  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
    // ====================================================================================
    // Cargamos los parametros proporcionados para el tablero, en caso de no existir
    // cargamos el tablero por default del usuario autenticado. rh_empleado.id_rpt_tablero_detalle
    // ====================================================================================
    if (route.queryParams['id'] === undefined) {
      const parametro: HttpParams = new HttpParams()
        .append('id', (localStorage.getItem('usuario') ? JSON.parse(localStorage.getItem('usuario')).id_rpt_tablero_detalle : '-1'))
        .append('id2', '-1');
      return this.http.get<any>(`${environment.base_url}/tablero/tablero2`, { params: parametro });
    } else {
      const parametro: HttpParams = new HttpParams()
        .append('id', route.queryParams['id'])
        .append('id2', route.queryParams['id2']);
      return this.http.get<any>(`${environment.base_url}/tablero/tablero2`, { params: parametro });
    }
  }
}
