import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.css']
})
export class NotificacionComponent implements OnInit {
  public titulo: string = "Listado de Tareas para realizar";
  public data: any = [];

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.carga();
  }

  carga() {
    const base_url = environment.base_url;
    this.http.get<any>(`${base_url}/organizacion/notificacion/listado`).subscribe(
      respuesta => {
        this.data = respuesta;
        console.log(this.data);
      }
    );
  }

}
