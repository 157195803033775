import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ToolbarService } from '@syncfusion/ej2-angular-grids';
import { FileManagerService, HtmlEditorService, ImageService, LinkService, QuickToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from '../dlgMensajeError/dlgMensajeError.component';
import { dlgMensaje } from '../dlgMensaje/dlgMensaje.component';
import Quill from 'quill'
import 'quill-emoji/dist/quill-emoji.js';
import BlotFormatter from 'quill-blot-formatter';
import { MdlCorreo } from './models/MdlCorreo.model';
import { CorreoService } from './services/correo.service';
Quill.register('modules/blotFormatter', BlotFormatter);

@Component({
    selector: 'dlgcorreo',
    templateUrl: './dlgcorreo.component.html',
    styleUrls: ['./dlgcorreo.component.scss'],
    providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService, FileManagerService]
})
export class DlgcorreoComponent implements OnInit {

    @Input('id') id: number = -1;
    @Input('ids') ids: string = '';
    @Input('modulo') modulo: string = '';
    @Input('informacion') informacion: any;
    @Output('respuesta') respuesta = new EventEmitter<any>();
    public trabajando : boolean = false;

    public formulario: UntypedFormGroup;
    public modules = {
        'emoji-shortname': true,
        'emoji-textarea': false,
        'emoji-toolbar': true,
        blotFormatter: {},
        toolbar: {
			container: [
				['bold', 'italic', 'underline', 'strike'], // toggled buttons
				['blockquote', 'code-block'],
				[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
				[{ list: 'ordered' }, { list: 'bullet' }],
				['link', 'image', 'video'],
				[{ color: [] }, { background: [] }], // dropdown with defaults from theme
				['clean'], // remove formatting button
				['emoji'],
			],
			handlers: { emoji: function () { } },
		},
    };

    constructor(
        private modalService: NgbModal,
        private fb: UntypedFormBuilder,
        private servicio: CorreoService
    ) { }

    ngOnInit(): void {
        if (this.informacion) {
            this.formulario = this.fb.group(new MdlCorreo);
            this.formulario.controls['mensaje'].setValue(this.informacion.mensaje);
            this.formulario.controls['para'].setValue(this.informacion.correo);
            this.formulario.controls['asunto'].setValue(this.informacion.asunto);
            this.formulario.controls['id'].setValue(this.id);
        } else {
            this.formulario = this.fb.group(new MdlCorreo);
        }
    }

    ngAfterViewInit() {
    }

    /**
     * Metodos para las validaciones de Nuevoformulario
     */
    get paraNoValida() {
        return (
            this.formulario.get('para').invalid &&
            this.formulario.get('para').touched
        );
    }
    get asuntoNoValida() {
        return (
            this.formulario.get('asunto').invalid &&
            this.formulario.get('asunto').touched
        );
    }
    get mensajeNoValida() {
        return (
            this.formulario.get('mensaje').invalid &&
            this.formulario.get('mensaje').touched
        );
    }

    public revisa() {
        if (this.formulario.invalid) {
            return Object.values(this.formulario.controls).forEach((control) => {
                control.markAsTouched();
            });
        }
    }

    /**
     * Metodo para el envio de correos
     * @returns
     */
    public enviarCorreo() {
        this.revisa();
        if (!this.formulario.invalid) {
            this.trabajando = true;
            this.servicio.setCorreo(this.modulo, this.formulario.value).subscribe(resp => {
                if (resp.numero > 0) {
                    this.close();
                    const modalRef = this.modalService.open(dlgMensaje);
                    modalRef.componentInstance.encabezado = resp.titulo;
                    modalRef.componentInstance.contenido = resp.mensaje;
                    this.trabajando = false;
                } else {
                    const modalRef = this.modalService.open(dlgMensajeError);
                    modalRef.componentInstance.encabezado = resp.titulo;
                    modalRef.componentInstance.contenido = resp.mensaje;
                    modalRef.componentInstance.error = resp.error;
                    this.trabajando = false;
                }
            });
        } else {
            this.trabajando = false;
        }
    }

    /**
   * Metodo para cerrar la ventana modal
   */
    public close() {
        this.respuesta.emit(
            {
              id: 1,
              descripcion: this.formulario.controls['asunto'].value
            }
          );
        this.modalService.dismissAll();
    }

}
