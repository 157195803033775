import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MdlDocumento } from '../Models/MdlDocumento';
import { MdlDocumentoDetalle } from '../Models/MdlDocumentoDetalle';
import { MdllPlanificaEstatus } from '../Models/MdllPlanificaEstatus';
import { MdlPlanificaRelacion } from '../Models/MdlPlanificaRelacion';

@Injectable({
    providedIn: 'root'
})
export class DocumentoService {

    constructor(
        private http: HttpClient
    ) { }

    public GetTable(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/documento/frmDocLog/${id}`);
    }

    /**
     * Insventa_cartaporte: este metodo crea una carta porte desde una factura
     * carga
     *
     * almacenamos los datos del Carta Porte en log_planifica_relacion;
     *
     * @param request
     * @return
     
    */
    public Insventa_cartaporte(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/documento/Insventa_cartaporte/${id}`);
    }

    public Guardar(modelo: MdlDocumento) {
        return this.http.post(`${environment.base_url}/logistica/documento/grdDoc`, modelo);
    }
    public grdPlanificaEstatus(modelo: MdllPlanificaEstatus) {
        return this.http.post(`${environment.base_url}/logistica/documento/grdPlanificaEstatus`, modelo);
    }

    public grdItem(modelo: MdlDocumentoDetalle): Observable<any> {
        return this.http.post(`${environment.base_url}/logistica/documento/grdDocDetalle`, modelo);
    }

    public InsertDocumento(modelo: MdlPlanificaRelacion) {
        return this.http.post(`${environment.base_url}/logistica/documento/InsertarDocumentoVenta`, modelo);
    }

    // eliminacion
    // public Borrar(id: number): Observable<any> {
    //   return this.http.post<any>(`${environment.base_url}/rrhh/dispositivo/borrarDispositivo/${id}`, {})
    //     .pipe(
    //       catchError(error => {
    //         return throwError(this.errores.getErrores(error));
    //       })
    //     );
    // }

    public lstClienteDomicilio(id: number): Observable<any> {
        return this.http.get(`${environment.base_url}/logistica/clienteDomicilio/${id}`);
    }

    public cargaActivos(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/documento/cargaActivos/${id}`);
    }

    public insertarActivo(id_log: any, ids: string): Observable<any> | any {
        const parametro: HttpParams = new HttpParams()
            .append('id_log', id_log)
            .append('ids', ids);
        console.log('parametro', parametro);
        return this.http.post<any>(`${environment.base_url}/logistica/documento/insertarActivo`, parametro);
    }

    public borrarActivo(ids: any): Observable<any> | any {
        const parametro: HttpParams = new HttpParams()
            .append('ids', ids);
        console.log('parametro', parametro);
        return this.http.post<any>(`${environment.base_url}/logistica/documento/borrarActivo`, parametro);
    }

    public cargaEmpleado(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/documento/cargaEmpleado/${id}`);
    }

    public insertarEmpleado(id_log: any, ids: string): Observable<any> | any {
        const parametro: HttpParams = new HttpParams()
            .append('id_log', id_log)
            .append('ids', ids);
        console.log('parametro', parametro);
        return this.http.post<any>(`${environment.base_url}/logistica/documento/insertarEmpleado`, parametro);
    }

    public borrarEmpleado(ids: any): Observable<any> | any {
        const parametro: HttpParams = new HttpParams()
            .append('ids', ids);
        return this.http.post<any>(`${environment.base_url}/logistica/documento/borrarEmpleado`, parametro);
    }
    /**
     * CATALOGOS DEL SAT 
     */

    public lstSATTipoCarro(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATTipoCarro`);
    }
    public lstSATConfTrasporte(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATConfTrasporte`);
    }
    public lstSatTipoPermiso(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSatTipoPermiso`);
    }
    public lstSatTipoRemolque(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSatTipoRemolque`);
    }
    public lstSATPuertoEstacion(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATPuertoEstacion`);
    }
    public lstCombustible(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstCombustible`);
    }
    public lstSATTipoServicio(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATTipoServicio`);
    }
    public lstSATTipoCarga(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATTipoCarga`);
    }
    public lstSATFiguraTransporte(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATFiguraTransporte`);
    }
    public lstSATParteTransporte(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATParteTransporte`);
    }

    public lstSATTipoEstacion(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATTipoEstacion`);
    }
    public lstSATCveTransporte(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATCveTransporte`);
    }
    public lstSATTrafico(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATTrafico`);
    }
    public lstSATContenedor(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATContenedor`);
    }
    public lstSATCodigoTransporteAereo(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATCodigoTransporteAereo`);
    }
    public lstSATContenedorMaritimo(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstSATContenedorMaritimo`);
    }

    public lstTipoRelacion(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstTipoRelacion`);
    }
    /***
     * dialogos de busqueda 
     */
    public buscarSatPuertoEstacion(referencia: string): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('referencia', referencia);
        return this.http.get<any>(`${environment.base_url}/logistica/buscarSatPuertoEstacion`, {
            params: parametros,
        });
    }
    public buscarSATMaterialPeligroso(referencia: string): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('referencia', referencia);
        return this.http.get<any>(`${environment.base_url}/logistica/buscarSATMaterialPeligroso`, {
            params: parametros,
        });
    }
    public buscarSatTipoEmbalaje(referencia: string): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('referencia', referencia);
        return this.http.get<any>(`${environment.base_url}/logistica/buscarSatTipoEmbalaje`, {
            params: parametros,
        });
    }
    public buscarSatDerechosPaso(referencia: string): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('referencia', referencia);
        return this.http.get<any>(`${environment.base_url}/logistica/buscarSatDerechosPaso`, {
            params: parametros,
        });
    }

    // listado detalle
    public lstMoneda(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstMoneda`);
    }

    public lstProductoUnidad(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/logistica/lstProductoUnidad`);
    }

    // tabla de detalle
    public TbDocDetalle(id: number): Observable<any> {
        return this.http.get(`${environment.base_url}/logistica/documento/TbDocDetalle/${id}`);
    }
    // tabla Relaciones de documentos facturas
    public TbLogRelaciones(id: number): Observable<any> {
        return this.http.get(`${environment.base_url}/logistica/documento/TbLogRelaciones/${id}`);
    }

    public BorrarItem(id: number): Observable<any> {
        return this.http.post<any>(`${environment.base_url}/logistica/documento/BorrarItem/${id}`, {});
    }

    public BorrarRelacion(id: number): Observable<any> {
        return this.http.post<any>(`${environment.base_url}/logistica/documento/BorrarRelacion/${id}`, {});
    }

    public lstEstatus(): Observable<any> {
        return this.http.get(`${environment.base_url}/logistica/documento/lstEstatus`);
    }

    public ActualizarEstatus(id: number, id_log_planifica: number): Observable<any> {
        return this.http.get(`${environment.base_url}/logistica/documento/ActualizarEstatus/${id}/${id_log_planifica}`, {});
    }

    // busqueda por proyecto id y descipcion
    public timbrar(id: number): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('id', String(id));
        return this.http.post(`${environment.base_url}/logistica/documento/timbrar`, parametros);
    }


    public timbrarCancela(id: any): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('id', id);
        return this.http.get<any>(`${environment.base_url}/logistica/documento/timbrarCancela`, { params: parametros, });
    }

    public enviarCorreo(
        mensaje: any,
        para: any,
        copia: any,
        copiaoculta: any,
        encabezado: any,
    ): Observable<any> {
        const parametros: HttpParams = new HttpParams()
            .append('mensaje', mensaje)
            .append('para', para)
            .append('copia', copia)
            .append('copiaoculta', copiaoculta)
            .append('encabezado', encabezado);
        return this.http.get<any>(`${environment.base_url}/correo/enviarcorreodlg`, { params: parametros });
    }

    //****************************************************************** */
    //Metodo _id_estatus: Asiganamos el valor a _id_estatus si este tiene
    //                    un id antecesor para agregar el estatus 3    
    public _id_estatus(): Observable<any> {
        return this.http.get(`${environment.base_url}/logistica/documento/_id_estatus`);
    }

    //****************************************************************** */
    //Metodo _id_estatus_requerido: realizamos la consulta para mostrar en cadena
    // de texto para que el usuario observe el antecesor de cancelado en este caso 
    public _id_estatus_requerido(): Observable<any> {
        return this.http.get(`${environment.base_url}/logistica/documento/_id_estatus_requerido`);
    }

    //****************************************************************** */
    //@method DlgDocTipoCausa: realizamos la consulta para mostrar un listado
    // este listado mostrará las opciones de cancelasciones 
    // cuando esten agregadas en configuracion de mov.
    // * 01 Comprobante emitidocon errores con relación 
    // * 02 Comprobante emitido con errores sin relación
    // * 03 No se llevó a cabo la operación 
    // * 04 Operación nominativa relacionada en la factura global 
    public DlgDocTipoCausa(): Observable<any> {
        return this.http.get(`${environment.base_url}/logistica/documento/DlgDocTipoCausa`);
    }
}
