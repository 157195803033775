import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ErroresService } from 'src/app/shared/errores.service';
import { environment } from 'src/environments/environment';
import { MdlComentarios } from '../models/MdlComentarios';
import { MdlFormulario } from '../models/MdlFormulario';
import { MdlOpcionesAgregar } from '../models/MdlOpcionesAgregar';
import { MdlParametros } from '../models/MdlParametros';
import { MdlProyectos } from '../models/MdlProyectos';

@Injectable({
    providedIn: 'root',
})
export class GccalidadService {
    constructor(
        private http: HttpClient,
        private errores: ErroresService
    ) { }

    /**
     * Metodo para enviar a ejecutar una funcion a la base de datos.
     * Esto da la oportunidad de crear funciones dentro de la DB con determinada logica
     * para posteriormente ser solo ejecutadas desde nuestros procesos.
     * Por ejemplo, una funcion paa obtener el tipo de cambio del dia, ya que solo
     * se requiere conocer esta valor
     * 
     * @param _funcion 
     * @returns 
     */
    public getFuncion(_funcion: string): Observable<any> {
        let parametros: HttpParams = new HttpParams().append('funcion', _funcion);
        return this.http.get<any>(`${environment.base_url}/publico/funciones/get`, { params: parametros });
    }

    /**
     * Metodo para el listado de documentos.
     * @param id ID del archivo a descargar
     * @param id2 ID del proyecto al que pertenece
     * @param id3 ID del tipo de documento
     *
     * @return Información en formato JSON
     **/
    public lstDescarga(id: number, id2: number, id3: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/listadodescarga/${id}/${id2}/${id3}`);
    }
    /**
     * Metodo para la descarga de documentos.
     * @param id ID del archivo a descargar
     *
     * @return Información en formato JSON
     **/
    public getDescarga(id: number): Observable<any> {
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
        return this.http.get<any>(`${environment.base_url}/gccalidad/descargas/descarga/${id}`, { responseType: resonseType, headers: headers });
    }
    /**
   * Metodo para la descarga de archivos.
   * @param id ID del archivo a descargar
   *
   * @return Información en formato JSON
   **/
    public getDescargaArchivoFrm(id: number): Observable<any> {
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/descarga/${id}`, { responseType: resonseType, headers: headers });
    }

    /**
     * Metodo para el DropDownList en el que se mostrarán los proyectos de los documentos
     *
     * @return Información en formato JSON
     **/
    public proyecto(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/configuracion/proyecto`);
    }

    /**
     * Metodo para el DropDownList en el que se mostrarán el tipo de documento
     *
     * @return Información en formato JSON
     **/
    public tipodoc(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/configuracion/tipo`);
    }

    /**
     * Metodo para el DropDownList en el que se mostrarán los formularios
     *
     * @return Información en formato JSON
     **/
    public formulario(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/lstformulario`);
    }

    /**
     * Metodo para el DropDownList en el que se mostrarán la clasificaión de los formularios
     *
     * @return Información en formato JSON
     **/
    public clasifica_formulario(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/lstfrmclasifica`);
    }

    /**
     * Metodo para el DropDownList en el que se mostrarán las opciones del paramentro que se esta ejecutando
     *
     * @return Información en formato JSON
     **/
    public data_consulta(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/ejecuta/dataConsulta/${id}`);
    }

    /**
     * Metodo para el DropDownList en el que se mostrarán los reportes
     *
     * @return Información en formato JSON
     **/
    public lstReportes(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/reporte/lstreportes`);
    }
    /**
     * Metodo para el DropDownList en el que se mostrarán los reportes
     *
     * @return Información en formato JSON
     **/
    public lstTipoParam(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/lsttipoparam`);
    }
    /**
     * Metodo para el ListBox en el que se mostrarán las opciones disponibles para asignar
     *
     * @return Información en formato JSON
     **/
    public lstOpcionesDisponibles(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/lst_opcdisponibles/${id}`);
    }
    /**
     * Metodo para el ListBox en el que se mostrarán las opciones asignadas a cierto parametro
     * @params id ID del parametro al cual se le quiere asignar una o varias opciones
     * @return Información en formato JSON
     **/
    public lstOpcionesAsignadas(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/lst_opcasignadas/${id}`);
    }

    /**
     * Metodo para el ListBox en el que se mostrarán los documentos disponibles para asignar
     *
     * @return Información en formato JSON
     **/
    public lstDisponiblesDoc(id: number, _id_tipoid: number, _id_proyecto: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/configuracion/lst_docdisponibles/${id}/${_id_tipoid}/${_id_proyecto}`);
    }
    /**
     * Metodo para el ListBox en el que se mostrarán los documento asignados
     * @params id ID del documento al cual esta asigandos
     * @return Información en formato JSON
     **/
    public lstAsignableDoc(id: number, _id_tipoid: number, _id_proyecto: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/configuracion/lst_docasignadas/${id}/${_id_tipoid}/${_id_proyecto}`);
    }


    /**
     * Metodo para la busqueda de formularios con base a un referencia.
     * @param referencia Referencia con la cual va a hacer la busqueda por nombre de usuario o formulario
     * @return Información en formato JSON
     **/
    public buscarFormulario(referencia: string): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('referencia', referencia);
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/buscarfrm`, { params: parametros, });
    }
    /**
   * Metodo para la busqueda de empleados con base a un referencia.
   * @param referencia Referencia con la cual va a hacer la busqueda por nombre del empleado
   * @return Información en formato JSON
   **/
    public buscarEmpleado(referencia: string): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('referencia', referencia);
        return this.http.get<any>(`${environment.base_url}/gccalidad/configuracion/buscarempleado`, { params: parametros, });
    }
    /**
    * Metodo para mostrar un listado de formularios
    * @return Información en formato JSON
    **/
    public listadoFrm(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/listadoFrm`);
    }
    /**
     * Metodo para mostrar un listado de formularios a ejecutar por empleado
     * @return Información en formato JSON
     **/
    public listadoFrmEjecuta(): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/listadoFrmEjecuta`);
    }
    /**
     * Metodo para mostrar un listado de los estatus que se pueden asignar
     * @return Información en formato JSON
     **/
    public listadoEstatus(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/listadoEstatus/${id}`
        );
    }

    /**
    * Metodo para mostrar un listado de los archivos asignados en los formularios
    * @return Información en formato JSON
    **/
    public listadoArchivo(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/listadoArchivo/${id}`
        );
    }

    /**
     * Metodo para la obtenición de información de las opciones que estan asignadas al formulario
     * @param id ID del formulario que se seleccione.
     * @return Información en formato JSON
     **/
    public lstOpcionesFormulario(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/opcformularios/${id}`
        );
    }

    /**
     * Metodo para obtener el listado de los formularios que puede visualizar el usuario con
     * el que se ingreso ademas de filtrar la busqueda de los mismos
     * @param numero ID del formulario
     * @param fecha_inicial Fecha inicial de los formularios registrados
     * @param fecha_final Fecha final de los formularios registrados
     * @param referencia Referencia con la cual va a hacer la busqueda por nombre de usuario o formulario
     * @return Información en formato JSON
     **/
    public getData(
        numero: string,
        fecha_inicial: string,
        fecha_final: string,
        referencia: string
    ): Observable<any> | any {
        const parametros: HttpParams = new HttpParams()
            .append('numero', numero)
            .append('fecha_inicial', fecha_inicial)
            .append('fecha_final', fecha_final)
            .append('referencia', referencia);
        return this.http.get(`${environment.base_url}/gccalidad/formulario/listado`, { params: parametros });
    }

    /**
     * Metodo para la generar y visualizar los componentes
     * @param id ID de la tabla de frmInformación de la cual se obtiene toda la información relacionada al formulario
     * @param id2 ID del formulario a seleccionar
     *
     * @return Información en formato JSON
     **/
    public lstparametros(id: number, id2: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/parametros/${id}/${id2}`);
    }

    /**
     * Metodo para la generar y visualizar los componentes
     * @param id ID de la tabla de frmInformación de la cual se obtiene toda la información relacionada al formulario
     * @param id2 ID del formulario a seleccionar
     *
     * @return Información en formato JSON
     **/
    public lstFormulario(id: number): Observable<any> {
        return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/parametrosEjecuta/${id}`);
    }

    /**
     * Metodo para enviar la data a la Api y almacenar la información dentro de la DB
     *
     * @param modelo Objeto dentro del cual se tiene toda la información del formulario
     */
    public guardarFormulario(modelo: MdlFormulario) {
        return this.http.post(`${environment.base_url}/gccalidad/formulario/guardarFormulario`, modelo);
    }
    /**
     * Metodo para enviar la data a la Api y almacenar la información dentro de la DB
     *
     * @param modelo Objeto dentro del cual se tiene toda la información de las opcione
     */
    public guardarOpciones(modelo: MdlOpcionesAgregar) {
        return this.http.post(`${environment.base_url}/gccalidad/formulario/guardarOpciones`, modelo);
    }
    /**
     * Metodo para enviar la data a la Api y almacenar la información dentro de la DB
     *
     * @param modelo Objeto dentro del cual se tiene toda la información de las opcione
     */
    public guardarParametros(modelo: MdlParametros) {
        return this.http.post(`${environment.base_url}/gccalidad/formulario/guardarParametros`, modelo);
    }

    /**
     * Metodo para enviar la data a la Api y almacenar la información dentro de la DB
     * Se almacena el comentario con respecto al formulario que se selecciono
     *
     * @param modelo Objeto dentro del cual se tiene toda la información del comentario
     */
    public guardarComentario(modelo: MdlComentarios) {
        return this.http.post(`${environment.base_url}/gccalidad/formulario/guardarComentario`, modelo);
    }

    /**
     * Metodo para enviar la data a la Api y almacenar la información dentro de la DB
     * Se almacena el proyecto
     *
     * @param modelo Objeto dentro del cual se tiene toda la información del proyecto
     */
    public guardarProyecto(modelo: MdlProyectos) {
        return this.http.post(`${environment.base_url}/gccalidad/proyectos/guardarProyecto`, modelo);
    }
    /**
     * Metodo para enviar la data a la Api y almacenar la información dentro de la DB
     *
     * @param id ID DE FRM_DETALLES
     * @param id2 ID DE FRM_OPCIONES QUE ES LA OPCION
     */
    public insertarOpcion(id: number, id2: number): Observable<any> {
        return this.http.post<any>(`${environment.base_url}/gccalidad/formulario/insertar_opciones/${id}/${id2}`, {});
    }
    /**
   * Metodo para eliminar los datos de la tabla
   *
   * @param id ID DE FRM_DETALLES_OPCIONES
   */
    public borrarOpcion(id: number): Observable<any> {
        return this.http.post<any>(`${environment.base_url}/gccalidad/formulario/borrar_opcion/${id}`, {});
    }

    /**
     * Metodo para borrar registro de formulario
     * @param id Numero o identificador del registro a ser borrado
     * @returns
     */
    public borrar(id: number): Observable<any> {
        return this.http.post<any>(`${environment.base_url}/gccalidad/formulario/borrarFormulario/${id}`, {});
    }

    /**
     * Metodo para ajustar el usuario dentro del formulario
     * @param id Numero o identificador del registro a ser ajustado
     * @param id2 Numero o identificador del usuario
     * @returns
     */
    public usuarioFormulario(id: number, id2: number): Observable<any> {
        return this.http.post<any>(`${environment.base_url}/gccalidad/formulario/usuarioFormulario/${id}/${id2}`, {});
    }


    /**
     * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON
     *
     * @param id Numero o identificador del formulario
     * @returns Observable para recuperar data del formulario
     */
    public getFomularios(id: number): Observable<any> {
        const parametro: HttpParams = new HttpParams();
        return this.http.get(`${environment.base_url}/gccalidad/formulario/cargaFormulario/${id}`);
    }

    /**
   * Obtenemos el listado de Comentarios
   * @param id Id del comentario
   * @returns Observable con listado de Comentarios
   */
    public getListadoComentarios(id: number): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('id', String(id));
        return this.http.get(`${environment.base_url}/gccalidad/formulario/listadoComentarios`, { params: parametros });
    }

    /**
   * Obtenemos el listado de historial de cambios de estatus
   * @param id Id del comentario
   * @returns Observable con listado de Comentarios
   */
    public getListadoHistorial(id: number): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('id', String(id));
        return this.http.get(`${environment.base_url}/gccalidad/formulario/listadoHistorial`, { params: parametros });
    }

    /**
     * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON de
     * las opciones
     *
     * @param id Numero o identificador de la opción
     * @returns Observable para recuperar la opción
     */
    public getOpcionesAdd(id: number): Observable<any> {
        return this.http.get(`${environment.base_url}/gccalidad/formulario/cargaOpciones/${id}`);
    }

    /**
    * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON
    *
    * @param id Numero o identificador
    * @returns Observable para recuperar la data
    */
    public getParametros(id: number): Observable<any> {
        return this.http.get(`${environment.base_url}/gccalidad/formulario/cargaParametro/${id}`);
    }

    /**
     * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON
     *
     * @param id Numero o identificador
     * @returns Observable para recuperar la data
     */
    public getFrm(id: number): Observable<any> {
        return this.http.get(`${environment.base_url}/gccalidad/formulario/cargaFormulario/${id}`);
    }
    /**
    * Metodo para obtener la data de la Api. Obtendremos el objeto en formato JSON
    *
    * @param id Numero o identificador
    * @returns Observable para recuperar la data
    */
    public getProyecto(id: number): Observable<any> {
        return this.http.get(`${environment.base_url}/gccalidad/proyectos/cargaProyectos/${id}`);
    }

    /**
     * Metodo para exportar formulario a formato txt
     * @param id
     * @returns
     */
    public getTXT(id: number): Observable<any> {
        return this.http.post<any>(`${environment.base_url}/gccalidad/formulario/get_texto/${id}`, {});
    }

}
