<!--Dialogo par busqueda de Informacion con base en tabla y columna
    Esta ventana puede ser utilizada por el modulo de reportes para el tipo
    de parametro 6 = Busqueda
-->
<ejs-dialog id="dlgBusqueda" #dlgBusqueda header='Informacion de busqueda' gridLines='Both' maxHeight='500px' width='500px' isModal='true' [visible]='false' [buttons]='buttons'>
    <ng-template #header>
        <div id="dlg-template" class="e-icon-settings"><i class="fas fa-search"></i> Buscar Informacion</div>
    </ng-template>
    <ng-template #content>
        <div class='form-group'>
            <label>Descripcion:</label>
            <div class="input-group">
                <input type="text" #referencia autofocus class="form-control" (keydown.enter)="buscar()">
                <div class="input-group-append">
                    <button class="btn btn-buscar-cuadrado" (click)="buscar()"></button>
                </div>
            </div>
        </div>
        <ejs-grid #tabla [dataSource]='data' (rowSelected)="onRowSelected($event)" height=150>
            <e-columns>
                <e-column field='id' headerText='Id' [width]='0'></e-column>
                <e-column field='descripcion' headerText='Descripción' width='100%'></e-column>
            </e-columns>
        </ejs-grid>
    </ng-template>
</ejs-dialog>
