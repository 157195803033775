import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PagesComponent } from './pages.component';
import { PerfilComponent } from './SIusuario/perfil/perfil.component';
import { CalidadRoutingModule } from './GCcalidad/calidad-routing.module';
import { PerfilResolver } from '../services/SIusuario/perfil.resolver';
import { NotificacionesComponent } from './SIusuario/notificaciones/notificaciones.component';
import { CorreoComponent } from './SIusuario/correo/correo.component';
import { NotificacionesResolver } from './SIusuario/notificaciones/services/notificaciones.resolver';

const routes: Routes = [
    {
        path: 'escritorio', component: PagesComponent,
        //canActivate: [ AuthGuard ],
        children: [
            { path: 'perfil', component: PerfilComponent, resolve: { datos: PerfilResolver }, data: { breadcrumb: { menu1: 'Perfil', menu2: 'Usuario' } } },
            { path: 'notificaciones', component: NotificacionesComponent, resolve: { listado: NotificacionesResolver }, data: { breadcrumb: { menu1: 'Perfil', menu2: 'Notificaciones' } } },
            { path: 'correo', component: CorreoComponent, data: { breadcrumb: { menu1: 'Perfil', menu2: 'Correo' } } },
        ]
    },
    { path: 'correo', component: CorreoComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CalidadRoutingModule
    ],
    exports: [RouterModule],
    providers: [

    ]
})
export class PagesRoutingModule { }
