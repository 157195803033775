import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusquedagenericoService {

  constructor(private http: HttpClient) { }

  public listado(
    _tabla: string,
    _columna: string,
    _referencia: string,
    _condicion: string
  ): Observable<any> {
    const parametro: HttpParams = new HttpParams()
      .append('tabla', _tabla)
      .append('columna', _columna)
      .append('referencia', _referencia)
      .append('condicion', _condicion);
    return this.http.get<any>(`${environment.base_url}/gccalidad/formulario/busqueda`, { params: parametro });
  }

}