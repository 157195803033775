<block-ui>
</block-ui>
<div class="modal-header">
    <h4 class="modal-title encabezado">{{encabezado}}</h4>
</div>
<div class="modal-body">
    <ngx-file-drop dropZoneLabel="Soltar aqui los archivos EXCEL" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
    [multiple]="false" [showBrowseBtn]="false" accept=".xls, .xlsx*" [directory]="false" dropZoneClassName="dropzone">
      <ng-template let-openFileSelector="openFileSelector">
        Soltar aqui los archivos EXCEL
      </ng-template>
    </ngx-file-drop>

</div>
<div class="modal-footer piepagina">
    <button class="btn boton" aria-label="Close" (click)="activeModal.close('-1')">
        Cerrar
    </button>
</div>