import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CorreoService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * ! Metodo para obtener listado de los correos del servidor POP
   * ? Las credenciales de autentificación se obtienen del perfil del usuario
   * @returns
   */
  public getCorreo(): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/correo/recibir/getCorreo`, {});
  }
}
