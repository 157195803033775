<div class="breadcrumb_navbar">
    <ul class="breadcrumb">
        <li><a>{{this.breadcrumbs1}}</a></li>
        
    </ul>
</div>

<block-ui></block-ui>
<section>
    <app-loader></app-loader>
    <div class="card">
        <div class="card-body">
            <div class="footer">
                <button class="btn btn-nuevo"> {{"Boton.Nuevo" | translate}}</button>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-detail">
            <h2>Acceso no permitido a este modulo</h2>
        </div>
    </div>
</section>
