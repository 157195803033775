import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { environment } from 'src/environments/environment';
import { GccalidadService } from '../services/gccalidad.service';
import { EmitType } from '@syncfusion/ej2-base';
import { MdlProyectos } from '../models/MdlProyectos';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.scss']
})
export class ProyectosComponent implements OnInit {
  public breadcrumbs1: string = '';
  public breadcrumbs2: string = '';

  public dataLstProyectos: any;
  UtilComponent: any;
  public id_proyectos: number = -1;
  public formulario: UntypedFormGroup;
  @ViewChild('dlgFormulario') dlgFormulario: DialogComponent;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private route: ActivatedRoute,
    private gc_calidad_Service: GccalidadService,
    private fb: UntypedFormBuilder,
    private http: HttpClient
  ) {
    this.cargarLst();
  }

  ngOnInit(): void {
    this.route.data.subscribe(resp => {
      this.breadcrumbs1 = resp.rutas.respuesta.ruta;
      
    });
    this.formulario = this.fb.group(new MdlProyectos());
  }

  /**
 * Metodo para cargar el listado de los proyectos
 * @returns;
 *
 **/
  public cargarLst() {
    this.gc_calidad_Service.proyecto().subscribe((resp) => {

      this.dataLstProyectos = JSON.parse(JSON.stringify(resp)).respuesta;
    });
  }
  btnNuevo() {
    this.formulario = this.fb.group({
      id: [-1],
      descripcion: ['', Validators.required],
    });
    this.dlgFormulario.show();
  }
  public btnBorrar(id) {
    this.id_proyectos = id;
    this.UtilComponent = DialogUtility.confirm({
      title:
        'Información Arcarius' +
        '[Alerta]' +
        ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
      content: '<h6><b>¿Estas seguro de eliminar este registro?</b></h6>',
      okButton: { text: 'Si', click: this.btnBorrar2.bind(this) },
      cancelButton: { text: 'No' },
      showCloseIcon: true,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
    });
  }

  public btnBorrar2: EmitType<object> = () => {
    this.http
      .post(
        `${environment.base_url}/gccalidad/proyectos/eliminarProyecto/${this.id_proyectos}`,
        {}
      )
      .subscribe((respuesta) => {
        if (JSON.parse(JSON.stringify(respuesta)).numero > 0) {
          DialogUtility.alert({
            title: 'Informacion Arcarius',
            content: JSON.parse(JSON.stringify(respuesta)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
          this.cargarLst();
        } else {
          DialogUtility.alert({
            title: 'Informacion Arcarius [Problemas]',
            content: JSON.parse(JSON.stringify(respuesta)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
          });
        }
        this.UtilComponent.hide();
        this.id_proyectos = -1;
      });
  };

  /**
   * Metodo para cargar el formulario a partir del Numero o identificador del
   * proyecto seleccionado
   */
  public btnEditar(id: number): void {
    this.gc_calidad_Service.getProyecto(id).subscribe((resp) => {
      let formulario: MdlProyectos = resp.respuesta;
      this.formulario = this.fb.group(formulario);
    });
    this.dlgFormulario.show();
  }

  /**
   * Metodos para las validaciones de Nuevoformulario
   */
  get descripcionNoValida() {
    return (
      this.formulario.get('descripcion').invalid &&
      this.formulario.get('descripcion').touched
    );
  }

  public revisa() {
    if (this.formulario.invalid) {
      return Object.values(this.formulario.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }

  /**
   * Metodo para guardar la información del formulario
   */
  public btnGuardar(): void {
    this.revisa();
    if (!this.formulario.invalid) {
      this.blockUI.start('Procesando...');
      this.gc_calidad_Service
        .guardarProyecto(this.formulario.value)
        .subscribe((resp) => {
          let respuesta = JSON.parse(JSON.stringify(resp));
          if (respuesta.numero < 0) {
            DialogUtility.alert({
              title:
                '<span class="dialogo-titulo-error">' +
                respuesta.titulo +
                ' <i style="color: #fff" class="fas fa-ban"></i></span>',
              content: JSON.parse(JSON.stringify(resp)).mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' },
            });
            this.blockUI.stop();
          } else {
            DialogUtility.alert({
              title:
                '<span style="color: #28a745">' +
                respuesta.titulo +
                ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
              content: JSON.parse(JSON.stringify(resp)).mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' },
            });
            this.blockUI.stop();
            this.dlgFormulario.hide();
            this.cargarLst();
            this.formulario = this.fb.group({
              id: [-1],
              descripcion: ['', Validators.required],
            });
          }
        });
    }
  }
}
