import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MatrizService } from './services/matriz.service';
import { MdlMatriz } from './models/MdlMatriz';

@Component({
	selector: 'app-matrixcomunicacion',
	templateUrl: './matrizcomunicacion.component.html',
	styleUrls: ['./matrizcomunicacion.component.scss']
})
export class MatrizcomunicacionComponent {

	@BlockUI() blockUI: NgBlockUI;
	@Input('id') public id: number = -1;
	@Input('titulo') public titulo: string = 'Matriz de Comunicacion';
	public formulario: UntypedFormGroup = new UntypedFormGroup({});

	constructor(
		private fb: UntypedFormBuilder,
		private servicio: MatrizService
	) {
		// Este bloque puede ser sustituido por la creación del formulario a partir de un modelo
		// this.formulario = this.fb.group(new Mdlmodelo);
		this.formulario = this.fb.group(new MdlMatriz());
	}

	/**
	 * Metodo para almacenar la informacion
	 */
	public btnGuardar() {
		this.servicio.setDato(this.formulario.value).subscribe((resp) => {
			
		});
	}

	/**
	 * Metodo para validar informacion capturada
	 */
	get descripcionNoValida() {
		return (
			this.formulario.get('descripcion').invalid &&
			this.formulario.get('descripcion').touched
		);
	}
}
