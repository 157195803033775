import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PatronesService } from '../services/Patron.service';
import { util } from 'src/app/shared/util';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { dlgMensajeConfirma } from 'src/app/components/dlgMensajeConfirma/dlgMensajeConfirma.component';
import { PatrondialogoComponent } from './patrondialogo/patrondialogo.component';

@Component({
  selector: 'app-patron',
  templateUrl: './patron.component.html',
  styleUrls: ['./patron.component.scss'],
})
export class PatronComponent {
  @BlockUI() blockUI: NgBlockUI;
  UtilComponent: any;
  public data: any;
  public breadcrumbs1: string = '';
  public breadcrumbs2: string = '';
  public IdRow: number = -1;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private service: PatronesService,
    private utilerias: util
  ) {
    this.carga();
  }

  ngOnInit(): void {
    this.route.data.subscribe((resp) => {
      this.breadcrumbs1 = resp.rutas.respuesta.ruta;
      
    });
  }

  /**
   * Metodo para carga de informacion en listado
   */
  public carga() {
    this.blockUI.start('Obteniendo informacion...');
    this.service.Table().subscribe((resp) => {
      this.data = JSON.parse(
        JSON.stringify(resp.respuesta),
        this.utilerias.parseFecha
      );
      this.blockUI.stop();
    });
  }

  /**
   * Metodo para lanzar la ventana modal donde se podra capturar de manera
   * inicial la informacion
   */
  public nuevo() {
    const modalRef = this.modalService.open(PatrondialogoComponent);
    modalRef.componentInstance.cerrar.subscribe(($event) => {
      this.carga();
    });
  }

  /**
   * Metodo para lanzar ventana modal donde de modificaran los datos
   * @args Objeto que contiene los datos a modificar
   */
  Editar(args: any) {
    const modalRef = this.modalService.open(PatrondialogoComponent);
    modalRef.componentInstance.datos = args;
    modalRef.componentInstance.cerrar.subscribe(event => {
      this.carga();
    });
  }

  /**
   * Metodo para elimnar el registro de patrones
   * @param id Numero o Identificador del registro a eliminar
   */
  public Eliminar(id: number) {
    const modalRef = this.modalService.open(dlgMensajeConfirma);
    modalRef.componentInstance.encabezado = 'Patrones';
    modalRef.componentInstance.contenido = 'Deseas eliminar el registro ?';
    modalRef.result.then((resultado) => {
      if (resultado == 'Si') {
        this.service.borrar(id).subscribe((resp) => {
          let respuesta = JSON.parse(JSON.stringify(resp));
          if (respuesta.numero < 0) {
            const modalRef = this.modalService.open(dlgMensajeError);
            modalRef.componentInstance.encabezado = JSON.parse(
              JSON.stringify(respuesta)
            ).titulo;
            modalRef.componentInstance.contenido = JSON.parse(
              JSON.stringify(respuesta)
            ).mensaje;
          } else {
            this.blockUI.start('Obteniendo informacion...');
            this.service.Table().subscribe((resp) => {
              this.data = resp.respuesta;
              this.blockUI.stop();
            });
          }
        });
      }
    });
  }
}
