import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dlgMensajeError',
  templateUrl: './dlgMensajeError.component.html',
  styleUrls: ['./dlgMensajeError.component.scss']
})
export class dlgMensajeError {

  @Input() encabezado;
  @Input() contenido;
  @Input() error;

  constructor(public activeModal: NgbActiveModal) { }

}
