<!--Dialogo para los correos.
    Esta ventana puede ser utilizada para diferentes modulos con la finalidad de mandar correos
    mediante un modelo..
-->

<ejs-dialog id="dglCorreo" #dglCorreo header='Envio Correo' gridLines='Both' height='500px' width='500px' isModal='true' showCloseIcon='true' [visible]='false'>
    <block-ui>
        <form autocomplete="off" [formGroup]="formulario">
            <div class='form-group'>
                <label>Para:</label>
                <input type='text' class="form-control" #para required formControlName="para" />
                <small *ngIf="paraNoValida" class="text-danger">Se requiere un destinatario para enviar correo</small>
            </div>
            <div class='form-group'>
                <label>Copia:</label>
                <input type='text' class="form-control" #copia />
            </div>
            <div class='form-group'>
                <label>Copia oculta:</label>
                <input type='text' class="form-control" #copiaoculta />
            </div>
            <div class='form-group'>
                <label>Asunto:</label>
                <input type='text' class="form-control" #encabezado required formControlName="asunto" />
                <small *ngIf="asuntoNoValida" class="text-danger">Se requiere un asunto para enviar correo</small>
            </div>
            <div class='form-group'>
                <label>Mensaje:</label>
                <ejs-richtexteditor id='iframeRTE' [toolbarSettings]='toolbarSettings' #mensaje required formControlName="mensaje">
                </ejs-richtexteditor>
                <small *ngIf="mensajeNoValida" class="text-danger">Se requiere un mensaje para enviar correo</small>
            </div>
            <button type="submit" class="btn btn-correo" (click)="enviarCorreo()">Enviar {{"Boton.Correo" | translate}}</button>
        </form>
    </block-ui>
</ejs-dialog>