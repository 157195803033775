export class MdlUsuario {

    public usuario: string;
    public nombre: string;
    public paterno: string;
    public materno: string;
    public puesto: string;
    public departamento: string;
    public empresa: string;
    public perfil: number;
    public id: number;
    public id_rpt_tablero_detalle: number;

    constructor(
        usuario: string,
        nombre: string,
        paterno: string,
        materno: string,
        puesto: string,
        departamento: string,
        empresa: string,
        perfil: number,
        id: number,
        id_rpt_tablero_detalle: number
    ) {
        this.usuario = usuario;
        this.nombre = nombre;
        this.paterno = paterno;
        this.materno = materno;
        this.puesto = puesto;
        this.departamento = departamento;
        this.empresa = empresa;
        this.perfil = perfil;
        this.id = id;
        this.id_rpt_tablero_detalle = id_rpt_tablero_detalle;
    }

    getUsuario(): string {
        return this.usuario;
    }

    getNombre(): string {
        return this.nombre;
    }

    getPaterno(): string {
        return this.paterno;
    }

    getMaterno(): string {
        return this.materno;
    }

    getEmpresa(): string {
        return this.empresa;
    }

    getPuesto(): string {
        return this.puesto;
    }

    getDepartamento(): string {
        return this.departamento;
    }

    getPerfil(): number {
        return this.perfil;
    }

    getId(): number {
        return this.id;
    }

    getId_rpt_tablero_detalle(): number {
        return this.id_rpt_tablero_detalle;
    }

}

