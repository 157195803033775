<block-ui>
</block-ui>
<section *ngIf="this.data">
    <div class="card">
        <div class="card-title">
            <h5>{{descripcion}}</h5>
            <button class="btn btn-nuevo btn-derecha" (click)="btnNuevo()"> {{"Boton.Nuevo" | translate}}</button>
            <button class="btn btn-cerrar btn-derecha" (click)="offcanvasService.dismiss('')"> {{"Boton.Cerrar" | translate}}</button>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <form #frmParametros id="frmParametros" [formGroup]="formulario">
                        <div class="flex-rows">
                            <div *ngFor="let item of data" class="flex-rows-child" [ngClass]="item.tipo == 7 ? (item.id_frm_detalle_condicion == undefined ? 'break' : item.id_frm_opciones_condicion != this.formulario.controls[item.id_frm_detalle_condicion + ''].value ? 'clsOculto': 'break') : ''">
                                <div *ngIf="item.tipo == 7">
                                    <!--7 Titulo
                                        [ngClass]="{break: item.tipo == 7}"
                                        [ngClass]="(item.id_frm_detalle_condicion == undefined ? false : item.id_frm_opciones_condicion != this.formulario.controls[item.id_frm_detalle_condicion + ''].value) ? 'clsOculto': ''"
                                        -->
                                    <h6>{{item.descripcion}}</h6>
                                </div>
                                <div *ngIf="item.tipo != 7" style="margin-top: 5px;">
                                    <div class="row">
                                        <div class="col-md-6 etiqueta" [title]="'C_'+item.id_frm_detalle"
                                            [ngClass]="(item.id_frm_detalle_condicion == undefined ? false : item.id_frm_opciones_condicion != this.formulario.controls[item.id_frm_detalle_condicion + ''].value) ? 'clsOculto': ''">
                                            {{item.descripcion}}
                                        </div>
                                        <div class="col-md-6">
                                            <!--1 NUMERICO-->
                                            <input type="number" class="form-control" *ngIf="item.tipo == 1"
                                                formControlName="{{item.id_frm_detalle}}"
                                                [id]="'C_'+item.id_frm_detalle" />
                                            <!--2 TEXTO -->

                                            <input
                                                *ngIf="item.tipo == 2 && item.renglones == 1 && item.id_frm_opciones_condicion == (item.id_frm_detalle_condicion == undefined ? item.id_frm_opciones_condicion : this.formulario.controls[item.id_frm_detalle_condicion + ''].value)"
                                                [attr.aria-label]="item.formula_js"
                                                [ngClass]="item.formula_js !=='' ?'form-control formula':'form-control' "
                                                type="text" formControlName="{{item.id_frm_detalle}}"
                                                [id]="'C_'+item.id_frm_detalle" (focusout)="onFocusOutEvent($event)">


                                            <textarea *ngIf="item.tipo == 2 && item.renglones > 1"
                                                formControlName="{{item.id_frm_detalle}}" [rows]="item.renglones"
                                                style="width: 100%;"></textarea>
                                            <!--3 FECHA-->
                                            <ejs-datepicker *ngIf="item.tipo == 3" timezone="UTC" [format]="format"
                                                formControlName="{{item.id_frm_detalle}}" value="item.fecha">
                                            </ejs-datepicker>
                                            <!--4 CONSULTA-->
                                            <ejs-dropdownlist *ngIf="item.tipo == 4" [dataSource]='item.data'
                                                [fields]='fields' formControlName="{{item.id_frm_detalle}}"
                                                [id]="'C_'+item.id_frm_detalle" (change)="onFocusOutEvent($event)">
                                            </ejs-dropdownlist>
                                            <!--5 BUSQUEDA-->
                                            <div *ngIf="item.tipo == 5" class="input-group mb-6">
                                                <dlgBusqueda #dlgBusqueda [tablas]='item.tabla'
                                                    [columnas]='item.columna' [condicion]='item.condicion'
                                                    (numero)="onBusquedaSelect($event, item.id_frm_detalle)">
                                                </dlgBusqueda>
                                                <div class="input-group input-group-sm">
                                                    <input type="text" class="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        id="id_{{item.id_frm_detalle}}" readonly>
                                                    <input type="text" class="form-control" aria-label="Small"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        formControlName="{{item.id_frm_detalle}}" hidden="true">
                                                    <div class="input-group-append">
                                                        <button class="btn btn-buscar-cuadrado"
                                                            (click)="dlgBusqueda.show()"></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--6 MULTICONSULTA-->
                                            <ejs-multiselect *ngIf="item.tipo == 6" [dataSource]='item.data'
                                                [fields]="fields" formControlName="{{item.id_frm_detalle}}"
                                                [id]="'C_'+item.id_frm_detalle">
                                            </ejs-multiselect>
                                            <!--8 BUSQUEDA DE IDs-->
                                            <div *ngIf="item.tipo == 8" name="{{item.variable}}"
                                                class="input-group mb-6">
                                                <div class="input-group-prepend">
                                                    <button class="btn btn-outline-success">
                                                        <i class="fa fa-search" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                                <input type="text" #tfIds readonly
                                                    formControlName="{{item.id_frm_detalle}}">
                                            </div>
                                            <!--9 Imagen-->
                                            <div *ngIf="item.tipo == 9">
                                                <div style="display: grid;">
                                                    <button class="btn btn-buscar-cuadrado" style="width: 100%;"
                                                        (click)="btnImagen(item.id_frm_detalle)">
                                                        <i class="far fa-file-image"></i> Subir imagen
                                                    </button>
                                                    <input type="hidden" formControlName="{{item.id_frm_detalle}}">
                                                    <img class="img-thumbnail"
                                                        [src]="domSanitizer.bypassSecurityTrustUrl(this.formulario.controls[item.id_frm_detalle].value)"
                                                        style="max-width: 300px; width: 100%;">
                                                </div>
                                            </div>
                                            <!-- Mostramos validador cuando la informacion es requerida -->
                                            <div *ngIf="this.validaControl(item.id_frm_detalle + '')"
                                                class="valida-error">Informacion
                                                requerida</div>
                                        </div>
                                    </div>
                                    <div class="row" class="observaciones" *ngIf="item.observaciones">
                                        <div class="col-md-12">
                                            {{item.observaciones}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="footer">
                        <button class="btn btn-guardar" (click)="btnGuardar()" [disabled]="!this.modoEdicion"> {{"Boton.Guardar" | translate}}</button>
                        <button class="btn btn-imprimir" (click)="btnImprimir()"> {{"Boton.Imprimir" | translate}}</button>
                        <button class="btn btn-procesar" (click)="btnEstatus()"> {{"Boton.Estatus" | translate}}</button>
                        <button class="btn btn-importar" (click)="btnImportArchivos()"> Importar</button>
                        <button class="btn btn-enlace" (click)="btnArchivos()"> {{"Boton.Archivos" | translate}}</button>
                        <button class="btn btn-borrar" (click)="btnBorrarFormulario()"> Borrar</button>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-detail">
                            <div class="row" style="margin-bottom: 5px;">
                                <div class="col-3 etiqueta">
                                    <i class="fa-solid fa-user"></i> Usuario
                                </div>
                                <div class="col-9">
                                    <div class="input-group">
                                        <button class="btn btn-buscar-interno feedback-form-control"
                                            (click)="btnBuscar()">
                                        </button>
                                        <input type="text" class="form-control feedback-input" readonly='true'
                                            value="{{usuario}}">
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-bottom: 5px;">
                                <div class="col-3 etiqueta">
                                    <i class="fa-solid fa-file-circle-check"></i> Estatus
                                </div>
                                <div class="col-9">
                                    <input class="form-control" type="text" value="{{estatus}}" readonly='true'>
                                </div>
                            </div>
                            <!-- Historial de cambios de estatus -->
                            <div class="row" style="margin-bottom: 5px;">
                                <table class="table table-hover table-bordered table-sm table-responsive">
                                    <thead>
                                        <tr>
                                            <td>Fecha</td>
                                            <td>Estatus</td>
                                            <td>Usuario</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of dataHistorial">
                                            <td>{{item.fecha}}</td>
                                            <td>{{item.estatus}}</td>
                                            <td>{{item.usuario}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card">
                            <div class="card-title mb-1 p-2">
                                <h5>Comentarios</h5>
                            </div>
                            <div class="card-detail">
                                <div class="list-group">
                                    <div class="list-group-item list-group-item-action"
                                        *ngFor="let item of dataComentarios">
                                        <div class="row">
                                            <div class="col-12">
                                                <span style="float: left; padding-top: 5px;"><img
                                                        src="assets\iconos\persona32.png"></span>

                                                <small>{{item.fecha}}</small>
                                                <h6>{{item.nombre}}</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <span class="col-12" [innerHtml]="item.comentario | safeHtml"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="footer">
                                    <button class="btn btn-comentario" (click)="btnComentario()">
                                        <i class="fa-regular fa-comments"></i>
                                        Comentar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ejs-dialog #dlgArchivos header='Archivos' [isModal]='true' [visible]='false' showCloseIcon='true' width='50%'>
    <ejs-grid [dataSource]='dataArchivos' width='auto' height='110px'>
        <e-columns>
            <e-column field='id' headerText='Id' width='0'>
            </e-column>
            <e-column headerText='' width='110' minWidth='110'>
                <ng-template #template let-data>
                    <button class="btn btn-descarga-sm " (click)="descarga(data.id,data.nombre,data.imagen)"
                        title="Descargar Archivo"></button>
                    <button class="btn btn-borrar-sm" title="Eliminar Archivo" (click)="btnBorrar(data.id)">
                    </button>
                </ng-template>
            </e-column>
            <e-column field='nombre' headerText='Nombre'>
            </e-column>
        </e-columns>
    </ejs-grid>
    <div class="callout callout-primary">
        <div id='droparea' style="height: 70px; background: #e9f5db; text-align: center; padding-top: 20px;">
            Soltar aqui tu archivo
            <a href="" id="browse" (click)='browseClick()'><u>Buscar Archivo</u></a>
            <ejs-uploader id="archivoCarga" [autoUpload]='false' [dropArea]='dropEle' multiple='false'
                (selected)="selected($event)">
                <ng-template #template let-data>
                    <span class="fileListwrapper">
                        <span class="icon template-icons sf-icon-{{data.type}}"></span>
                        <span class="name file-name">{{data.name}} ({{data.size}}
                            bytes)</span>
                        <span class="upload-status">{{data.status}}</span>
                    </span>
                    <span class="e-icons e-file-remove-btn" title="Remove"></span>
                </ng-template>
            </ejs-uploader>
        </div>
    </div>
</ejs-dialog>

<dlgComentario #dlgComentario (descripcion)="onGuardarComentario($event)"></dlgComentario>

<ejs-dialog #dlgimagenPregunta width='400px' isModal='true' [visible]='false' showCloseIcon='true'
    (open)="openImagen()">
    <ng-template #header>
        <div id="dlg-template" class="e-icon-settings"><i class="fas fa-dice-d6"></i> Imagen</div>
    </ng-template>
    <ng-template #content>
        <div style="text-align: center;">
            <img class="preview-image" #imgRenderer [src]="imageFile ? imageFile.link : '/assets/img/favicon.png'"
                style="max-width: 350px;">
        </div>

        <div id='dropareaImg' style="height: 70px; background: #e9f5db; text-align: center; padding-top: 20px;">
            Soltar aqui tu archivo
            <a href="" id="browse" (click)='browseClickImg()'><u>Buscar Archivo</u></a>
            <ejs-uploader id="archivoCargaImg" [autoUpload]='false' [dropArea]='dropEleImg' multiple='false'
                (selected)="selectedImg($event)">
                <ng-template #template let-data>
                    <span class="fileListwrapper">
                        <span class="icon template-icons sf-icon-{{data.type}}"></span>
                        <span class="name file-name">{{data.name}} ({{data.size}}
                            bytes)</span>
                        <span class="upload-status">{{data.status}}</span>
                    </span>
                    <span class="e-icons e-file-remove-btn" title="Remove"></span>
                </ng-template>
            </ejs-uploader>
        </div>

    </ng-template>
    <ng-template #footerTemplate>
        <button class="btn btn-primary" (click)="btnPegarImagen()">
            <i class="far fa-file-image"></i> Pegar Imagen
        </button>
        <button class="btn btn-success" (click)="btnImportar()">
            <i class="far fa-file-image"></i> Importar
        </button>
    </ng-template>
</ejs-dialog>
