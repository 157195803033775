import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DlgBusquedaComponent } from 'src/app/components/dlgBusqueda/dlgBusqueda.component';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ejecuta',
  templateUrl: './ejecuta.component.html',
  styleUrls: ['./ejecuta.component.scss']
})
export class EjecutaComponent implements OnInit {
  public breadcrumbs1: string = '';
  public breadcrumbs2: string = '';

  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('frmParametros') frmParametros: ElementRef;
  @ViewChild('id_generico') id_generico: ElementRef;
  @ViewChild('tfBusqueda') tfBusqueda: ElementRef;
  @ViewChild('dlgBusqueda') dlgBusqueda: DlgBusquedaComponent;
  @ViewChild('dlgEstatus') dlgEstatus: DialogComponent;
  @ViewChild('dlgArchivos') dlgArchivos: DialogComponent;
  @ViewChild('archivo') archivo: ElementRef;
  @ViewChild('dlgimagenPregunta') dlgimagenPregunta: DialogComponent;

  public descripcion: string = '';
  public id: number = -1;
  public data: any;
  public fields: Object = { value: 'id', text: 'descripcion' };
  public formulario: UntypedFormGroup = new UntypedFormGroup({});
  public format: string = 'dd-MM-yyyy';
  public habilitado = false;
  public imagen_tmp: string = '';
  public imagen_id: number = -1;
  public imageFile: { link: string, file: any, name: string };

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    public domSanitizer: DomSanitizer
  ) {
    this.route.data.subscribe((resp: { parametros: any[] }) => {
      this.data = JSON.parse(JSON.stringify(resp.parametros)).respuesta.data;
      this.descripcion = JSON.parse(JSON.stringify(resp.parametros)).respuesta.descripcion;
      if (this.data === '') {
        // =================================================================
        // Si no tenemos "data" quiere decir que no hay "parametros"
        // Terminamos este metodo
        // =================================================================
        return;
      }
      this.data.forEach(elemento => {
        switch (elemento.tipo) {
          case 1:
            this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl('', Validators.required));
            break;
          case 2:
            this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl('', Validators.required));
            break;
          case 3:
            this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl('', Validators.required));
            break;
          case 4:
            this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl('', Validators.required));
            break;
          case 5:
            this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl('', Validators.required));
            break;
          case 6:
            this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl('', Validators.required));
            break;
          case 7:
            this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl('', Validators.required));
            break;
          case 8:
            this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl('', Validators.required));
            break;
          case 9:
            this.formulario.addControl(elemento.id_frm_detalle, new UntypedFormControl('', Validators.required));
            break;
        }
      });
    });
  }

  ngOnInit(): void {
    this.route.data.subscribe(resp => {
      this.breadcrumbs1 = resp.rutas.respuesta.ruta;
      
    });
    //=============================================================================================
    // Obtenemos los parametros del router (ruta)
    //=============================================================================================
    this.route.params.subscribe(
      (params: Params) => {
        this.id = params.id;
      }
    );
    //=============================================================================================
  }

  public revisa() {
    if (this.formulario.invalid) {
      return Object.values(this.formulario.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
  }

  public btnGuardar() {
    this.revisa();
    if (this.formulario.invalid) {
      DialogUtility.alert({
        title: '<span class="dialogo-titulo-error">Documentos<i style="color: #fff" class="fas fa-ban"></i></span>',
        content: "Faltan Campos por llenar",
        showCloseIcon: true,
        closeOnEscape: true,
        animationSettings: { effect: 'Zoom' }
      });
    } else {
      let data2 = []
      for (let i = 0; i < this.data.length; i++) {
        data2 = data2.concat({ 'id': String(this.data[i].id_frm_detalle), 'datos': this.formulario.controls[this.data[i].id_frm_detalle].value });
      }
      this.blockUI.start("Procesando...");
      this.http.post(`${environment.base_url}/gccalidad/formulario/insertarFormulario/${this.id}/${-1}`, data2).subscribe((respuesta) => {
        if (JSON.parse(JSON.stringify(respuesta)).numero < 0) {
          DialogUtility.alert({
            title: '<span class="dialogo-titulo-error">Formulario<i style="color: #fff" class="fas fa-ban"></i></span>',
            content: JSON.parse(JSON.stringify(respuesta)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' }
          });
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.router.navigate(['/calidad/listado']);
        }
      });
    }
  }

  // ==================================================================================
  // Al seleccionar un registro de la tabla de Busqueda
  // ==================================================================================
  public onBusquedaSelect(args, id_frm_detalle: number) {
    if (args === undefined) {
      this.dlgBusqueda.hide();
      return;
    } else {
      this.formulario.controls[id_frm_detalle].setValue(args.id);
      (<HTMLInputElement>document.getElementById('id_' + id_frm_detalle)).value = args.descripcion;
    }
  }

  public btnImagen(id) {
    this.imagen_id = id;
    this.dlgimagenPregunta.show();
  }


  public imagesPreview(event: any, id: string) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event: any) => {
        this.imageFile = {
          link: _event.target.result,
          file: event.srcElement.files[0],
          name: event.srcElement.files[0].name
        };
        this.imagen_tmp = String(reader.result);
      };
    }
  }

  public btnImportar() {
    let cantidad: number = this.archivo.nativeElement.files.length;
    if (cantidad === 0) {
      DialogUtility.alert({
        title: 'Imagen Pregunta',
        content: 'Selecciona una imagen!',
        showCloseIcon: true,
        closeOnEscape: true,
        animationSettings: { effect: 'Zoom' }
      });
      return;
    }
    this.formulario.controls[this.imagen_id].setValue(String(this.imagen_tmp));
    this.dlgimagenPregunta.hide();
  }
}
