<div class="breadcrumb_navbar">
    <ul class="breadcrumb">
        <li><a>{{this.breadcrumbs1}}</a></li>
    </ul>
</div>

<section>
    <div class="card">
        <div class="card-detail">
            <div class="row">
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Proyecto</label>
                    <ejs-dropdownlist [dataSource]='data_proyecto' [fields]='fields' placeholder='Seleccionar Proyecto' (change)='onChangeProyecto($event)'></ejs-dropdownlist>
                </div>
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Tipo</label>
                    <ejs-dropdownlist [dataSource]='data_tipo' [fields]='fields' placeholder='Seleccionar Tipo de Documento' (change)='onChangeTipo($event)'></ejs-dropdownlist>
                </div>
            </div>
            <!-- tabla de calificaciones -->
        </div>
    </div>

    <div class="card">
        <div class="card-detail">
            <table class="table table-sm table-hover table-bordered">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data">
                        <td>
                            <i style="color: #fd7e14;"></i> {{item.tipo}}
                        </td>
                        <td>
                            <i style="color: #fd7e14;"></i> {{item.nombre}}
                        </td>
                        <td>
                            <button class="btn btn-descarga" (click)="descarga(item.id,item.nombre,item.archivo)">Descargar</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>
