import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organizacion',
  templateUrl: './organizacion.component.html',
  styleUrls: ['./organizacion.component.css']
})
export class OrganizacionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
