import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CalendarioComponent } from './SIusuario/calendario/calendario.component';


@NgModule({
  declarations: [
    CalendarioComponent
  ],

  imports: [
    SharedModule
  ],
  providers: [

  ],
  //===============================================================================================
  // La siguiente linea remueve los errores de sintaxis del los componentes web, en los archivos
  // html de cada componente. Esto para los componentes importados de terceros SyncFusion.
  //===============================================================================================
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  //===============================================================================================
})
export class PagesModule { }
