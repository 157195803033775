export class MdlPatron
{
	  public id: number = -1 ;
    public codigo: string = "";
    public alcance: string = "";
    public clase: string = "";
    public fecha_calibracion: Date = new Date();
    public trazabilidad: string = "";
    public periodo_calibracion:number= 0;
    public estatus_calibracion: string = "";
    public meteorometro: boolean = false;
    public valor_tabla: boolean = false;
    public texto_certificado:string = "NULL";
    public vigencia: Date = new Date();
    public temperatura:number= 0;
    public humedad:number= 0;
    public presion:number= 0;
    public incertidumbre_t:number= 0;
    public incertidumbre_hr:number= 0;
    public incertidumbre_p:number= 0;

}
