import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { dlgMensajeError } from '../../../components/dlgMensajeError/dlgMensajeError.component';

@Component({
  selector: 'dlgImportar',
  templateUrl: './archivos.component.html',
  styleUrls: ['./archivos.component.scss']
})
export class dlgImportar {
  @BlockUI() blockUI: NgBlockUI;
  @Input() encabezado;
  @Input() contenido;
  @Input() id: number;
  public data: any[];

  private archivos: UploaderComponent;
  public archivoArea: HTMLElement;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private http: HttpClient
  ) { }

  ngAfterContentChecked(): void {
    this.archivoArea = document.getElementById('droparea');
  }

  /**
   * Metodo para lanzar el buscardor de archivos relacionado con el uploader.
   * @returns 
   */
  public browseClick() {
    document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click(); return false;
  }

  /**
   * Metodo que se ejecuta cada vez que seleccionamos un archivo, ya sea por arrastre o busqueda.
   * El archivo seleccionado será almacenado dentro de una variable global, la cual posteriormente
   * es utilizada para guardar el archivo con el metodo "guardarImagen()"
   * 
   * @param args Son las propiedades del componente uploader, para obtener el archivo seleccionado
   * que esta en la posicion 0. El uploader esta configurado para solo obtener 1 archivo a la vez
   */
  public selected(args: UploaderComponent) {
    this.archivos = args;
    this.guardarArchivo();
  }

  /**
   * Metodo para almacenar los archivos
   * @returns 
   */
  public guardarArchivo() {
    this.blockUI.start('Procesando...');
    let formulario = new FormData();
    formulario.append('archivo', this.archivos.filesData[0].rawFile, this.archivos.filesData[0].name);
    formulario.append('Content-Type', 'application/json');
    formulario.append('Accept', `application/json`);
    this.http.post(`${environment.base_url}/gccalidad/importar/excel/1`, formulario).subscribe(resp => {
      let respuesta = JSON.parse(JSON.stringify(resp));
      if (respuesta.numero > 0) {
        //==============================================================================
        // Utilizacion de "ej2_instances" para poder ejecutar un metodo dentro del componente
        // Este componente no se utilizo por ViewChild, ya que no se renderiza al inicio
        //==============================================================================
        (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
        //==============================================================================
        this.activeModal.close(respuesta.numero);
        this.blockUI.stop();
      } else {
        const modalRef = this.modalService.open(dlgMensajeError);
        modalRef.componentInstance.encabezado = respuesta.titulo;
        modalRef.componentInstance.contenido = respuesta.mensaje;
        this.blockUI.stop();
      }
    });
  }
}