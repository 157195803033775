import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { MdlUsuario } from 'src/app/models/MdlUsuario';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { NotificacionesService } from './services/notificaciones.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss'],
})
export class NotificacionesComponent implements OnInit {
  public breadcrumbs1: string = '';
  public breadcrumbs2: string = '';
  @BlockUI() blockUI: NgBlockUI;

  @ViewChild('dlgnotificacion') dlgnotificacion: DialogComponent;
  @ViewChild('referencia') referencia: ElementRef;
  public descripcion: string = '';
  public fecha: string = '';
  public estatus: string = '';
  public hora: string = '';
  public origen: string = '';
  public imagen: string = '';
  public myObservable: any;
  public leido = true;
  public buttons: Object = [
    {
      click: this.cerrar.bind(this),
      buttonModel: {
        content: 'Cerrar',
        isPrimary: false
      },
    },
  ];
  public usuario: MdlUsuario;
  public data: any = [];
  public datamodal: any = [];
  public id_mensaje: number;

  constructor(
    private http: HttpClient,
    private servicio: NotificacionesService,
    public domSanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe((resp: { listado: any[] }) => {
      this.data = JSON.parse(JSON.stringify(resp.listado)).respuesta;
    });
    this.route.data.subscribe(resp => {
      this.breadcrumbs1 = resp.rutas.respuesta.ruta;
      
    });
  }

  public cerrar(): void {
    this.dlgnotificacion.hide();
    this.descripcion = '';
    this.fecha = '';
    this.estatus = '';
    this.hora = '';
    this.origen = '';
    this.imagen = '';
    this.btnBuscar();
  }

  cargaMensaje(id: number) {
    this.servicio.getDataNotificacionSelect(id).subscribe((resp) => {
      this.datamodal = JSON.parse(JSON.stringify(resp)).respuesta;
      this.descripcion = this.datamodal.descripcion;
      this.fecha = this.datamodal.fecha;
      this.estatus = this.datamodal.estatus;
      this.hora = this.datamodal.hora;
      this.origen = this.datamodal.origen;
    });
    this.http.post(`${environment.base_url}/mensajes/leido/${id}`, '').subscribe((respuesta) => {
    });
    this.dlgnotificacion.show();
  }

  public btnBuscar(): void {
    this.blockUI.start('Obteniendo informacion...');
    this.servicio.getListado(this.referencia.nativeElement.value).subscribe((resp) => {
      this.data = JSON.parse(JSON.stringify(resp)).respuesta;
      this.blockUI.stop();
    });
  }
}
