import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErroresService } from 'src/app/shared/errores.service';
import { environment } from 'src/environments/environment';
import { MdlPatron } from '../patron/models/MdlPatron';

@Injectable({
  providedIn: 'root'
})
export class PatronesService {

  constructor
    (
      private http: HttpClient,
      private errores: ErroresService
    ) {

  }
 // carga de table Dispositivo
 public Table(): Observable<any> {
  return this.http.get<any>(`${environment.base_url}/gccalidad/patron/carga`)
    .pipe(
      catchError(error => {
        return throwError(this.errores.getErrores(error));
      })
    );
}

   /**
   * Metodo para borrar el registro de planeacion de transporte
   *
   * @param modelo Objeto dentro del cual se tiene toda la información del registro
   */
   public borrar(id: number) {
    return this.http.post(`${environment.base_url}/gccalidad/patron/eliminar/${id}`, {})
      .pipe(
        catchError((error) => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }



  /**
  * Guardar: guardamos informacion del dispositivo
  *
  * @param modelo campturamos los campos del modelo y enviamos hacia la api
  * @returns resp: respuesta del api almacenado o actualizado
  */
 public guardar(modelo: MdlPatron) {
  return this.http
    .post(`${environment.base_url}/gccalidad/patron/guardar`, modelo)
    .pipe(
      catchError(error => {
        return throwError(this.errores.getErrores(error));
      })
    );
}
}

