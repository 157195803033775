import { AppService } from './../../services/app.service';
import { Component, Inject, OnDestroy, Renderer2, TemplateRef } from '@angular/core';
import { MdlUsuario } from 'src/app/models/MdlUsuario';
import { DomSanitizer } from '@angular/platform-browser';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NgbOffcanvas, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarioService } from 'src/app/pages/RMgestion/calendario/services/calendario.service';
import { UsuarioService } from '../../services/usuario.service';
import { NavbarService } from './navbar.service';
import { MdlRhEmpleadoConfigura } from './MdlRhEmpleadoConfigura.model';
import { dlgMensajeError } from '../dlgMensajeError/dlgMensajeError.component';
import { dlgAlertaService } from '../sidebar/dlgAlerta.service';
import { NotificacionesService } from 'src/app/pages/SIusuario/notificaciones/services/notificaciones.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnDestroy {
	public btnEleShow: ButtonComponent;
	public usuario: MdlUsuario;
	public menu: { [key: string]: Object }[];
	public dataNotificacion: any = [];
	public observaba;
	public numero_notificaciones = 0;
	public mensajes = false; //Sirve para el ngIf si existen o no mensajes sin leer
	public intervalo: any;
	public myObservable: any;
	isCollapsed = true;
	public dataMenu: any;
	public dataMenuTradicional: any;
	public dataMenuSub: any;

	public id_menu: number = -1;
	public id_menu_sub: number = -1;
	public menuDeslizante: boolean = false;

	public dataPorHacer: any;
	public dataConfiguracion: any;

	public notificacion: any = [];
	public NotificacionPosicion: number = 1;

	constructor(
		private appService: AppService,
		public alertaService: dlgAlertaService,
		private mensajesService: NotificacionesService,
		public domSanitizer: DomSanitizer,
		private http: HttpClient,
		private renderer2: Renderer2,
		@Inject(DOCUMENT) private documento,
		private offcanvasService: NgbOffcanvas,
		private calendarioService: CalendarioService,
		private usuarioService: UsuarioService,
		private navbarService: NavbarService,
		private modalService: NgbModal

	) {
		this.usuario = JSON.parse(localStorage.getItem('usuario'));
		this.menu = JSON.parse(localStorage.getItem('menu'));

		this.usuarioService.getConfiguracion(2).subscribe(resp => {
			this.menuDeslizante = JSON.parse(resp.respuesta).activado;
			if (this.menuDeslizante) {
				//==================================================================
				// Cargamos el menu desluzante
				//==================================================================
				this.getMenu();
				if (localStorage.getItem('id_menu')) {
					this.id_menu = JSON.parse(localStorage.getItem('id_menu'));
					this.getMenuSub(JSON.parse(localStorage.getItem('id_menu')));
				}
				if (localStorage.getItem('id_menu_sub')) {
					this.id_menu_sub = JSON.parse(localStorage.getItem('id_menu_sub'));
				}
				this.creamosScript();
			} else {
				//==================================================================
				// Cargamos el menu tradicional
				//==================================================================
				this.getMenuTradicional();
			}

			// !Intervalo del tiempo en el que se va a estar ejecutando el metodo que contiene el observable
			// !que gestiona las notificaciones
			this.intervalo = setInterval(() => {
				this.CargaNotificacion();
			}, 60000);

		});

		this.calendarioService.getPorHacer().subscribe(resp => {
			this.dataPorHacer = JSON.parse(resp.respuesta);
		});
		this.getListado();
	}

	toggleSidebarPin() {
		this.appService.toggleSidebarPin();
	}

	toggleSidebar() {
		this.appService.toggleSidebar();
	}

	public openTop(content: TemplateRef<any>) {
		this.offcanvasService.open(content, { position: 'top' });
	}

	creamosScript() {
		const script = this.renderer2.createElement('script');
		script.type = 'text/javascript';
		script.src = '/assets/scripts/menu.js';
		script.text = ``;
		this.renderer2.appendChild(this.documento.body, script);
	}

	public getMenuTradicional() {
		this.http.get<any[]>(`${environment.base_url}/auth/menu`).subscribe(resp => {
			this.dataMenuTradicional = JSON.parse(JSON.stringify(resp));
		});
	}

	/**
	 * Metodo para obtener los datos del menu de primer nivel
	 */
	public getMenu() {
		this.http.get<any>(`${environment.base_url}/auth/menu_sub/2`).subscribe(resp => {
			this.dataMenu = resp.respuesta;
		});
	}

	/**
	 * Metodo para obtener los datos del menu de segundo nivel con base en el parametro
	 * seleccionado del menu de primer nivel
	 * @param id
	 */
	public getMenuSub(id: number) {
		this.http.get<any>(`${environment.base_url}/auth/menu_sub/${id}`).subscribe(resp => {
			this.id_menu = id;
			this.dataMenuSub = resp.respuesta;
			localStorage.setItem('id_menu', String(id));
		});
	}

	/**
	 * Almacenamos de manera local el menu de segundo nivel que fue seleccionado
	 * esto será utilizado para marcar el menu como "activo"
	 */
	public setMenuSub(id: number) {
		this.id_menu_sub = id;
		localStorage.setItem('id_menu_sub', String(id));
	}

	private getListado() {
		this.usuarioService.getListado().subscribe(resp => {
			this.dataConfiguracion = JSON.parse(resp.respuesta);
		});
	}

	public openEnd(content: TemplateRef<any>) {
		this.offcanvasService.open(content, { position: 'end' });
	}

	public chkConfiguracion(args: any, item: any) {
		let modelo: MdlRhEmpleadoConfigura = new MdlRhEmpleadoConfigura();
		modelo.id = item.id;
		modelo.id_rh_configura = item.id_rh_configura;
		modelo.activado = args.target.checked;
		this.navbarService.setConfiguracion(modelo).subscribe(respuesta => {
			if (respuesta.numero < 0) {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
				modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
			} else {
				this.getListado();
			}
		});
	}

	logout(): void {
		this.offcanvasService.dismiss();
		this.usuarioService.logout();
	}

	ngOnDestroy() {
		//SE NECESITA LIMPIAR EL INTERVALO POR LA RAZON DE QUE SIGUE EJECUTANDOSE EN SEGUNDO PLANO Y SATURA MEMORIA.
		clearInterval(this.intervalo);
		//DETIENE EL OBSERVABLE YA QUE CADA CAMBIO DE COMPONENTE LOS DUPLICA Y LOS SIGUE EJECUTANDO EN SEGUNDO PLANO.
		if (this.myObservable) {
			this.myObservable.unsubscribe();
		}
	}

	CargaNotificacion() {
		this.myObservable = this.mensajesService.getDataNotificaciones(this.usuario.id).subscribe((resp) => {
			this.dataNotificacion = JSON.parse(JSON.stringify(resp)).respuesta;
			if (this.dataNotificacion != undefined) {
				this.numero_notificaciones = this.dataNotificacion.length;
				if (this.numero_notificaciones > 0) {
					this.mensajes = false;
					this.dataNotificacion.forEach(element => {
						this.alertaService.show(
							element.descripcion,
							{ classname: 'toasts-bg text-light', delay: 20000, id: element.id, modulo: element.modulo }
						);
					});

					//let audio: HTMLAudioElement = new Audio('https://drive.google.com/uc?export=download&id=1M95VOpto1cQ4FQHzNBaLf0WFQglrtWi7');
					let audio: HTMLAudioElement = new Audio('/assets/audio/toast_sound.mp3');
					audio.play();
				} else {
					this.mensajes = true;
				}
			}
		});
	}

	// Ejecuta el update para cambiar el estatus de las tablas
	public btnMensajeMarcado(id: number) {
		this.http.post(`${environment.base_url}/mensajes/mensajeMarcado/${id}`, '').subscribe((resp) => {
			let respuesta = JSON.parse(JSON.stringify(resp));
			if (respuesta.numero < 0) {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
				modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
			}
		});
	}

	/**
	 * Metodo para marcar como Terminado la reunion del calendario
	 * @param id
	 */
	public setTerminado(id: number) {
		this.calendarioService.setTerminado(id).subscribe(respuesta => {
			if (respuesta.numero < 0) {
				const modalRef = this.modalService.open(dlgMensajeError);
				modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
				modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
			}
		});
	}

	cargaNotificacion() {
		this.http.get<any>(`${environment.base_url}/organizacion/notificacion/listado`).subscribe(
			respuesta => {
				this.notificacion = respuesta;
			}
		);
	}

	onNotificacionChange(posicion: number): void {
		this.NotificacionPosicion = posicion;
		if (this.NotificacionPosicion == 1) {
			this.cargaNotificacion();
		}
	}

	showStandard() {
		this.alertaService.show('Mensaje Estandart');
	}

	showSuccess() {
		this.alertaService.show('Mensaje con estilo', { classname: 'bg-success text-light', delay: 10000 });
	}

	showDanger(dangerTpl) {
		this.alertaService.show(dangerTpl, { classname: 'bg-danger text-light', delay: 15000 });
	}

	isTemplate(toast) {
		return toast.textOrTpl instanceof TemplateRef;
	}

}
